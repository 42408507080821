<template>
    <v-row class="mt-1 mx-3">
        <v-col cols="3">
          <v-select
              v-model="outlet.province"
              :items="provinces"
              :loading="mDistrictLoading"
              label="Province"
              placeholder="Select province"
              variant="outlined"
              @update:modelValue="getAllDistrictAccProv"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
              v-model="outlet.district"
              :items="districts"
              :loading="mDistrictLoading"
              label="District"
              placeholder="Select district"
              variant="outlined"
              @update:modelValue="getAllDsd"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
              v-model="outlet.dsd"
              :items="dsdList"
              :loading="mDSDLoading"
              label="DSD"
              placeholder="Select DSD"
              variant="outlined"
              @update:modelValue="getAllGnd"

          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
              v-model="outlet.gnd"
              :items="gndList"
              label="GND"
              placeholder="Select GND"
              variant="outlined"
          ></v-select>
        </v-col>
    </v-row>
    <v-row class="mx-3">
        <v-col cols="12">
          <Map :center="mapCenter" :zoom="12" class="d-flex map-styles">
          <Marker v-for="agent in agents" :key="agent.id" :position="agent.position" @click="selectAgent(agent)">
            <InfoWindow :opened="selectedAgent.id === agent.id && dialog" class="d-flex gm-ui-hover-effect" @close="dialog=false">
              <!-- Show card when marker is clicked -->
              <v-row class="bg-orange-darken-3">
                  <v-card class="map-card mt-0">
                    <v-card-title>
                      <v-avatar class="mr-3">
                        <v-img :src="selectedAgent.avatar"></v-img>
                      </v-avatar>
                      <span>{{ selectedAgent.name }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-card-text>{{ selectedAgent.location }}<br>
                        <span>Last updated: {{ selectedAgent.lastUpdated }}</span></v-card-text>
                      <v-divider></v-divider>
                      <v-divider class="my-3"></v-divider>
                      <v-card-text variant="subtitle1">Trip Details:</v-card-text>
                      <v-list>
                        <v-list-item v-for="trip in selectedAgent.trips" :key="trip.id">
                          <v-list-item-content>
                            <v-list-item-title>{{ trip.destination }}</v-list-item-title>
                            <v-list-item-subtitle>{{ trip.time }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-row>
            </InfoWindow>
          </Marker>
        </Map>
        </v-col>
    </v-row>
</template>
    
<script>
import { ref } from 'vue';
import { Map, Marker, InfoWindow } from '@fawmi/vue-google-maps';
import axios from "axios";
export default {
    components: {
        Map,
        Marker,
        InfoWindow
    },
    name: 'OutletMapDashboardComponentFour',

  data(){

      return{
        provinces: [],
        districts: [],
        dsdList: [],
        gndList: [],

        outlet: {
          province: '',
          district: '',
          dsd: '',
          gnd: '',

        },
      }
  },
    setup() {
    const agents = ref([
      {
        id: 1,
        name: 'Mr. Nehan Pannaseeha',
        regNum: '1001',
        vehicle:'BHK-4321',
        avatar: 'path/to/avatar.jpg',
        trackingEnabled: true,
        position: { lat: 7.8731, lng: 80.7718 },
        location: 'Mawaramandiya',
        lastUpdated: '30 seconds ago',
        trips: [
          { id: 1, destination: 'Keells - Edmonton', time: '09:16 AM' },
          { id: 2, destination: 'Keells - Kohuwala', time: '10:30 AM' },
        ],
      },
      {
        id: 2,
        name: 'Mr. Supun Kanakeratne',
        regNum: '1000',
        vehicle:'BHK-4321',
        avatar: 'path/to/avatar.jpg',
        trackingEnabled: false,
        position: { lat: 7.8731, lng: 80.8968 },
        location: 'Panadura',
        lastUpdated: '5 minutes ago',
        trips: [
          { id: 1, destination: 'Keells - Moratuwa', time: '08:16 AM' },
          { id: 2, destination: 'Keells - Panadura', time: '09:30 AM' },
        ],
      },
      {
        id: 3,
        name: 'Mr. Sahan Pannaseeha',
        regNum: '1003',
        vehicle:'BHK-4321',
        avatar: 'path/to/avatar.jpg',
        trackingEnabled: true,
        position: { lat: 8.8731, lng: 80.7718 },
        location: 'Mawaramandiya',
        lastUpdated: '30 seconds ago',
        trips: [
          { id: 1, destination: 'Keells - Edmonton', time: '09:16 AM' },
          { id: 2, destination: 'Keells - Kohuwala', time: '10:30 AM' },
        ],
      },
    ]);

    const mapCenter = ref({ lat: 7.8731, lng: 80.7718 });
    const selectedAgent = ref({});
    const dialog = ref(false);

    const selectAgent = (agent) => {
      selectedAgent.value = agent;
      dialog.value = true;
    };

    const toggleEnable = (agent) => {
      agent.trackingEnabled = !agent.trackingEnabled;
    };


    return {
      agents,
      mapCenter,
      selectedAgent,
      dialog,
      selectAgent,
      toggleEnable,
    };
  },
  methods: {
  getAllProvinces() {
    this.districts = [];
    this.dsdList = [];
    this.gndList = [];
    this.mDistrictLoading = true;
    // let laravel = JSON.parse(window.Laravel);
    const header = {
      // 'X-CSRF-TOKEN': laravel.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'multipart/form-data',
    };

    axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
        .then(response => {
          if (response.data.http_status === 'success') {
            this.provinces = response.data.data;
            this.mDistrictLoading = false;
          }
        })
        .catch(() => {
          this.mDistrictLoading = false;
        });
  },

  getAllDistrictAccProv(province) {
    console.log('Province selected:', province);
    this.districts = [];
    this.mDistrictLoading = true;
    // let laravel = JSON.parse(window.Laravel);
    const headers = {
      // 'X-CSRF-TOKEN': laravel.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'multipart/form-data',
    };

    axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
        .then(response => {
          console.log('District API response:', response.data);
          if (response.data.http_status === 'success') {
            this.districts = response.data.data;
            console.log('District API response:', response.data);
            this.mDistrictLoading = false;

          }
        })
        .catch(error => {
          this.mDistrictLoading = false;
          console.error('Error fetching districts:', error);

        });
  },

  getAllDsd(district) {
    this.dsdList = [];
    this.mDSDLoading = true;
    // let laravel = JSON.parse(window.Laravel);
    const header = {
      // 'X-CSRF-TOKEN': laravel.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'multipart/form-data',
    };

    axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
        .then(response => {
          if (response.data.http_status === 'success') {
            this.dsdList = response.data.data;
            this.mDSDLoading = false;
          }
        })
        .catch(() => {
          this.mDSDLoading = false;
        });
  },

  getAllGnd(dsd) {
    this.gndList = [];
    this.mGNDLoading = true;
    // let laravel = JSON.parse(window.Laravel);
    const header = {
      // 'X-CSRF-TOKEN': laravel.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'multipart/form-data',
    };

    axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
        .then(response => {
          if (response.data.http_status === 'success') {
            this.gndList = response.data.data;
            this.mGNDLoading = false;
          }
        })
        .catch(() => {
          this.mGNDLoading = false;
        });
  },
},
  watch: {
    'outlet.province'(newValue) {
      this.getAllDistrictAccProv(newValue);
    },
    'outlet.district'(newValue) {
      this.getAllDsd(newValue);
    },
    'outlet.dsd'(newValue) {
      this.getAllGnd(newValue);
    }
  },
  mounted() {
    this.getAllProvinces();
  },
};
</script>
    
<style scoped>
.v-card-title {
    font-weight: bold;
}
.map-styles {
  width: 100%;
  height: 400px;

}
</style>
    