<template >
  <v-card style="height: 100%; width: 100%">
    <div style="display: flex; justify-content: center;">
      <v-card class="pa-3 mt-3" style="width: 93%;">
      <v-icon class="ml-8" >mdi-view-dashboard
      </v-icon>
      <v-label class="ml-10">OUTLETS DETAILS</v-label>
    </v-card>
    </div>

    <div style="display: flex; justify-content: center;">
      <v-card class="pa-2 mt-3 mb-5" style="width: 93%; height: 100%; padding-bottom: 20px;">
    <v-row class="first_row ma-0 pa-0 ">
      <v-col class="secondC textCenter" cols="12" md="8">
        <div class="image">
          <v-img
              :src="require('@/assets/ICONS/shop.png')"
              class="rounded-circle"
          ></v-img>
        </div>
        <v-card class="dataCard" flat style=" padding: 20px; width: 30vw"  >
          <v-row>
            <v-col cols="12">
              <v-row><h4><span >Outlet Name </span><span>  : </span><span style="color: red">{{ outletName }}</span></h4></v-row>
              <v-row><h5><span >Mobile </span><span style="margin-left: 39px"> : </span><span>{{ mobileNumber }}</span></h5></v-row>
              <v-row>
                <v-chip class="mt-3 pa-3" color="green" size="small" style="width: auto" text-color="white" variant="outlined" >
                  <v-icon  color="green"  >mdi-check-circle-outline</v-icon>
                  OUTLET STATES  - <span></span><span style="font-size: 14px; margin-left: 4px">{{outletStates}}</span> </v-chip>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

      </v-col>

      <v-col class="pdfC" cols="12" md="4">
        <div class="action">
          <div class="action">
            <v-btn class="editB bg-red-lighten-4 ml-5" color="red" icon rounded size="small" variant="outlined" width="200" @click="createRoute"> UPDATE OUTLET DATA
            </v-btn>
            <v-btn class="bg-grey-lighten-2 ml-3" color="black" icon  rounded size="small" variant="outlined" width="150" @click="cancell">CANCEL
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <v-card class="mt-3" flat style="width: 100%; padding-right: 20px; padding-bottom: 20px ">
      <v-row>
        <v-col cols="12" md="6">
          <v-card class="pt-2 card-hight-fixed" flat>
            <v-row class="textfield">
              <v-text-field
                  v-model="outletName"
                  label="Outlet Name"
                  variant="outlined"

              ></v-text-field>
              <v-text-field
                  v-model="ownerName"
                  label="Owner Name"
                  variant="outlined"

              ></v-text-field>
              <v-row>
                <v-col cols="12" md="8">
                  <v-select
                      v-model="outletStates"
                      :items="outletStatess"
                      item-title="outlet_status"
                      item-value="outlet_status"
                      label="Outlet Status"
                      variant="outlined"
                  ></v-select>

                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="bg-black" @click="reasoncard = !reasoncard">Reason</v-btn>
                </v-col>
                  <v-textarea
                      v-if="reasoncard" v-model="reason"
                      label="Reason"
                      style="margin-bottom: 5px; margin-left: 15px"
                      variant="outlined"
                  ></v-textarea>
              </v-row>
              <v-text-field
                  v-model="province"
                  label="Mobile Number"
                  variant="outlined"

              ></v-text-field>
              <v-select
                  v-model="outletType"
                  :items="outletTypes"
                  item-title="outlet_name"
                  item-value="id"
                  label="Select Outlet"
                  variant="outlined"
                  @click="fetchOutletType"
              ></v-select>
              <v-select
                  v-model="province"
                  :items="provinces"
                  :loading="mDistrictLoading"
                  label="Province"
                  placeholder="Select province"
                  variant="outlined"
                  @update:modelValue="getAllDistrictAccProv"
              ></v-select>

              <v-select
                  v-model="district"
                  :items="districts"
                  :loading="mDistrictLoading"
                  label="District"
                  placeholder="Select district"
                  variant="outlined"
                  @update:modelValue="getAllDsd"
              ></v-select>

              <v-select
                  v-model="dsd"
                  :items="dsdList"
                  :loading="mDSDLoading"
                  label="DSD"
                  placeholder="Select DSD"
                  variant="outlined"
                  @update:modelValue="getAllGnd"

              ></v-select>

              <v-select
                  v-model="gnd"
                  :items="gndList"
                  label="GND"
                  placeholder="Select GND"
                  variant="outlined"
              ></v-select>

            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" >
          <v-card class="pt-2 card-hight-fixed " flat style="padding-right: 20px; padding-left: 5px;">
            <v-row class="textfield2">
              <v-text-field
                  v-model="outletAddress"
                  label="Outlet Address"
                  variant="outlined"
              ></v-text-field>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="latitude"
                      label="Latitude"
                      variant="outlined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="longitude"
                      label="Longitude"
                      variant="outlined"
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-row>
            <v-divider></v-divider>
            <v-card-title >
              <h4 >OUTLET LIST</h4>
            </v-card-title>
            <v-card-subtitle>
              In here, you can set the outlet location by searching the location or dragging and placing it.
            </v-card-subtitle>
            <v-card class="mt-3" height="100%" width="100%">

              <Map ref="map" :center="mapCenter" :zoom="13" class="d-flex map" style="width: 100%; height: 60vh" @click="mapClicked">
                <Marker
                    v-if="markerPosition"
                    :icon="customIcon"
                    :position="markerPosition"
                />
                <Circle
                    v-if="markerPosition"
                    :center="markerPosition"
                    :options="circleOptions"
                    :radius="radius"
                />
              </Map>

            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
      </v-card>
    </div>
    <v-snackbar
        v-model="snackbar"
        color="success"
        location="top-right"
        outlined
        timeout="3000"
    >
      UPDATE SUCCESSFULLY.
      <template v-slot:action="{ attrs }">
        <v-btn
            icon
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay v-model="loading" >
      <v-progress-circular class="centered-progress" indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-card>
</template>

<script>


import { Map , Marker, Circle} from '@fawmi/vue-google-maps';
import axios from "axios";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";
import {EventBus} from "@/plugins/event-bus";

export default {
  name: "RoutePlan",
  components: {Circle, Marker, Map},
  data() {
    return {
      loading: false,
      snackbar: false,
      alignment: 1,
      formatting: [],
      deleteDialog: false,
      itemToDelete: null,
      closeDialog: false,
      editDialog:false,
      reminderDialog:false,
      reasoncard: false,
      mapCenter: {},
      outletTypeId:'',
      reason:'',
      fetchData:'',
      outletStates:'',
      ownerName:'',
      province:'',
      district:'',
      dsd:'',
      gnd:'',
      mobileNumber:'',
      outletName:'',
      outletAddress:'',
      outletType:'',
      latitude:'',
      longitude:'',
      statesDefault:'',
      outletTypes: [],
      outletStatess: ['ALLOWED', 'NOT ALLOWED', 'NEED APPROVAL'],
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      mDistrictLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,

      outletProducts: [],

      circleOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      },
      radius: 800,

      markerPosition: null,
      customIcon: {
        url: require('@/assets/ICONS/maker.png'), // Path to your custom marker icon
        scaledSize: { width: 40, height: 40 } // Adjust the size if needed
      }

    };
  },

  methods: {
    cancell() {
      //  functionality to edit item details
      EventBus.emit('outletManagementButtonClick2');
    },


    mapClicked(event) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.latitude = this.markerPosition.lat;
      this.longitude = this.markerPosition.lng;
    },

    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;

    },
    deleteItem() {
      // Implement your delete item logic here
      this.sales = this.sales.filter(sale => sale !== this.itemToDelete);
      this.deleteDialog = false;
      this.itemToDelete = null;
    },
    exportPDF() {
      //  functionality to export table data as PDF
    },

    closeD() {
      this.viewDialog = false;
      this.editDialog = false;
    },

    fetchOutletRecodeData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataOutlet;

      console.log('Shared Data:', this.sharedDataOutlet);
      console.log('fk', id);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}`, { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
          .then(response => {
            console.log("API Response all:", response.data);
            if (response.data) {

              this.outletProducts = Array.isArray(response.data) ? response.data: [response.data];
              console.log("this.outletProducts:", this.outletProducts);

              if (this.outletProducts.length > 0) {
                const route = this.outletProducts[0];
                this.ownerName = route.owner_name;
                this.latitude = route?.latitude;
                this.longitude = route?.longitude;
                this.province = route?.province;
                this.district = route?.district;
                this.dsd = route?.dsd;
                this.gnd = route?.gnd;
                this.outletStates = route?.outlet_status;
                this.statesDefault = route?.outlet_status;
                this.outletName = route?.outlet_name;
                this.outletType = route?.outlet_type.outlet_name;
                this.outletAddress = route?.outlet_address;
                this.mobileNumber = route.mobile_number;
                this.outletTypeId = route.outlet_type_id;
                console.log("sahan", this.mobileNumber);

                this.markerPosition = { lat: parseFloat(this.latitude), lng: parseFloat(this.longitude) };
                this.mapCenter = { lat: parseFloat(this.latitude), lng: parseFloat(this.longitude) };

              }
            } else {
              this.outletProducts = [];
            }
          })
          .catch(error => {
            console.log(error);
            this.outletProducts = [];
          });
    },
    fetchOutletType(){
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet`, { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.outletTypes = response.data;
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });

    },
    // fetchOutletStates() {
    //   const token = localStorage.getItem('token');
    //   const userId = localStorage.getItem('userId');
    //
    //   axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/status/view`, {userId}, {
    //     headers: {
    //       'Authorization': `Bearer ${token}`,
    //       'Content-Type': 'application/json'
    //     }
    //   })
    //       .then(response => {
    //         this.outletStatess = response.data;
    //       })
    //       .catch(error => {
    //         console.error('Error fetching routes:', error);
    //       });
    //
    // },
    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mDistrictLoading = false;
            }
          })
          .catch(() => {
            this.mDistrictLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },

    async createRoute() {
      this.loading = true;


      if (!this.outletTypes.some((outlet) => outlet.id === this.outletType)) {
        this.outletType = this.outletTypeId;
      }

      const id = this.sharedDataOutlet;

      console.log('Shared Data:', this.sharedDataOutlet);
      console.log('fk', id);

      const userId = localStorage.getItem('userId');
      console.log('ssssssss:', this.outletName)
      const outletData = {
        outlet_name: this.outletName,
        outlet_address: this.outletAddress,
        latitude: this.latitude,
        longitude: this.longitude,
        mobile_number: this.mobileNumber,
        outlet_type_id: this.outletType,
        province: this.province,
        district: this.district,
        dsd: this.dsd,
        gnd: this.gnd,
        owner_name: this.ownerName,
        outlet_status: this.outletStates,
        userId:  userId,
        id: id,
      };
      try {
        const token = localStorage.getItem('token');
console.log("outletData is ", outletData);
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/save`, outletData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        if (response.status === 200) {
          if (this.outletStates !== this.statesDefault) {
            const updateData = {
              reason: this.reason,
              status: this.outletStates,
              userId: userId
            };

            const updateResponse = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}/status`, updateData, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              },
            });
            console.log(response);
            console.log(updateResponse);

          }
        }
      } catch (error) {
        // Show error message
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response?.data?.message || 'Something went wrong!',
        });
      }finally {
        this.snackbar = true;
        this.loading = false;
        setTimeout(() => {
          EventBus.emit('outletManagementButtonClick2');
          this.editDialog = false;
        }, 3000);
      }
    },
  },

  computed: {
    ...mapGetters(['sharedDataOutlet']),
    imageSrc() {
      return `${process.env.VUE_APP_API_BASE_URL}${this.userImage}`;
    }
  },
  created() {
    this.fetchOutletRecodeData();

    // EventBus.on('viewAgentDetails',(data) =>{
    //   console.log('sahan', data.Id);
    // });
  },

  // beforeUnmount() {
  //   EventBus.off('viewAgentDetails', fetchData =>{
  //     console.log('pppppp', fetchData);
  //   });
  // },
  mounted() {
    this.fetchOutletRecodeData();
    this.getAllProvinces();
  },
  watch: {
    'province'(newValue) {
      this.getAllDistrictAccProv(newValue);
    },
    'district'(newValue) {
      this.getAllDsd(newValue);
    },
    'dsd'(newValue) {
      this.getAllGnd(newValue);
    }
  }

};
</script>


<style scoped>

.FR{
  width: 100vw;
}
.fc{
  width: 100vw;
  margin-top: 4px;
  margin-bottom: 10px;
}
.closeC{
  justify-content: right;
  margin-top: 0;
  margin-left: 88vw;

}
.card-hight-fixed{
  height: 100%;
  overflow: auto;
  max-height: 63vh;
}

.pdfC{
  display: flex;
  justify-content: right;
  width: 90vw;
  margin-top: 40px;
}

.editB{
  display: flex;
  margin-right: 10px;
}
.image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden; /* Ensure the image does not overflow the div */
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
}

.rounded-circle {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire div */
  display: block;
}

.fill-height {
  height: 100vh; /* Full viewport height */
  padding: 20px;
  display: flex;
}
.textCenter {
  text-align: center;
}


.textfield{
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-left: 20px;
  margin-top: 20px;
}

.textfield2{
  display: flex;
  width: 40vw;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 20px;
}

.action {
  margin-left: 10px;
  display: flex;
  justify-content: right;
}

.first_row{
  display: flex;
  justify-content: flex-end;

}

.secondC{
  display: flex;

}


.tableCard{
  height: 31vh;
}

.red--text{
  color: red;
}

</style>
