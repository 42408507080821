<template>
  <v-card class="main" >
    <div style="display: flex; justify-content: center;">
    <v-card class="pa-3 mt-5" style="width: 93%;">
      <v-icon>mdi-view-dashboard
      </v-icon>
      <v-label class="ml-6">SALES RECORD</v-label>
    </v-card>
    </div>

    <div style="display: flex; justify-content: center;">
      <v-card class="pa-2 mt-5 mb-5" style="width: 93%; height: 100%">
    <v-row class="first_row ma-0 pa-0 ">
      <v-col class="secondC textCenter" cols="12" md="10">
        <div class="image">
          <v-img
              :src="imageSrc"
              class="rounded-circle"
          ></v-img>
        </div>
        <v-card class="dataCard" flat style=" padding: 20px; width: 30vw" >
          <v-row>
            <v-col cols="12">
              <v-row><h4><span >User Name </span><span>  : </span><span style="color:red">{{ agentName }}</span></h4></v-row>
              <v-row><h5><span >Mobile </span><span style="margin-left: 39px"> : </span><span>{{ mobileNumber }}</span></h5></v-row>
              <v-row>
                <v-chip class="mt-3 pa-3" color="green" size="small"  text-color="white" variant="outlined" >
                  <v-icon  color="green"  >mdi-check-circle-outline</v-icon>
                  USER TYPE  - <span></span><span style="font-size: 16px; margin-left: 4px">{{userType}}</span> </v-chip>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

      </v-col>

        <v-col class="pdfC d-flex justify-center" cols="12" md="2">
          <div class="action">
            <v-btn class="editB bg-green-lighten-4" color="green" icon rounded size="small" variant="outlined" @click="editViewSales">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="bg-red-lighten-4" color="red" icon rounded size="small" variant="outlined" @click="confirmDelete">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </div>
        </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-card flat style="width: 100%; height: 100%" >
    <v-row class="second_row ">
      <v-col cols="12" md="6">
        <v-card  class="pt-2 card-height-fixed" flat>
        <v-row class="textfield">
          <v-text-field
              v-model="oderId"
              label="Oder ID"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="agentName"
              label="User Name"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="userType"
              label="User Type"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="province"
              label="Province"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="district"
              label="District"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="dsd"
              label="DSD"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="gnd"
              label="GND"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="storeName"
              label="Store Name"
              readonly
              variant="outlined"
          ></v-text-field>
        </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card  class="pt-2 card-height-fixed" flat>
        <v-row class="textfield2">
          <v-text-field
              v-model="quantitySold"
              label="Quantity Sold"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="storeAddress"
              label="Sales Address"
              readonly
              variant="outlined"
          ></v-text-field>

          <v-card>
            <v-tabs v-model="tab" stacked>
              <v-tab v-if="access.cash" class="text-red" value="tab-1" variant="plain">CASH</v-tab>
              <v-tab v-if="access.bankDeposit" class="ml-3 mr-5 text-red" value="tab-2" variant="plain">BANK DEPOSIT SLIP OR CHEQUE</v-tab>
              <v-tab v-if="access.payLater" class="text-red" value="tab-3" variant="plain">PAY LATER</v-tab>
            </v-tabs>

            <v-tabs-window v-model="tab" style="margin: 10px">
              <!-- Cash Tab -->
              <v-tabs-window-item  v-if="access.cash" value="tab-1">
                <v-text-field
                    v-model="salesAmount"
                    class="mt-4"
                    label="Sales Amount"
                    readonly
                    variant="outlined"
                ></v-text-field>
              </v-tabs-window-item>

              <!-- Bank Deposit Slip or Cheque Tab -->
              <v-tabs-window-item  v-if="access.bankDeposit" value="tab-2">
                <v-card class="d-flex align-center">
                  <v-icon class="mr-3" color="red">mdi-file</v-icon>
                  <div>
                    <v-btn
                        :href="fileUrl"
                        class="d-flex align-center"
                        download
                        variant="text"
                    >
                      bank-slip-01.png
                      <v-icon class="ml-1">mdi-download</v-icon>
                    </v-btn>
                    <div class="text-caption grey--text">
                      • file size – 92 kb
                    </div>
                  </div>

                </v-card>
              </v-tabs-window-item>

              <!-- Pay Later Tab -->
              <v-tabs-window-item v-if="access.payLater" value="tab-3">
                <v-text-field
                    v-model="oderId"
                    class="mt-2"
                    label="Order ID"
                    readonly
                    variant="outlined"
                ></v-text-field>
                <v-text-field
                    v-model="salesAmount"
                    class="mt-2"
                    label="Sales Amount"
                    readonly
                    variant="outlined"
                ></v-text-field>
              </v-tabs-window-item>
            </v-tabs-window>
          </v-card>

          <v-card-title>
            <h4>Sales Products List</h4>
            <v-card-subtitle>
              <h6>The following table shows all the products related to sales </h6>
            </v-card-subtitle>
          </v-card-title>
          <v-card style="  padding: 10px;">
            <v-col cols="12">
              <v-data-table
                  :headers="headers"
                  :items="salesProducts[0]?.products"
                  style="font-size: 10px; overflow-y: auto; max-height: 100%;"
              >
                <!-- Custom slot for product column -->
                <template v-slot:[`item.product_name`]="{ item }">
                  <v-card-text class="text-red">{{ item.product_name }}</v-card-text>
                </template>

              </v-data-table>
            </v-col>
          </v-card>
        </v-row>
        </v-card>
      </v-col>
    </v-row>
    </v-card>
      </v-card>
    </div>

    <v-row>

    </v-row>

    <v-dialog v-model="editDialog" class="solid-dialog" fullscreen persistent>
      <v-card>
        <v-row class="d-flex">
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-toolbar-title class="mt-1 ml-6">
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
            </v-toolbar-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="10">
            <v-btn class="closeC bg-red mr-2 justify-center font-weight-thin mt-3 mr-3" color="white" height="30" icon
                   rounded="lg" variant="text" width="90" @click="closeD">
              <v-icon>mdi-close-circle-outline</v-icon>
              CLOSE
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <Sales_Recod_Edit/>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" max-height="500" max-width="500" persistent>
      <v-card class="pa-5">
        <v-row dense style="background-color: #d9d9d9;">
          <v-col cols="12" md="10">
            <v-card-title class="headline">DELETE SALES DATA</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-2 mr-2" icon size="x-small" @click="deleteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this sales record ?</v-card-text>
        <v-card-text class="font-weight-medium">
          This sales record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>
          <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteDialog = false">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {EventBus} from "@/plugins/event-bus";
import Sales_Recod_Edit from "@/components/SALES_MANAGEMENT/Sales_Recod_Edit.vue";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "Sales_Record.vue",
  components: {Sales_Recod_Edit,},
  data() {
    return {
      tab:null,
      deleteDialog: false,
      itemToDelete: null,
      closeDialog: false,
      editDialog: false,
      fetchData:'',
      agentName:'',
      oderId:'',
      userType:'',
      province:'',
      district:'',
      dsd:'',
      gnd:'',
      quantitySold:'',
      salesAmount:'',
      storeAddress:'',
      storeName:'',
      userImage:'',
      mobileNumber:'',
      headers: [
        {text: 'Product', value: 'product_name', title: 'Product'},
        {text: 'Quantity', value: 'quantity', title: 'Quantity'},
        {text: 'Sales Amount', value: 'sales_amount', title: 'Sales Amount'},
        {text: 'Unit Price', value: 'unit_price', title: 'Unit Price'},
      ],
      salesProducts: [],
      access: {
        cash: false,
        bankDeposit: false,
        payLater: false,
      },

    };
  },

  methods: {
    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    deleteItem() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedData;

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/sales/delete/${id}`,{ userId },{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.deleteDialog = false;
            this.itemToDelete = null;
            EventBus.emit('SalesRecodeManagementButtonClick2');
          })
          .catch(error => {
            console.error("There was an error deleting the sale!", error);
          });

    },
    exportPDF() {
      //  functionality to export table data as PDF
    },
    editViewSales() {
      //  functionality to edit item details
      this.editDialog = true;
      EventBus.emit('SalesRecodeManagementButtonClick');
    },

    closeD() {
      this.viewDialog = false;
      this.editDialog = false;
    },

    fetchSalesRecodeData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedData;

      console.log('Shared Data:', this.sharedData);
      console.log('fk', id);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/sales/${id}`, { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
          .then(response => {
            console.log("API Response all:", response.data);
            if (response.data.sale) {
              // Convert the sales object into an array with a single element if necessary
              this.salesProducts = Array.isArray(response.data.sale) ? response.data.sale : [response.data.sale];
              console.log("this.salesProducts:", this.salesProducts);

              if (this.salesProducts.length > 0) {
                const sale = this.salesProducts[0];
                this.agentName = sale.agent.name;
                this.oderId = sale?.order_id;
                this.userType = sale.user_type;
                this.province = sale?.province;
                this.district = sale?.district;
                this.dsd = sale?.dsd;
                this.gnd = sale?.gnd;
                this.quantitySold = sale?.quantity_sold;
                this.salesAmount = sale?.sales_amount;
                this.storeAddress = sale?.address;
                this.storeName = sale?.store_name;
                this.userImage = sale?.agent?.user_image;
                this.mobileNumber = sale.agent.mobile_number.length > 0 ? sale.agent.mobile_number[0].mobile_number : null;

                console.log("sahan", this.mobileNumber)

                // Check the payment method and set access flags
                const paymentMethod = sale.payment_method;

                console.log(
                    "paymentMethod:", paymentMethod,
                )

                // Reset access flags
                this.access = {
                  cash: false,
                  bankDeposit: false,
                  payLater: false,
                };

                // Set access based on the payment method
                if (paymentMethod === 'cash') {
                  this.access.cash = true;
                  this.tab = 'tab-1'; // Set to cash tab
                } else if (paymentMethod === 'bank_deposit') {
                  this.access.bankDeposit = true;
                  this.tab = 'tab-2'; // Set to bank deposit tab
                } else if (paymentMethod === 'pay_later') {
                  this.access.payLater = true;
                  this.tab = 'tab-3'; // Set to pay later tab
                }
              }
            } else {
              this.salesProducts = [];
            }
          })
          .catch(error => {
            console.log(error);
            this.salesProducts = [];
          });
    }
    // this.mobileNumber = sale.agent.mobile_number.length > 0 ? sale.agent.mobile_number[0].mobile_number : null;
  },

    computed: {
    ...mapGetters(['sharedData']),
      imageSrc() {
        return `${process.env.VUE_APP_API_BASE_URL}${this.userImage}`;
      }
  },
  created() {
    this.fetchSalesRecodeData();

    // EventBus.on('viewAgentDetails',(data) =>{
    //   console.log('sahan', data.Id);
    // });
  },

  // beforeUnmount() {
  //   EventBus.off('viewAgentDetails', fetchData =>{
  //     console.log('pppppp', fetchData);
  //   });
  // },
  mounted() {
    this.fetchSalesRecodeData();
  },

};
</script>


<style scoped>

.main{
  width: 100%;
  height: 100%;
}

.FR {
  width: 100vw;
}

.fc {
  width: 100vw;
  margin-top: 4px;
  margin-bottom: 10px;
}

.closeC {
  justify-content: right;
  margin-top: 0;
  margin-left: 88vw;

}

.pdfC {
  display: flex;
  margin-top: 20px;
}

.editB {
  display: flex;
  margin-right: 10px;
}

.image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden; /* Ensure the image does not overflow the div */
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
}

.rounded-circle {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire div */
  display: block;
}


.textCenter {
  text-align: center;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.my-4 {
  margin: 1.5rem 0;
}

.textfield {
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-left: 50px;
  margin-top: 20px;
}
.textfield2{
  display: flex;
  flex-direction: column;
  width: 38vw;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
  margin-right: 15px;
}

.action {
  margin-left: 10px;
  display: flex;
  justify-content: right;
}

.first_row {
  display: flex;
  justify-content: flex-end;

}

.secondC {
  display: flex;

}

.dataCard {
  display: flex;
  justify-content: left;
}

.tableCard {
  height: 100%; overflow-y: auto; font-size: 10px
}

.card-height-fixed {
  height: 100%;
  overflow: auto;
  max-height: 63vh;
}

</style>
