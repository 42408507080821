<!-- Sales and collections analysis component -->

<template>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3">
          <v-card-title class="card-h4-title">OUTLET MAP
            <div class="tool-bar-style">
              <v-btn class="red-light-text" variant="outlined">
                <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
              </v-btn>
            </div>
          </v-card-title>
          
          <v-divider class="my-3"></v-divider>

          <v-row>
            <v-col cols="12">
              <v-card flat>
                <OutletMapDashboardComponentFour/>
              </v-card>
            </v-col>
            
          </v-row>
          </v-card>
    </v-col>
  </v-row>
</template>

<script>
import OutletMapDashboardComponentFour from "@/components/MAP/dashboard_outlet_map_component_four.vue";
export default {
    name: 'outletMap',
    components: {
      OutletMapDashboardComponentFour
    },

    data() {
      return {
          
      };
    },
    
    methods: {
    
    }
};
</script>

<style scoped>
.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  font-size: 1rem;
}

.tool-bar-style {
  display: flex;
  justify-content: space-between;
}

.red-light-text {
  background-color: #f1cccc;
  color: #b00d0d;
}

.red-text {
  color: #b00d0d;
}

.gray-text {
  color: #757575 !important;
}

</style>