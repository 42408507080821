<template>
  <v-container fluid class="mainContainer">
    <!-- Header Section -->
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3">
          <v-row justify="space-between" align="center">
            <v-col>
              <v-card-title variant="h5">Tracking Analysis</v-card-title>
              <v-card-subtitle>Analysis of daily traffic data of field officers (agents).</v-card-subtitle>
            </v-col>
            <v-col cols="12" md="2" class="d-flex justify-end">
              <div class="back-button">
                <v-btn text-sm-h9 font-weight-light mt-4 text-grey @click="back()" >
                  <v-icon color="black" size="small">mdi-arrow-left-circle</v-icon> BACK
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" md="3">
              <v-btn class="pa-1 d-flex" width="250" height="80" @click="allAgents()">
                <v-card width="250" height="80">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <span class="mt-7 ml-7">ALL</span>
                      </v-row>
                      <v-row>
                        <span class="ma-2 ml-8 font-weight-bold text-red box">{{ this.allOfficerDetails.length }}</span>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="mt-4">
                      <v-icon right color="red" class="bg-red-lighten-4" size="50">mdi-car-3-plus</v-icon>
                    </v-col>
                  </v-row>
                </v-card>
              </v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn class="pa-1 d-flex" width="250" height="80">
                <v-card width="250" height="80" variant="outlined" color="green" class="on-trip-card">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <span class="mt-7 ml-7 on-trip-header">ON TRIP</span>
                      </v-row>
                      <v-row>
                        <span class="ma-2 ml-8 font-weight-bold on-trip-subheader">{{ agents.length }}</span>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="mt-4">
                      <v-icon right class="on-trip-icon" size="50">mdi-car-3-plus</v-icon>
                    </v-col>
                  </v-row>
                </v-card>
              </v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn class="pa-1 d-flex" width="250" height="80" @click="idling()">
                <v-card width="250" height="80">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <span class="mt-7 ml-7">AVAILABLE</span>
                      </v-row>
                      <v-row>
                        <span class="ma-2 ml-8 font-weight-bold text-red box">{{ this.deactiveOfficerCount}}</span>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="mt-4">
                      <v-icon right color="red" class="bg-red-lighten-4" size="50">mdi-car-3-plus</v-icon>
                    </v-col>
                  </v-row>
                </v-card>
              </v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn class="pa-1 d-flex" width="250" height="80" @click="parking()">
                <v-card width="250" height="80">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <span class="mt-7 ml-7">NOT AVAILABLE</span>
                      </v-row>
                      <v-row>
                        <span class="ma-2 ml-8 font-weight-bold text-red box">{{ this.activeOfficerCount}}</span>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="mt-4">
                      <v-icon right color="red" class="bg-red-lighten-4" size="50">mdi-car-3-plus</v-icon>
                    </v-col>
                  </v-row>
                </v-card>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Main Content -->
    <v-row class="mapRow" style="margin-bottom: 30px; height: 100%; width: 100%;" >
      <!-- Map Section -->
      <v-col cols="8" >
        <v-card style="margin-bottom: 10px; height: 100%; width: 100%;">
          <Map 
            class="d-flex map" 
            :center="mapCenter" 
            :zoom="7" 
            style="width: 100%; height: 76vh;">
            
            <Marker :key="index"
               v-for="(m, index) in markers" 
               :position="m.position" 
               @click="selectAgent(m)"
               :icon="{
                      url: require('@/assets/ICONS/vechile_icon.png'), 
                      scaledSize: { width: 40, height: 35 },
                  }" 

            >

              <!-- <InfoWindow :opened="selectedAgentsamplee && selectedAgentsamplee.id === m.id"  class="d-flex gm-ui-hover-effect" @close="dialog=false"  >
                
                <v-row >
                  <v-card class="map-card mt-0">
                    <v-card-title>
                      <v-avatar class="mr-3">
                        <v-img :src="officerDetails.user_image"></v-img>
                      </v-avatar>
                      <span>{{ officerDetails.name }}</span>
                    </v-card-title >
                    <v-card-text>
                      <v-card-text>{{ officerDetails.location }}<br>
                        <span>Last updated: {{ officerDetails.lastUpdated }}</span></v-card-text>
                      <v-divider></v-divider>
                      <v-divider class="my-3"></v-divider>
                    </v-card-text>



                    <v-card class="mt-10 d-flex " style="height: 35vh; width: 600px"  >
                      <v-row>
                        <v-col cols="12" md="9">
                          <v-card class="dataCard" style="height: 34vh; width: 450px" variant="flat">
                            <v-card-text>
                              <div class="font-weight-bold ms-1 mb-2">Agent name  : {{outletDetails.name}}</div>
                              <v-timeline align="start" density="compact">
                                <v-timeline-item
                                    v-for="agent in agents"
                                    :key="agent.id"
                                    dot-color="red"
                                    size="x-small"
                                >
                                  <v-row v-for="agent in outletDetails" :key="agent.id">
                                    <v-col cols="12" md="1">
                                      <v-icon  color="red">mdi-check-circle-outline</v-icon>
                                    </v-col>
                                    <v-col cols="12" md="10">
                                      <v-row >
                                        <v-card-title style="font-size: 12px" class="mt=0 ">{{agent.institute_name}}</v-card-title>
                                        <v-card-subtitle style="font-size: 12px" class="mt-0">{{agent.address}}</v-card-subtitle>
                                      </v-row>
                                      <v-row >
                                        <v-card-text>{{agent.visited_date}}</v-card-text>
                                        <v-chip v-if= "agent.locationType === 'startLocation' " color="blue" variant="flat" size="x-small" class="mt-3">
                                          Start Location
                                        </v-chip>
                                        <v-chip v-if= "agent.locationType === 'endLocation' " color="green" variant="flat" size="x-small" class="mt-4">
                                          End Location
                                        </v-chip>
                                      </v-row>
                                      <v-divider></v-divider>
                                    </v-col>
                                  </v-row>

                                </v-timeline-item>
                              </v-timeline>
                            </v-card-text>
                          </v-card>

                        </v-col>
                      </v-row>

                    </v-card>




                  </v-card>
                </v-row>
              </InfoWindow> -->


            </Marker>
          </Map>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" class="listColum">
        <v-card class="listCard pa-1" flat>
          <v-card-title class="text-grey">LIST OF AGENTS</v-card-title>
          <v-card-subtitle>List of all live agents & their Details.</v-card-subtitle>
          
          <v-list>
            <v-list-item >
              <v-card elevation="16" class="pa-2" v-for="agent in this.allOfficerDetails " :key="agent.id" >
              <v-row class="mt-2">
                <v-col cols="12" md="2" >
                  <v-avatar>
                    <v-img src="@/assets/ASSETS/PERSONS/person-3.jpg" alt="Avatar"></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="12" md="6" class="mt-3">
                  <v-list-item-content>
                    <v-list-item-title>{{ agent.name }}</v-list-item-title>
                    <!-- <v-list-item-subtitle>{{ agent.vehicle }}</v-list-item-subtitle> -->
                    <v-list-item-subtitle class="list-RegNum">Reg num: Reg0110002</v-list-item-subtitle>
                  </v-list-item-content>
                </v-col>
               
              </v-row>
            </v-card>
            </v-list-item>
          </v-list>
          
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Map, Marker, google  } from '@fawmi/vue-google-maps';
import axios from "axios";

export default {
  name: 'TrackingAnalysis',
  components: {
    Map, Marker
  },
  data() {
    return {
      markers: {}, // Initialize markers as an empty object
      agents: [
        {
          id: 1,
          name: 'Mr. Nehan Pannaseeha',
          regNum: '1001',
          vehicle: 'BHK-4321',
          avatar: 'path/to/avatar.jpg',
          trackingEnabled: true,
          position: { lat: 7.8731, lng: 80.7718 },
          location: 'Mawaramandiya',
          lastUpdated: '30 seconds ago',
          trips: [
            { id: 1, destination: 'Keells - Edmonton', time: '09:16 AM' },
            { id: 2, destination: 'Keells - Kohuwala', time: '10:30 AM' },
          ],
        },
      ],
      mapCenter: { lat: 7.8731, lng: 80.7718 },
      selectedAgent: {},
      dialog: false,

      allOfficerDetails:[],
      activeOfficerCount:0,
      deactiveOfficerCount:0,

    };
  },

  watch(){

  },

  mounted() {
        // this.listenForVehicleUpdates();
        this.getAllAgentAndCount();
       
       
        if (typeof google !== 'undefined' && google.maps) {
    // API is loaded, you can safely call updateUser now or later as needed
      console.log('fkkkk');
      } else {
        console.error('Google Maps API is not loaded yet.');
      }
      
      
          window.Echo.channel('SfaTeacking')
            .listen('LocationUpdated', (e) => {
                console.log('User updated:', e.location);
                // Update the dashboard with the new user data
                this.updateUser(e.location);
            });
    
    },

  methods: {
   
    updateUser(vehicle) {
           
      console.log('tracking',vehicle);
           const position = { 
             lat: vehicle.latitude, 
             lng: vehicle.longitude 
           };

           console.log('tracking',position);


           this.markers = [
             {
               agentId:vehicle.agent_id,
               itineraryId:vehicle.itinerary_id,
               outletId:vehicle.outlet_id,
               position: {
                 lat: position.lat,
                 lng: position.lng
               }
             }
           ];
                 
       },
       

       selectAgent(agent) {

          console.log('fkfkfll',agent);

          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem("token");

          const formData = new FormData();
          formData.append("userId", userId);
          formData.append("agentId", agent.agentId);
          formData.append("itineraryId", agent.itineraryId);
          formData.append("outletId", agent.outletId);

          axios.post('/api/getDetaisFromAgent',formData,{
            headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
              .then(response => {
                if (response.data.http_status === 'success') {
                  
                  this.officerDetails = response.data.officerDetails;
                  console.log("fk1",this.officerDetails);
                  this.outletDetails = response.data.outletDetails;
                  console.log("fk2", this.outletDetails);



                  this.selectedAgentsamplee = agent;
                  this.dialog = true;
                }
              })
              .catch(() => {
                console.log("fk2");
              });


          // this.selectedAgent = agent;
          this.dialog = true;
          },

          // Get All Agent Details and Active Deactive Ofiicer Count
    getAllAgentAndCount() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('userId', userId); 

      axios.post('/api/getAllAgentAndCount', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.status === 200) {
          this.allOfficerDetails = response.data.ofiicerDetails;
          this.activeOfficerCount = response.data.getActiveOfiicer;
          this.deactiveOfficerCount = response.data.getDeactiveOfiicer;

          this.$store.dispatch('updateAgentList', this.allOfficerDetails);


        } else {
          console.error("Error:", response.data.error);
        }
      })
      .catch(error => {
        console.error("Error fetching chart details:", error);
      });
      },
                  


    allAgents() {
      this.$router.push('/all-agents');
    },
    idling(){
      this.$router.push('/idling-agents');

    },
    parking(){
      this.$router.push('/parking-agents');
    },
    back(){
      this.$router.push('/live-tracking');
    }
  },
};
</script>




<style scoped>
.mainContainer{
  width: 100vw;
  height: 100vh;
}

.map {
  width: 100%;
  height: 100%;

}
.map-card {
  width: 400px;

}

.mapRow{
  padding: 10px;


}

.listColum{
  width: auto;
}

.box{
  font-size: 30px;
}

.on-trip-header{
  font-weight: bold;
  color: green;

}
.on-trip-subheader{
  font-weight: bold;
  font-size: 30px;
  color: black;

}
.on-trip-icon{
  color: white;
  background-color: green;

}
.on-trip-card{
  outline: green;
}
.e-button{
  font-size: 9px;
  font-weight: bold;
}
.BTNswitch{
  width: 100px;

}
.list-RegNum{
  color: #BB0000;
}
.gm-ui-hover-effect {
  top: 10px !important; /* Adjust top position */
  right: 10px !important; /* Adjust right position */
}

.back-button{
  display: flex;
  justify-content: right;
  margin-right: 20px;
  outline-style: hidden;

}

</style>









