<script>
  import {mapGetters} from "vuex";

  export default {
    data: () => ({
      drawer: true,      
      dialogLogout: false,
      group: null,
      activeSubMenu: null,
      menu: false,
      
    }),

    watch: {
      group () {
        this.drawer = false
      },
      
    },
    computed: {
      ...mapGetters(['sharedDataOrganization']),

    //   isDashboardVisible() {
    //     return (this.sharedDataOrganization?.['Dashboard'] || '0') === '1';
    //   },
    //   isProductManagementVisible() {
    //     return (this.sharedDataOrganization?.['Product Management'] || '0') === '1';
    //   },
    //   isLeadsManagementVisible() {
    //     return (this.sharedDataOrganization?.['Leads Management'] || '0') === '1';
    //   },
    //   isUserManagementVisible() {
    //     return (this.sharedDataOrganization?.['User Management'] || '0') === '1';
    //   },
    //   isSalesManagementVisible() {
    //     return (this.sharedDataOrganization?.['Sales Management'] || '0') === '1';
    //   },
    //   isCollectionManagementVisible() {
    //     return (this.sharedDataOrganization?.['Collection Management'] || '0') === '1';
    //   },
    //   isTrackingManagementVisible() {
    //     return (this.sharedDataOrganization?.['Tracking Management'] || '0') === '1';
    //   },
    //   isRouteManagementVisible() {
    //     return (this.sharedDataOrganization?.['Route Management'] || '0') === '1';
    //   },
    //   isOutletManagementVisible() {
    //     return (this.sharedDataOrganization?.['Outlet Management'] || '0') === '1';
    //   },
    },
    
    methods: {

        isLogOut() {
            window.location.href = '/login';
        },

        homeReload(){
            window.location.href = '/dashboard';
        },

        toggleSubMenu(menu) {
            this.activeSubMenu = this.activeSubMenu === menu ? null : menu;
        },

        isActiveParentMenu(menu) {
            const route = this.$route.path;
            const subMenus = {
                A: ['/add-products', '/products-list'],
                B: ['/add-leads', '/leads-list'],
                C: ['/user-registration', '/users-list', '/create-user-role', '/user-role-list'],
                D: ['/live-tracking', '/tracking-history'],
                E: ['/create-routes', '/routes-list'],
                F: ['/create-outlet', '/outlet-list'],
            };
            return subMenus[menu] && subMenus[menu].includes(route);
        },
    },
  }
</script>

<template>
    <v-app>
        <v-card>
        <v-layout>
            <v-app-bar
            class="gray-text" dense prominent
            >
            <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer" ></v-app-bar-nav-icon>
    
            <v-toolbar-title class="mt-1">
                <img :src="require('@/assets/app_bar_logo.png')"  @click="homeReload()"/>
            </v-toolbar-title>
    
            <v-spacer></v-spacer>

            <v-btn icon="mdi-bell-outline" variant="text"></v-btn>
    
            <!-- App bar icon & logout -->

            <div class="text-center">
                <v-menu
                v-model="menu"
                :close-on-content-click="false"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn class="mr-4" icon v-bind="props" variant="text">
                            <v-avatar>
                                <img :src="require('@/assets/Avatar.png')" class="avatar-image"/>
                            </v-avatar>
                        </v-btn>
                    </template>

                    <v-card class="mt-4 no-scroll">
                        <v-row class="mt-2 ml-1">
                            <v-col class="d-flex justify-center align-center" cols="3">
                                <v-card-title>
                                    <v-avatar size="x-large">
                                        <img :src="require('@/assets/Avatar.png')" class="avatar-image"/>
                                    </v-avatar>
                                </v-card-title>
                            </v-col>

                            <v-col class="text-left" cols="9">
                                <h6  class="user-role spaced-content">SUPER ADMIN</h6>
                                <h3 class="spaced-content black-text">Lahiru Gunawardhana</h3>
                                <h6 class="spaced-content gray-text">lahiru@celatatech.com</h6>
                                <h6 class="spaced-content gray-text">+94 078345123</h6>
                            </v-col>
                        </v-row>
                        <v-divider class="gray-text mt-6"></v-divider>

                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            dark
                            size="small"
                            variant="flat"
                            @click="isLogOut()"
                        >
                            Sign out
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </div>


            </v-app-bar>

            <v-navigation-drawer v-model="drawer" :class="{'drawer-open': drawer, 'drawer-closed': !drawer}" app clipped>
                <v-divider></v-divider>
                <v-card class="mx-3 mt-3 drawer-content">
                    <v-list background dense density="compact" nav rounded>

                        <!-- Dashboard -->
                        <v-list-item  active-class="red-background" link to="/dashboard">
                                <v-list-item-icon >
                                    <v-icon >mdi-home-outline</v-icon>
                                </v-list-item-icon>&nbsp;&nbsp;Dashboard
                        </v-list-item>

                        <!-- Product Management -->
                        <v-list-item  :class="{ 'red-background': isActiveParentMenu('A') }" link @click="toggleSubMenu('A')">
                            
                            <v-row class="align-center justify-space-between">
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-list-item-icon >
                                    <v-icon>mdi-store-outline</v-icon>&nbsp;&nbsp;Product Management
                                    </v-list-item-icon>
                                </v-col>
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-col>
                            </v-row>

                        </v-list-item>
                        <v-list-item-group  v-if="activeSubMenu === 'A'">
                            <v-list-item link to="/add-products">
                                <v-list-item-icon >
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Add Products
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/products-list">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Products List
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list-item-group>

                        <!-- Leads Management -->
                        <v-list-item  :class="{ 'red-background': isActiveParentMenu('B') }" link @click="toggleSubMenu('B')">
                                
                            <v-row class="align-center justify-space-between">
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-list-item-icon>
                                    <v-icon>mdi-account-circle-outline</v-icon>&nbsp;&nbsp;Leads Management
                                    </v-list-item-icon>
                                </v-col>
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-col>
                            </v-row>

                        </v-list-item>

                        <v-list-item-group v-if="activeSubMenu === 'B'">
                            <v-list-item link to="/add-leads">
                                <v-list-item-icon >
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Add Leads
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/leads-list">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Leads List
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list-item-group>

                        <!-- User Management -->
                        <v-list-item  :class="{ 'red-background': isActiveParentMenu('C') }" link @click="toggleSubMenu('C')">
                            
                            <v-row class="align-center justify-space-between">
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-list-item-icon>
                                    <v-icon>mdi-account-multiple-plus-outline</v-icon>&nbsp;&nbsp;User Management
                                    </v-list-item-icon>
                                </v-col>
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-col>
                            </v-row>

                        </v-list-item>
                        <v-list-item-group v-if="activeSubMenu === 'C'">
                            <v-list-item link to="/user-registration">
                                <v-list-item-icon >
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;User Registration
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/users-list">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Users List
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/create-user-role">
                                <v-list-item-icon >
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Create User Role
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/user-role-list">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;User Role List
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list-item-group>

                        <!-- Sales Management -->
                        <v-list-item active-class="red-background" link to="/sales-management">
                        <v-list-item-icon>
                            <v-icon >mdi-chart-line</v-icon> &nbsp;&nbsp;Sales Management
                        </v-list-item-icon>
                        </v-list-item>

                        <!-- Collection Management -->
                        <v-list-item  active-class="red-background" link to="/collection-management">
                        <v-list-item-icon>
                            <v-icon >mdi-folder-account-outline</v-icon>&nbsp;&nbsp;Collection Management
                        </v-list-item-icon>
                        </v-list-item>

                        <!-- Tracking Management -->
                        <v-list-item  :class="{ 'red-background': isActiveParentMenu('D') }" link @click="toggleSubMenu('D')">
                            
                            <v-row class="align-center justify-space-between">
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-list-item-icon>
                                    <v-icon>mdi-map-marker-path</v-icon>&nbsp;&nbsp;Tracking Management
                                    </v-list-item-icon>
                                </v-col>
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-col>
                            </v-row>

                        </v-list-item>

                        <v-list-item-group v-if="activeSubMenu === 'D'">
                            <v-list-item link to="/live-tracking">
                                <v-list-item-icon >
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Live Tracking
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/tracking-history">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Routeplan Tracking History
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/myroute-tracking-history">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Myroute Tracking History
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list-item-group>

                        <!-- Route Management -->
                        <v-list-item  :class="{ 'red-background': isActiveParentMenu('E') }" link @click="toggleSubMenu('E')">
                            
                            <v-row class="align-center justify-space-between">
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-list-item-icon>
                                    <v-icon>mdi-transit-connection-variant</v-icon>&nbsp;&nbsp;Route Management
                                    </v-list-item-icon>
                                </v-col>
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-col>
                            </v-row>

                        </v-list-item>
                        <v-list-item-group v-if="activeSubMenu === 'E'">
                            <v-list-item link to="/create-routes">
                                <v-list-item-icon >
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Create Routes
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/routes-list">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Routes List
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list-item-group>

                        <!-- Organization Management -->
                        <v-list-item active-class="red-background" link to="/organization-management">
                        <v-list-item-icon>
                            <v-icon >mdi-office-building-outline</v-icon>&nbsp;&nbsp;Organization Management
                        </v-list-item-icon>
                        </v-list-item>

                        <!-- Outlet Management -->
                        <v-list-item  :class="{ 'red-background': isActiveParentMenu('F') }" link @click="toggleSubMenu('F')">
                            
                            <v-row class="align-center justify-space-between">
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-list-item-icon>
                                    <v-icon>mdi-store-outline</v-icon>&nbsp;&nbsp;Outlet Management
                                    </v-list-item-icon>
                                </v-col>
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-col>
                            </v-row>

                        </v-list-item>
                        <v-list-item-group v-if="activeSubMenu === 'F'">
                            <v-list-item link to="/create-outlet">
                                <v-list-item-icon >
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Create Outlet
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/outlet-list">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Outlet List
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list-item-group>
                        
                        <!-- User Log -->
                        <v-list-item active-class="red-background" link to="/user-log">
                            <v-list-item-icon>
                                <v-icon >mdi-text-box-search-outline</v-icon> &nbsp;&nbsp;User Logs
                            </v-list-item-icon>
                        </v-list-item>

                    </v-list>

                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                <v-divider class="mx-3 mt-2"></v-divider>
                <v-card-text class="text-center">
                    Powered by CelataTech
                </v-card-text>
            </v-card>
            </v-navigation-drawer>
            
            <v-main :class="{'drawer-expanded': drawer}" class="full-height">
                <router-view/>
            </v-main>
        </v-layout>
        </v-card>

    </v-app>
  </template>

<style>

.full-height {
  height: 100vh;
  overflow: auto;
}

.red-background {
  background-color: #BB0000 !important;
  color: white !important;
}

.red-background .v-icon,
.red-background .v-list-item__title {
  color: white !important;
}

.drawer-open {
    width: auto !important;
    border: none !important;
    box-shadow: none !important;
    
}

.drawer-closed {
    width: 0 !important;
    border: none !important;
    box-shadow: none !important;
}

.drawer-content{
    display: flex;
    flex-direction: column;

    min-height: 96%;
    color: #757575;
}

.drawer-expanded{
    margin-left: 39px; 
    padding-right: 20px;
}

.gray-text{
    color: #757575 !important;
}

.user-role{
    color: #910404 !important;
}

.black-text{
    color:#000000 !important;
}

.spaced-content {
    margin: 0px;
}

.no-scroll {
    overflow-x: hidden !important;
}

.pa-0 {
  padding: 0;
}
.avatar-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

</style>