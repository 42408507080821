<template>
  <v-card class="mt-3 card-size" flat>
    <v-row class="pl-2 pr-6">
      <v-col cols="6" class="mt-3">
        <h3 class="page-title">REGISTERED USER LIST</h3>
        <h5 class="light-gray-text">Analyse already registered user data.</h5>
        <v-card-title>
          
        </v-card-title>
      </v-col>
      <v-col cols="6" class="px-4 mt-5">
        <v-row class="tool-bar-style">
            <v-btn size="x-large" class="red-text" variant="text"  @click="createUserRole()">
              <v-icon>mdi-account-star</v-icon>&nbsp;&nbsp;CREATE USER ROLE
            </v-btn>
            <v-divider vertical :thickness="3" class="divider-vertical"></v-divider>
            <v-btn variant="text" size="x-large" class="gray-text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card elevation="3" class="px-2 pt-2 mx-1 mb-2 leads-details-card">
      <!-- <h5 class="card-two-title px-3 mt-2 mb-4">USERS DATA ANALYSIS</h5> -->
      <h5 class="card-h4-title px-3 mt-2 mb-4">USERS DATA ANALYSIS
        <div class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-btn class="red-light-text mr-3" variant="outlined" @click="exportPDF">
            <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          
          <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
            <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </div>
      </h5>
      <v-divider></v-divider>   
      <v-row class="px-3 pt-6">
        <v-col cols="4">
          <v-select
            label="User Type"
            variant="outlined"
            placeholder="Select date range"
            :items="userTypes"
            v-model="userRole.userType"
            item-title="name"
            item-value="name"
          ></v-select>
        </v-col>
        <v-col cols="8">
          <v-text-field
              v-model="search"
              append-inner-icon="mdi-magnify"
              variant="outlined"
              placeholder="Search"
              label="Search"
              hide-details
              clearable
          ></v-text-field>
        </v-col>
      </v-row> 
      <v-col cols="12">
        <v-card class="mt-3 card-size" flat>
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            class="data-table dataTable"
            :items-per-page="itemsPerPage"
            v-model:page="page"
            @page-count="totalPages = $event"
            width="100%"
          >
          <!-- Template for mobile_number -->
            <template v-slot:[`item.mobile_number`]="{ item }">
              <div>
                <!-- <v-chip
                  v-for="(mobile, index) in item.mobile_number"
                  :key="index"
                  :size="x-small"
                  color="#CBAB04"
                  outlined
                  label
                  class="mp-0 my-1"
                >
                  {{ mobile.mobile_number }}
                </v-chip> -->
                <v-col
                  v-for="(mobile, index) in item.mobile_number"
                  :key="index"
                  cols="12"
                >
                  <v-chip
                    :size="x-small"
                    label
                    class="px-1"
                    variant="text"
                  >
                    {{ mobile.mobile_number }}
                  </v-chip>
                </v-col>
              </div>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-row>
                <v-col cols="4">
                  <v-btn
                  class="mr-3 bg-blue-lighten-5 viewbtn"
                        variant="outlined"
                        color="blue"
                    @click="viewItem(item)"
                  >
                    <v-icon>mdi-eye</v-icon>&nbsp;  view
                  </v-btn>
                </v-col>
                
                <v-col cols="2">
                  <v-btn
                    class="bg-green-lighten-5"
                    rounded
                    variant="outlined"
                    size="small"
                    color="green"
                    icon
                    @click="editItem(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
                
                <v-col cols="2">
                  <v-btn
                    class="bg-red-lighten-5"
                    rounded
                    variant="outlined"
                    size="small"
                    color="red"
                    icon
                    @click="confirmDelete(item)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-col>
                
                <v-col cols="2">
                  <v-btn
                  class="gray-icon-button"
                  rounded
                  variant="outlined"
                  size="small"
                  color="gray"
                  icon
                  @click="lockItem(item)"
                >
                  <v-icon>mdi-lock-reset</v-icon>
                </v-btn>
                </v-col>
                
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Delete user confirmation dialog -->
    <v-dialog v-model="delete_user_dialog" max-width="500" max-height="500" persistent>
      <v-card class="pa-1">
        <v-row dense class="rowClr ma-1">
          <v-col cols="12" md="9">
            <v-card-title class="headline">DELETE USER DATA</v-card-title>
          </v-col>
          <v-col cols="12" md="3" class="d-flex justify-end pt-3 pr-3">
            <v-btn icon size="x-small" @click="delete_user_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-divider class="mt-3"></v-divider>

        <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this user ?</v-card-text>
        <v-card-text class="textspace">
          This user will be deleted immediately. Once deleted, it can no longer be used in any purpose.
        </v-card-text>
        <v-divider class="mb-3"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="deleteItem" variant="outlined" class="bg-red-lighten-4">DELETE</v-btn>&nbsp; &nbsp; 
          <v-btn @click="delete_user_dialog = false" variant="outlined" class="bg-grey-lighten-2">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- VIEW USER DIALOG -->
    <v-dialog v-model="view_user_dialog" fullscreen>
      <v-card class="card-size">
        <v-toolbar dense color="white" class="pr-5 mb-5">
          <v-toolbar-title>
              <img @click="homeReload()" :src="require('@/assets/app_bar_logo.png')"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" variant="elevated" dark @click="view_user_dialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-card class="pa-3 mx-6">
            <v-icon>mdi-view-dashboard
            </v-icon>
            <v-label class="ml-6">USER DETAILS</v-label>
            </v-card>
            <v-card class="pa-3 mx-6 mt-3">
              <v-row class="first_row ma-0 pa-0 ">
                <v-col cols="12" class="secondC textCenter" >
                
                <div class="image card-h4-title">
                  <!-- <img :src="user.user_image" class="avatar-image"/> -->
                  <!-- <img :src="`data:image/jpeg;base64,${user.user_image}`" class="avatar-image"/> -->
                  <!-- <img :src="`https://sfa.backend.celata.org/${user.user_image}`" class="avatar-image" /> -->
                  <img :src="user.user_image ? `https://sfa.backend.celata.org/${user.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')" class="avatar-image"/>
                </div>

                <div class="mt-6 ml-6">
                  <v-row>
                    <h3 class="dataCard">Agent name: </h3>
                    <h3 class="dataCard text-red">&nbsp; {{ user.name }}</h3>
                  </v-row>
                  <v-row class="mt-6">
                    <h5>Mobile : &nbsp; {{ user.mobile_number.map(num => num.mobile_number).join(', ') }}</h5>
                  </v-row>
                  <v-row class="mt-6">
                    <v-chip variant="outlined" class="mr-3 chip1clr" color="green" text-color="white">
                      <v-icon color="green">mdi-check-circle-outline</v-icon>&nbsp; 
                      USER TYPE - {{ user.user_type }}
                    </v-chip>
                  </v-row>
                </div>
                <v-spacer></v-spacer>
                <div class="justify-right align-right ml-6">
                  <v-btn
                  class="bg-green-lighten-5 mr-4"
                  rounded
                  variant="outlined"
                  size="small"
                  color="green"
                  icon
                  @click="editItem(user)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  class="bg-red-lighten-5"
                  rounded
                  variant="outlined"
                  size="small"
                  color="red"
                  icon
                  @click="confirmDelete(user)"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
                </div>
                </v-col>
              </v-row>
              
              <v-divider class="my-3"></v-divider>
              <v-row class="pt-6">
                <v-col cols="6" class="px-6">
                  <!-- <v-card flat class="pt-2 card-hight-fixed"> -->
                    <v-text-field
                      class="mb-2"
                      label="User Name"
                      v-model="user.user_name"
                      readonly
                      variant="outlined"
                    ></v-text-field>

                    <v-text-field
                      class="mb-2"
                      label="User Type"
                      v-model="user.user_type"
                      readonly
                      variant="outlined"
                    ></v-text-field>

                    <v-text-field
                      class="mb-2"
                      label="Province"
                      v-model="user.province"
                      readonly
                      variant="outlined"
                    ></v-text-field>

                    <v-text-field
                      class="mb-2"
                      label="District"
                      v-model="user.district"
                      readonly
                      variant="outlined"
                    ></v-text-field>

                    <v-text-field
                      class="mb-2"
                      label="DSD"
                      v-model="user.dsd"
                      readonly
                      variant="outlined"
                    ></v-text-field>

                    <v-text-field
                      class="mb-2"
                      label="GND"
                      v-model="user.gnd"
                      readonly
                      variant="outlined"
                    ></v-text-field>

                    <v-text-field
                      class="mb-2"
                      label="Email Address"
                      v-model="user.email"
                      readonly
                      variant="outlined"
                    ></v-text-field>
                  <!-- </v-card> -->
                </v-col>
                <v-col cols="6" class="px-6">
                  <!-- <v-card flat class="pt-2 card-hight-fixed"> -->
                    <v-text-field
                      class="mb-2"
                      label="NIC"
                      v-model="user.nic"
                      readonly
                      variant="outlined"
                    ></v-text-field>

                    <v-text-field
                      class="mb-2"
                      label="Registration Number"
                      v-model="user.registration_num"
                      readonly
                      variant="outlined"
                    ></v-text-field>


                    <div v-for="(mobile, index) in user.mobile_number" :key="index">
                      <!-- <v-col cols="12"> -->
                        <v-text-field
                          :label="'Mobile Number ' + (index + 1)"
                          v-model="mobile.mobile_number"
                          readonly
                          class="mb-2"
                          variant="outlined"
                        ></v-text-field>
                      <!-- </v-col> -->
                    </div>

                    <v-text-field
                      class="mb-2"
                      label="Gender"
                      v-model="user.gender"
                      readonly
                      variant="outlined"
                    ></v-text-field>

                    <v-textarea
                      class="mb-2"
                      label="Address"
                      v-model="user.address"
                      readonly
                      rows="3"
                      variant="outlined"
                    ></v-textarea>
                  <!-- </v-card> -->
                </v-col>
              </v-row>
            </v-card>

          </v-col>
        </v-row>

      </v-card>
    </v-dialog>
    
    <!-- EDIT USER DIALOG -->
    <v-dialog v-model="edit_user_dialog" fullscreen>
      <v-card class="card-size">
        <v-toolbar dense color="white" class="pr-5 mb-5">
          <v-toolbar-title>
              <img @click="homeReload()" :src="require('@/assets/app_bar_logo.png')"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" variant="elevated" dark @click="edit_user_dialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-card class="pa-3 mx-6">
            <v-icon>mdi-view-dashboard
            </v-icon>
            <v-label class="ml-6">UPDATE USER DETAILS</v-label>
            </v-card>
            <v-card class="pa-3 mx-6 mt-3">
              <v-row class="first_row ma-0 pa-0 ">
                <v-col cols="12" class="secondC textCenter" >
                
                <div class="image card-h4-title">
                  <!-- <img :src="require('@/assets/ASSETS/PERSONS/person-3.jpg')" class="avatar-image"/> -->
                  <img :src="user.user_image ? `https://sfa.backend.celata.org/${user.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')" class="avatar-image"/>
                </div>

                <div class="mt-6 ml-6">
                  <v-row>
                    <h3 class="dataCard">Agent name: </h3>
                    <h3 class="dataCard text-red">&nbsp; {{ user.name }}</h3>
                  </v-row>
                  <v-row class="mt-6">
                    <!-- <h5>Mobile : &nbsp; +94 711872828</h5> -->
                    <h5>Mobile : &nbsp; {{ user.mobile_number.map(num => num.mobile_number).join(', ') }}</h5>
                  </v-row>
                  <v-row class="mt-6">
                    <v-chip variant="outlined" class="mr-3 chip1clr" color="green" text-color="white">
                      <v-icon color="green">mdi-check-circle-outline</v-icon>&nbsp; 
                      USE TYPE - {{ user.user_type }}
                    </v-chip>
                  </v-row>
                </div>
                <v-spacer></v-spacer>
                <div class="justify-right align-right ml-6">
                  <v-btn variant="outlined" size="x-large" class="red-background-text" @click="updateUser">UPDATE USER DATA</v-btn>
                </div>
                </v-col>
              </v-row>
              
              <v-divider class="my-3"></v-divider>
              <v-row class="pt-6">
                <v-col cols="6" class="px-6">
                  <v-card flat class="pt-2 card-hight-fixed">
                    <v-col cols="11">

                    <v-text-field
                      class="mb-2"
                      label="User Name *"
                      v-model="user.user_name"
                      variant="outlined"
                      required
                    ></v-text-field>

                    <!-- <v-text-field
                      class="mb-2"
                      label="User Type *"
                      v-model="user.user_type"
                      variant="outlined"
                      required
                    ></v-text-field> -->

                    <v-select
                      class="mb-2"
                      label="User Type *"
                      variant="outlined"
                      :items="userTypes"
                      v-model="user.user_type"
                      item-title="name"
                      item-value="name"
                      :rules="[v => !!v || 'User role is required']"
                      required
                      placeholder="Select User Type Here"
                    ></v-select>

                    <!-- <v-text-field
                      class="mb-2"
                      label="Province *"
                      v-model="user.province"
                      variant="outlined"
                      required
                    ></v-text-field>

                    <v-text-field
                      class="mb-2"
                      label="District *"
                      v-model="user.district"
                      variant="outlined"
                      required
                    ></v-text-field>

                    <v-text-field
                      class="mb-2"
                      label="DSD *"
                      v-model="user.dsd"
                      variant="outlined"
                      required
                    ></v-text-field>

                    <v-text-field
                      class="mb-2"
                      label="GND *"
                      v-model="user.gnd"
                      variant="outlined"
                      required
                    ></v-text-field> -->
                    <v-select
                      class="mb-2"
                      label="Province"
                      variant="outlined"
                      placeholder="Select province"
                      v-model="user.province"
                      :items="provinces"
                      @update:modelValue="getAllDistrictAccProv"
                      item-text="label"
                      item-value="value"
                      :loading="mDistrictLoading"
                    ></v-select>

                    <v-select
                      class="mb-2"
                      label="District"
                      variant="outlined"
                      placeholder="Select district"
                      v-model="user.district"
                      :items="districts"
                      @update:modelValue="getAllDsd"
                      item-text="label"
                      item-value="value"
                      :loading="mDistrictLoading"
                    ></v-select>

                    <v-select
                      class="mb-2"
                      label="Divisional"
                      variant="outlined"
                      placeholder="Select divisional"
                      v-model="user.dsd"
                      :items="dsdList"
                      item-text="label"
                      item-value="value"
                      @update:modelValue="getAllGnd"
                      :loading="mDSDLoading"
                    ></v-select>

                    <v-select
                      class="mb-2"
                      label="GND *"
                      variant="outlined"
                      :items="gndList"
                      v-model="user.gnd"
                      required
                      :loading="mDSDLoading"
                      placeholder="Select GND"
                    ></v-select>


                    <!-- <v-text-field
                      class="mb-2"
                      label="Password *"
                      type="password"
                      variant="outlined"
                      placeholder="Type password here"
                      v-model="user.password"
                      required
                      append-inner-icon="mdi-eye"
                    ></v-text-field> -->

                    <v-text-field
                      class="mb-2"
                      label="Email Address *"
                      v-model="user.email"
                      variant="outlined"
                      required
                      placeholder="Type Email Here"
                    ></v-text-field>

                    <!-- <v-text-field
                      class="mb-2"
                      label="Confirm Password *"
                      type="password"
                      variant="outlined"
                      placeholder="Confirm your password here"
                      v-model="user.confirmPassword"
                      required
                      append-inner-icon="mdi-eye"
                    ></v-text-field> -->
                  </v-col>
                  </v-card>
                </v-col>
                <v-col cols="6" class="px-6">
                  <v-card flat class="pt-2 card-hight-fixed">
                    <v-col cols="11">

                    <v-text-field
                      class="mb-2"
                      label="Registration Number *"
                      v-model="user.registration_num"
                      variant="outlined"
                      placeholder="Type Mobile Number Here"
                    ></v-text-field>
                  
                    <div v-for="(phoneNo, index) in user.mobile_number" :key="index">
                      <v-row>
                        <v-col cols="8">
                          <v-text-field
                            :label="'Mobile Number ' + (index + 1)"
                            v-model="phoneNo.mobile_number"
                            variant="outlined"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="auto" class="align-center justify-center">
                          <v-btn
                            density="default"
                            class="green-text"
                            variant="outlined"
                            icon="mdi-plus-thick"
                            @click="addPhoneNo"
                          ></v-btn>
                        </v-col>
                        <v-col cols="auto" class="align-center justify-center">
                          <v-btn
                            density="default"
                            class="red-text-btn"
                            variant="outlined"
                            icon="mdi-minus-thick"
                            @click="removePhoneNo(index)"
                            v-if="user.mobile_number.length > 1"
                          ></v-btn>
                        </v-col>
                      </v-row>
                    </div>

                    <v-text-field
                      class="mb-2"
                      label="NIC *"
                      v-model="user.nic"
                      variant="outlined"
                      required
                      placeholder="Type NIC"
                    ></v-text-field>

                    <!-- <v-text-field
                      class="mb-2 mt-1"
                      label="Gender *"
                      v-model="user.gender"
                      variant="outlined"
                      required
                    ></v-text-field> -->
                    <v-select
                      class="mb-2"
                      label="Gender *"
                      variant="outlined"
                      :items="gender"
                      v-model="user.gender"
                      required
                      placeholder="Select Gender"
                    ></v-select>

                    <v-textarea
                      class="mb-2"
                      label="Address *"
                      v-model="user.address"
                      variant="outlined"
                      rows="3"
                      required
                      placeholder="Type Address Here"
                    ></v-textarea>
                  </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

          </v-col>
        </v-row>

      </v-card>
    </v-dialog>

    <!-- reset user password dialog -->
    <v-dialog v-model="reset_password_dialog" fullscreen>
      <v-card class="card-size">
        <v-toolbar dense color="white" class="pr-5 mb-5">
          <v-toolbar-title>
              <img @click="homeReload()" :src="require('@/assets/app_bar_logo.png')"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" variant="elevated" dark @click="reset_password_dialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-card class="pa-3 mx-6">
            <v-icon>mdi-view-dashboard
            </v-icon>
            <v-label class="ml-6">RESET PASSWORD</v-label>
            </v-card>
            <v-card class="pa-3 mx-6 mt-3">
              <v-row class="first_row ma-0 pa-0 ">
                <v-col cols="12" class="secondC textCenter" >
                
                <div class="image card-h4-title">
                  <!-- <img :src="require('@/assets/ASSETS/PERSONS/person-3.jpg')" class="avatar-image"/> -->
                  <img :src="user.user_image ? `https://sfa.backend.celata.org/${user.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')" class="avatar-image"/>
                </div>

                <div class="mt-6 ml-6">
                  <v-row>
                    <h3 class="dataCard">Agent name: </h3>
                    <h3 class="dataCard text-red">&nbsp; {{ user.name }}</h3>
                  </v-row>
                  <v-row class="mt-6">
                    <h5>Mobile : &nbsp; {{ user.mobile_number.map(num => num.mobile_number).join(', ') }}</h5>
                  </v-row>
                  <v-row class="mt-6">
                    <v-chip variant="outlined" class="mr-3 chip1clr" color="green" text-color="white">
                      <v-icon color="green">mdi-check-circle-outline</v-icon>&nbsp; 
                      USE TYPE - {{ user.user_type }}
                    </v-chip>
                  </v-row>
                </div>
                <v-spacer></v-spacer>
                
                </v-col>
              </v-row>
              
              <v-divider class="my-3"></v-divider>
              <v-row class="pt-6">
                <v-col cols="7" class="px-6">
                  <v-card flat class="card-hight-fixed">
                    
                    <v-tabs
                      v-model="tab"
                      grow
                    >
                      <v-tab :class="{ 'red-light-text': tab === 'one' }" value="one">GENERATE RESET LINK</v-tab>
                      <v-tab :class="{ 'red-light-text': tab === 'two' }" value="two">AUTO GENERATE PASSWORD</v-tab>
                      <!-- <v-tab :class="{ 'red-light-text': tab === 'three' }" value="three">MANUAL PASSWORD CHANGE</v-tab> -->
                    </v-tabs>

                    <v-tabs-window v-model="tab">
                      <v-tabs-window-item value="one" class="mt-9">
                        <v-btn variant="outlined" size="x-large" class="red-white-text" @click="generateResetLink(user)">GENERATE & SEND RESET LINK</v-btn>
                        <h5 class="red-text mt-9">RESET LINK: &nbsp; http://localhost:8080/users-listhttp://localhost:8080/users-listhttp://localhost:8080/users-listhttp://localhost:8080/users-listhttp://localhost:8080/users-list</h5>
                      </v-tabs-window-item>

                      <v-tabs-window-item value="two" class="mt-9">
                        <v-btn variant="outlined" size="x-large" class="red-white-text" @click="generateAndSendPassword(user)">GENERATE PASSWORD & SEND LINK</v-btn>
                        
                      </v-tabs-window-item>

                      <!-- <v-tabs-window-item value="three" class="mt-9">
                        <v-text-field
                          class="mb-2"
                          label="Password *"
                          type="password"
                          variant="outlined"
                          placeholder="Type password here"
                          v-model="user.password"
                          required
                          append-inner-icon="mdi-eye"
                        ></v-text-field>
                        <v-text-field
                          class="mb-2"
                          label="Confirm Password *"
                          type="password"
                          variant="outlined"
                          placeholder="Confirm your password here"
                          v-model="user.confirmPassword"
                          required
                          append-inner-icon="mdi-eye"
                        ></v-text-field>
                        <v-row class="margin-bottomn-fix pa-3 mb-2">
                          <v-btn variant="outlined" class="red-background-text mr-3"  @click="updatePasswordRest(item)">UPDATE</v-btn>
                          <v-btn variant="outlined" class="gray-background-text" @click="reset_password_dialog = false">CANCEL</v-btn> 
                        </v-row>
                      </v-tabs-window-item> -->
                    </v-tabs-window>
                  </v-card>

                </v-col>
                <v-col cols="5" class="d-flex flex-column justify-end">
                  <img
                    class="custom-create-product-category"
                    :src="require('@/assets/ASSETS/ILLUSTRATOR/reset_passwoard.png')"
                    
                />
                </v-col>
              </v-row>
            </v-card>

          </v-col>
        </v-row>

      </v-card>
    </v-dialog>

  </v-card>
  <!-- Snackbar to show notifications -->
    <v-snackbar
      v-model="snackAlert.snackbar"
      :color="snackAlert.snackColor"
      :top="snackAlert.snackBarTop"
      :right="snackAlert.snackBarRight"
      timeout="3000"
    >
      {{ snackAlert.snackMessage }}
      
    </v-snackbar>
  </v-card>
</template>
    
<script>
  import axios from 'axios';
  import jsPDF from 'jspdf';
  import 'jspdf-autotable';
  import * as XLSX from "xlsx";
  export default {
    name: 'UserRoleList',
    data() {
      return {

        snackAlert: {
          snackbar: false, // Snackbar visibility
          snackColor: "", // Snackbar color
          snackMessage: "", // Snackbar message
          snackBarRight: false, // Snackbar position right
          snackBarTop: true,  // Snackbar position top
        },
        // Dialog state
        delete_user_dialog: false,
        view_user_dialog: false,
        edit_user_dialog: false,
        reset_password_dialog: false,

        //reset password three options tabs
        tab: null,
        autoGenaratePassword: null,

        search: '',
        headers: [
          { text: 'REG: NUMBER', value: 'registration_num', title: 'REG: NUMBER', sortable: true  },
          { text: 'USER NAME', value: 'user_name', title: 'USER NAME', sortable: true  },
          { text: 'USER TYPE', value: 'user_type', title: 'USER TYPE', sortable: false  },
          { text: 'MOBILE', value: 'mobile_number', title: 'MOBILE', sortable: true  },
          { text: 'NIC', value: 'nic', title: 'NIC', sortable: true  },
          { text: 'ACTION', value: 'action', title: 'ACTION'}
        ],
        items: [],

        userTypes: [],
        gender: ['Male', 'Female'],

        itemsPerPage: 10,
        page: 1,
        totalPages: 1,
        
        userRole: {
          userType: '',
          
        },
        // user: {
        //   name: '',
        //   user_name: '',
        //   province: '',
        //   district: '',
        //   dsd: '',
        //   gnd: '',
        //   email: '',
        //   nic: '',
        //   registration_num: '',
        //   mobile_number: [''],
        //   gender: '',
        //   address: '',
        //   password: '',
        //   confirmPassword: '',
        //   user_type: '',
        //   user_image: '',
        // },

        user:{},

        mDistrictLoading: false,
        mDSDLoading: false,
        mGNDLoading: false,
        provinces: [],
        districts: [],
        dsdList: [],
        gndList: [],
        // user.mobile_number: [""],
        // user: {},
        deleteItemId: null, // Store the ID of the item to be deleted
      };
    },

    mounted() {
      this.getAllProvinces();
      this.getAllUserRoles();
    },
    created(){
      this.fetchDataintoTable();
    },
    computed: {
      // filteredItems() {
      //   return this.items.filter(item => {
      //     const matchesUserRole = !this.userRole.userType || item.user_type === this.userRole.userType;
      //     const matchesSearch = !this.search || item.user_name.toLowerCase().includes(this.search.toLowerCase());
      //     return matchesUserRole && matchesSearch;
      //   });
      // }
      
      filteredItems() {
        const searchLower = this.search.toLowerCase();

        return this.items.filter(item => {
          const matchesUserRole = !this.userRole.userType || item.user_type === this.userRole.userType;
          const registrationNum = item.registration_num ? item.registration_num.toLowerCase() : '';
          const userName = item.user_name ? item.user_name.toLowerCase() : '';
          const userType = item.user_type ? item.user_type.toLowerCase() : '';
          const nic = item.nic ? item.nic.toLowerCase() : '';
          // const mobileNumbers = item.mobileNumbers
          //   ? item.mobileNumbers.map(mobile => mobile.toLowerCase())
          //   : [];

          const matchesSearch =
            !this.search ||
            userName.includes(searchLower) ||
            registrationNum.includes(searchLower) ||
            userType.includes(searchLower) ||
            nic.includes(searchLower) ;
            // || mobile_number.some(mobile => mobile.includes(searchLower));

          return matchesUserRole && matchesSearch;
        });
      }



    },
    methods: {
      showSnackbar(message, color) {
        this.snackAlert.snackbar = true;
        this.snackAlert.snackMessage = message;
        this.snackAlert.snackColor = color;
      },
      exportExcel() {
        const tableData = this.items.map((item, index) => ({
          '#': index + 1,
          'REG: NUMBER': item.registration_num,
          'USER NAME': item.user_name,
          'USER TYPE': item.user_type,
          // 'MOBILE': item.mobile_number,
          // Join mobile numbers with a comma or newline
          'MOBILE': item.mobile_number.map(m => m.mobile_number).join(', '),
          'NIC': item.nic,
        }));

        const worksheet = XLSX.utils.json_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Leads');


        XLSX.writeFile(workbook, 'users_analysis.xlsx');
      },

      exportPDF() {
        const doc = new jsPDF();

        // Set the title for the document
        doc.text('User Analysis', 14, 10);

        // Prepare the data for the table
        const tableData = this.items.map((item, index) => [
          index + 1,
          item.registration_num,
          item.user_name,
          item.user_type,
          // item.mobile_number,
          // Join mobile numbers with a newline
          item.mobile_number.map(m => m.mobile_number).join('\n'),
          item.nic,
        ]);

        // Define the columns for the table
        const tableColumns = ['#', 'REG: NUMBER', 'USER NAME', 'USER TYPE', 'MOBILE', 'NIC'];

        // AutoTable plugin to create a table
        doc.autoTable({
          head: [tableColumns],
          body: tableData,
        });

        // Save the PDF with a name
        doc.save('users_analysis.pdf');

      },


      getAllProvinces() {
        this.districts = [];
        this.dsdList = [];
        this.gndList = [];
        this.mDistrictLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const header = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
            .then(response => {
              if (response.data.http_status === 'success') {
                this.provinces = response.data.data;
                this.mDistrictLoading = false;
              }
            })
            .catch(() => {
              this.mDistrictLoading = false;
            });
      },

      getAllDistrictAccProv(province) {
        console.log('Province selected:', province);
        this.districts = [];
        this.mDistrictLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const headers = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
            .then(response => {
              console.log('District API response:', response.data);
              if (response.data.http_status === 'success') {
                this.districts = response.data.data;
                console.log('District API response:', response.data);
                this.mDistrictLoading = false;

              }
            })
            .catch(error => {
              this.mDistrictLoading = false;
              console.error('Error fetching districts:', error);

            });
      },

      getAllDsd(district) {
        this.dsdList = [];
        this.mDSDLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const header = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
            .then(response => {
              if (response.data.http_status === 'success') {
                this.dsdList = response.data.data;
                this.mDSDLoading = false;
              }
            })
            .catch(() => {
              this.mDSDLoading = false;
            });
      },

      getAllGnd(dsd) {
        this.gndList = [];
        this.mGNDLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const header = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
            .then(response => {
              if (response.data.http_status === 'success') {
                this.gndList = response.data.data;
                this.mGNDLoading = false;
              }
            })
            .catch(() => {
              this.mGNDLoading = false;
            });
      },

      async updateUser() {
      try {
        const token = localStorage.getItem("token");
        const logUserId = localStorage.getItem('userId');

        if (!this.user.id) {
          console.error("User ID is missing");
          return;
        }

        // Form validation
        // if (this.$refs.form && this.$refs.form.validate()) {
          const formData = new FormData();
          formData.append("userId", this.user.id);
          formData.append("logUserId", logUserId);
          formData.append("userName", this.user.user_name);
          formData.append("fullName", this.user.name);
          formData.append("userType", this.user.user_type);
          formData.append("province", this.user.province);
          formData.append("district", this.user.district);
          formData.append("dsd", this.user.dsd);
          formData.append("gnd", this.user.gnd);
          formData.append("password", this.user.password);
          formData.append("confirmPassword", this.user.confirmPassword);
          formData.append("emailAddress", this.user.email);
          formData.append("nic", this.user.nic);
          formData.append("registrationNumber", this.user.registration_num);
          formData.append("gender", this.user.gender);
          formData.append("address", this.user.address);
          // formData.append("mobileNumber", this.user.mobile_number);
          
          // let mobileNumbersArray = [];
          // if (Array.isArray(this.user.mobile_number)) {
          //   this.user.mobile_number.forEach((phoneNo) => {
          //     console.log('sasas',phoneNo.mobile_number);
          //    mobileNumbersArray.push(phoneNo.mobile_number);
          //    console.log('num',mobileNumbersArray);
          //   });
          // }
          // formData.append(`mobileNumber`, mobileNumbersArray);

          // Handle mobile numbers
          const mobileNumbersArray = this.user.mobile_number.map(phoneNo => phoneNo.mobile_number);
          // formData.append("mobileNumber", JSON.stringify(mobileNumbersArray));
          formData.append("mobileNumber", mobileNumbersArray);



          // Append image if available
          if (this.user.image && this.user.image.length > 0) {
            const base64String = await this.convertToBase64(this.user.image[0]);
            formData.append("image", base64String);
          }
          console.log([...formData]);
          // API call
          const response = await axios.post(
            "/api/createdUserUpdate",
            formData,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
              }
            }
          );

          if (response.status === 201) {
            this.showSnackbar("User updated successfully", "success");
            this.edit_user_dialog = false;
            this.fetchDataintoTable();
          } else {
            this.showSnackbar(response.data.message || "Failed to update user", "error");
          }
        // } else {
        //   this.showSnackbar("Please fill all required fields", "warning");
        // }
      } catch (error) {
        console.error("Error updating user:", error);
        this.showSnackbar("An error occurred while updating user", "error");
      }
    },

    // Convert image to base64 string (if necessary)
    async convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

      getAllUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token'); 

      axios.post('/api/getUserRolesType', {
          userId: userId
      }, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      })
      .then(response => {
          let usrRole = response.data.data;
          this.userTypes = usrRole.map(item => {
              return {
                  id: item.id,
                  name: item.role_type
              };
          });
          console.log('User role types:', this.userTypes);
      })
      .catch(error => {
          console.error('Error fetching user roles:', error);
      });
    },

      fetchDataintoTable(){

          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem("token");

          const formData = new FormData();
          formData.append("userId", userId);
          

          axios.post("/api/createdUserList", formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
            }).then((response) => {
                //  this.items= response.data.data;
                // Sort the data by created_at in descending order (newest first)
                this.items = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                console.log('table data',this.items);
            })
            .catch((error) => {
                // if (error.response.status === 401) {

                // } else {
                    
                // }

                console.log(error);
                
            });
      },

     
      backPageRoute(){
        this.$router.back();
      },
     
      viewItem(item) {
        this.user = item;
        this.view_user_dialog = true;
        console.log("View item:", item);
      },
      editItem(item) {
        this.user = { ...item };
        this.edit_user_dialog = true;
        console.log("Edit item:", item);
      },
     
      lockItem(item) {
        console.log("Lock item:", item);
        this.user = item;
        this.reset_password_dialog = true;
      },
      async generateResetLink(user) {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        const emails = user.email;
        
        try {
          const response = await fetch('/api/forgetPassword', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ userId, emails })
          });
          
          if (response.ok) {
            console.log("Reset link generated and sent to:", emails);
          } else {
            console.error("Error generating reset link:", response.statusText);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      },
      async generateAndSendPassword(user) {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        const emails = user.email;
        
        try {
          const response = await fetch('/api/submitResetPasswordForm', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ userId, emails })
          });
          
          if (response.ok) {
            console.log("Password generated and sent to:", emails);
          } else {
            console.error("Error generating password:", response.statusText);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      },
      confirmDelete(item) {
        this.delete_user_dialog = true;
        this.deleteItemId = item.id;
      },
      deleteItem() {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        const userListId = this.deleteItemId; // Use the stored ID
        axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/createdUserdelete`, {
          userId: userId,
          userListId: userListId
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
          .then(() => {
            this.fetchDataintoTable(); // Refresh the user list
            this.delete_user_dialog = false;
            this.edit_user_dialog = false;
            this.view_user_dialog = false;
          })
          .catch(error => {
            console.error('Error deleting users:', error);
            this.delete_user_dialog = false;
            this.edit_user_dialog = false;
            this.view_user_dialog = false;
          });
      },
      homeReload(){
        window.location.href = '/dashboard';
      },
      // addPhoneNo() {
      //   this.user.mobile_number.push('');
      //   // this.user.mobile_number.push({ mobile_number: '' });
      // },
      // removePhoneNo(index) {
      //   // if (this.user.mobile_number.length > 1) {
      //     this.user.mobile_number.splice(index, 1);
      //   // }
      // },

      addPhoneNo() {
        this.user.mobile_number.push({ mobile_number: '' });
      },
      removePhoneNo(index) {
        if (this.user.mobile_number.length > 1) {
          this.user.mobile_number.splice(index, 1);
        }
      },

      
      // generateResetLink(item){
      //   this.user = item;
      //   console.log("resentlink password item:", item);
      // },
      // generateAndSendPassword(item){
      //   this.user = item;
      //   console.log("autoGenarateLink password item:", item);
      // },
      updatePasswordRest(item){
        console.log("update password item:", item);
      },
      createUserRole(){
        this.$router.push({ path: '/create-user-role' });
      },
    }
  };
</script>

<style scoped>
  .v-card-title {
    font-weight: bold;
  }
  .card-h4-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    color: black;
  }
  .red-text {
    color: #b00d0d;
  }
  .red-light-text {
    background-color: #f1cccc;
    color: #b00d0d;
  }
  .red-white-text {
    background-color: #b00d0d;
    color: white;
  }
  .green-light-text {
    background-color: #aee8b1;
    color: #057b0a;
  }
  .green-text {
    color: #057b0a;
    background-color: #aee8b1;
  }
  .gray-icon-button {
    color: gray;
    background-color: #e2e2e2;
  }
  .card-size{
    width: 100% !important;
    padding: 0;
    margin-bottom: 0;
  }
  .page-title {
    font-weight: bold;
  }

  .light-gray-text{
    color: #a09e9e !important;
  }
  .data-table {
    width: 100% !important;
  }
  .btn-border{
    border-block-end-color: #9a1313;
  }
  .tool-bar-style {
    display: flex;
    justify-content: end;
  }

  .divider-vertical{
    color: #3c3b3b !important;
  }
  .card-two-title{
    color: black;
  }
  .rowClr{
    background-color: #d9d9d9;
  }

  .textspace{
    margin-top: -20px;
  }
  
  .headline {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .divider-margin-dv{
    margin-top: -20px;
  }

  .secondC{
    display: flex;
  }

  .textCenter {
    text-align: center;
  }
  .avatar-image {
    object-fit: cover;
    width: 100px;
    height: 100px;
    display: block;
  }

  .image {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden; 
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
  }
  .dataCard{
    display: flex;
    justify-content: left;
  }

  .first_row{
    display: flex;
    justify-content: flex-end;
  }

  .chip1clr{
    background-color: #e4ffe5;
  }
  .mobile-number-item {
    padding: 0 !important;
  }

  .mobile-number-field {
    margin: 0 !important;
    width: 100%;
  }
  .custom-create-product-category {
    width: 400px;
    height: 400px;
    object-fit: contain;
    margin-left:auto;
    margin-right:auto;
  }
  .red-background-text {
    background-color: #f0bcbc;
    color: #BB0000;
  } 
  .gray-background-text {
    background-color: rgb(202, 202, 202);
    color: black;
  } 
  .viewbtn{
      font-size: 12px;
    }
    :deep(.dataTable) th, td{
      border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
      flex: 1;
      text-align: center;
    }

    :deep(.dataTable) td, td{
      border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
      flex: 1;
    }

    :deep(.dataTable) {
      border: white;
    }

    :deep(.dataTable )  tr,  th:last-child {
      border-right: none; /* Removes right border from the last cell in each row and header */
    }
    :deep(.dataTable )  tr,  td:last-child {
      border-right: none; /* Removes right border from the last cell in each row and header */
    }
</style>
    