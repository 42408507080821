<template>
    <v-card class="mt-3 card-size" flat>
      <v-row class="pl-3 pr-6">
        
        <v-col cols="6" class="mt-3">
          <h3 class="page-title">LOGS DATA</h3>
          <h5 class="light-gray-text">Analyse all logs data using a variety of parameters.</h5>
          <v-card-title>
            
          </v-card-title>
        </v-col>
  
        <v-col cols="6" class="px-4 mt-5">
          <v-row class="tool-bar-style">
            <v-spacer></v-spacer>
              <v-divider vertical :thickness="3" class="divider-vertical"></v-divider>
              <v-btn variant="text" size="x-large" class="gray-text" @click="backPageRoute()">
                <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
              </v-btn>
          </v-row>
        </v-col>
  
      </v-row>
      <v-card elevation="3" class="px-2 pt-2 mb-3 pb-2 mx-1 leads-details-card">
        <h5 class="card-h4-title px-3 mt-2 mb-4">LOGS DATA
            <div class="tool-bar-style">
            <v-spacer></v-spacer>
            <v-btn class="red-light-text mr-3" variant="outlined" @click="exportPDF">
                <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
            </v-btn>
            
            <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
                <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
            </v-btn>
            </div>
        </h5>

      <v-divider class="pb-6"></v-divider>

        <v-row >
            <v-col cols="4">
                
                <v-select
                    label="Management Area"
                    variant="outlined"
                    placeholder="Select area"
                    :items="managementAreaDashboards"
                    v-model="filters.managementArea"
                    multiple
                    chips
                    clearable
                    item-value="value"
                    item-title="text"
                ></v-select>


            </v-col>
            <v-col cols="4">
            <v-date-input
                v-model="filters.dates"
                color="red"
                label="Select Date or Range"
                max-width="368"
                multiple="range"
                variant="outlined"
                clearable
            ></v-date-input>
            </v-col>

            <v-col cols="4">
            <v-select
                label="Action"
                variant="outlined"
                placeholder="Select action"
                :items="filterActions"
                v-model="filters.action"
                multiple
                chips
                clearable
            >
            
            </v-select>
            </v-col>
        </v-row>

        <v-row >
            <v-col cols="4">
                <v-select
                    label="User"
                    variant="outlined"
                    placeholder="Select user"
                    :items="filterUsers"
                    v-model="filters.users"
                    multiple
                    chips
                    clearable
                >
                
                </v-select>
            </v-col>

            <v-col cols="4">
                <v-select
                    label="User Role"
                    variant="outlined"
                    placeholder="Select user role"
                    :items="userTypes"
                    v-model="filters.userType"
                    multiple
                    item-title="name"
                    item-value="name"
                    clearable
                ></v-select>
            </v-col>

            <v-col cols="4">
                <v-text-field
                    v-model="filters.search"
                    append-inner-icon="mdi-magnify"
                    variant="outlined"
                    placeholder="Search data"
                    label="Search"
                    hide-details
                    clearable
                    
                ></v-text-field>
            </v-col>
        </v-row>

        <v-col cols="12">
            <v-data-table
                :headers="headersUserLog"
                :items="filteredLogs"
                class="data-table elevation-1"
                :items-per-page="itemsPerPage"
                v-model:page="page"
                @page-count="totalPages = $event"
                @page="fetchUserLog"
                >

                <template v-slot:[`item.description`]="{ item }">
                    <span class="blue-text description-column">
                        {{ item.description }}
                    </span>
                </template>

            </v-data-table>
            
            <v-row>
                <v-col cols="12" class="text-right">
                    <v-pagination
                        v-model="page"
                        :length="totalPages"
                        @input="fetchUserLog"
                    ></v-pagination>    
                </v-col>
            </v-row>
        </v-col>

      </v-card>


      <v-snackbar
        v-model="snackAlert.snackbar"
        :color="snackAlert.snackColor"
        :top="snackAlert.snackBarTop"
        :right="snackAlert.snackBarRight"
        timeout="3000"
        >
        {{ snackAlert.snackMessage }}
        
        </v-snackbar>
    </v-card>
  </template>

<script>
import axios from 'axios';
// import _ from 'lodash';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
export default {
  components: {

  },
  name: 'UserLog',
    data() {
        return {
            snackAlert: {
                snackbar: false, // Snackbar visibility
                snackColor: "", // Snackbar color
                snackMessage: "", // Snackbar message
                snackBarRight: false, // Snackbar position right
                snackBarTop: true,  // Snackbar position top
            },
            filters: {
                managementArea: [],
                action: [],
                users: [],
                userType: [],
                dates: [],
                search: '',
            },
            managementAreaDashboards: [], 
            filterActions: ['create', 'update', 'delete', 'view'],

            // filterActions: [
            //     { text: 'Insert', value: 'create' },
            //     { text: 'Edit', value: 'update' },
            //     { text: 'Delete', value: 'delete' },
            //     { text: 'Read', value: 'view' }
            // ],
            
            filterUsers: [],
            userTypes: [],

            dateRange: '',

            // headersUserLog: [
            //     { text: 'DESCRIPTION', value: 'description', title: 'DESCRIPTION', align: 'start', sortable: false },
            //     { text: 'ACTION TIME & DATE', value: 'actionTimeDate', title: 'ACTION TIME & DATE' },
            //     { text: 'ACTION', value: 'action', title: 'ACTION' },
            //     { text: 'USER ID', value: 'userId', title: 'USER ID' },
            //     { text: 'USER NAME', value: 'userName', title: 'USER NAME' },
            //     { text: 'IP ADDRESS', value: 'ipAddress', title: 'IP ADDRESS' },
            //     { text: 'ROLE', value: 'role', title: 'ROLE' },
            // ],
            headersUserLog: [
                { text: 'DESCRIPTION', value: 'details', title: 'DESCRIPTION', align: 'start', sortable: false },
                { text: 'ACTION TIME & DATE', value: 'timestamp', title: 'ACTION TIME & DATE' },
                { text: 'ACTION', value: 'action', title: 'ACTION' },
                { text: 'USER ID', value: 'user_id', title: 'USER ID' },
                { text: 'USER NAME', value: 'user_details.name', title: 'USER NAME' },
                { text: 'IP ADDRESS', value: 'ipAddress', title: 'IP ADDRESS' }, 
                { text: 'ROLE', value: 'user_details.user_type', title: 'ROLE' }
            ],
            filteredLogs: [],
            // fetchDataintoTable: [
            //     {
            //     id: 1,
            //     description: 'Site [www.sfa.com] | user: Chamath, insert data to lead management successfully! #S00090',
            //     actionTimeDate: 'Jun 15, 2024 | 02.45 PM',
            //     action: 'Insert',
            //     userId: '002',
            //     userName: 'Chamath Ekanayake',
            //     ipAddress: '254.115.64.80',
            //     role: 'Administrator'
            //     },
            //     {
            //     id: 2,
            //     description: 'Site [www.sfa.com] | user: Lahiru, delete data in product management successfully! #S00032',
            //     actionTimeDate: 'Jun 12, 2024 | 08.14 AM',
            //     action: 'Delete',
            //     userId: '005',
            //     userName: 'Lahiru Gunawardhana',
            //     ipAddress: '214.115.14.22',
            //     role: 'Super Administrator'
            //     },
            //     {
            //     id: 3,
            //     description: 'Site [www.sfa.com] | user: Tharaka, Edit data in outlet management successfully! #S00027',
            //     actionTimeDate: 'Jun 08, 2024 | 03.25 PM',
            //     action: 'Edit',
            //     userId: '007',
            //     userName: 'Tharaka Perera',
            //     ipAddress: '159.5.108.192',
            //     role: 'Manager'
            //     },
            //     {
            //     id: 4,
            //     description: 'Site [www.sfa.com] | user: Sugath, Read data in user management. #S00235',
            //     actionTimeDate: 'May 30, 2024 | 06.23 PM',
            //     action: 'Read',
            //     userId: '010',
            //     userName: 'Sugath Bandara',
            //     ipAddress: '226.77.96.35',
            //     role: 'Area Manager'
            //     }
            // ],
            fetchDataintoTable: [], // This will hold the filtered data.
            // Pagination
            itemsPerPage: 10,
            page: 1,
            totalPages: 0,        }
    },
    // computed: {
    //     filteredLogs() {
    //         return this.fetchDataintoTable.filter(item => {
    //             const withinDateRange =
    //                 !this.filters.dates.length ||
    //                 (new Date(item.timestamp) >= new Date(this.filters.dates[0]) &&
    //                 new Date(item.timestamp) <= new Date(this.filters.dates[1]));

    //             const matchesSearch = !this.filters.search ||
    //                 item.details.toLowerCase().includes(this.filters.search.toLowerCase()) ||
    //                 item.user_details.name.toLowerCase().includes(this.filters.search.toLowerCase()) ||
    //                 item.user_details.user_type.toLowerCase().includes(this.filters.search.toLowerCase());

    //             const matchesArea = !this.filters.managementArea.length ||
    //                 this.filters.managementArea.includes(item.managementArea);

    //             const matchesAction = !this.filters.action.length ||
    //                 this.filters.action.includes(item.action);

    //             const matchesUser = !this.filters.users.length ||
    //                 this.filters.users.includes(item.user_id);

    //             const matchesUserType = !this.filters.userType.length ||
    //                 this.filters.userType.includes(item.user_details.user_type);

    //             return withinDateRange && matchesSearch && matchesArea && matchesAction && matchesUser && matchesUserType;
    //         });
    //     }
    // },

    // watch: {
    //     // 'filters.managementArea': function() {
    //     //     this.fetchUserLog(); // Fetch data whenever management area changes
    //     // },
    //     'filters.managementArea': {
    //         handler() {
    //             this.fetchUserLog(); // Fetch data whenever managementArea changes
    //         },
    //         immediate: true, // Trigger immediately if needed
    //     },
    //     'filters.dates': function() {
    //         this.updateDateRange();
    //     }
    // },

    watch: {
        'filters.managementArea': {
            handler() {
                this.fetchUserLog(); // Fetch data whenever managementArea changes
            },
            immediate: true,
        },
        'filters.dates': function() {
            this.updateDateRange();
        },
        'filters.action': function() {
            this.fetchUserLog(); // Fetch data whenever action changes
        },
        'filters.users': function() {
            this.fetchUserLog(); // Fetch data whenever users change
        },
        'filters.userType': function() {
            this.fetchUserLog(); // Fetch data whenever userType changes
        },
        'filters.search': function() {
            this.fetchUserLog(); // Fetch data whenever search term changes
        },
    },


    mounted() {
      this.getAllUserRoles();
      this.getDashboardPermissions();
      this.getUsers();
    //   this.fetchUserLog();
    },
    methods: {
        showSnackbar(message, color) {
            this.snackAlert.snackbar = true;
            this.snackAlert.snackMessage = message;
            this.snackAlert.snackColor = color;
        },
        backPageRoute(){
          this.$router.back();
        },

        updateDateRange() {
            if (this.filters.dates.length === 2) {
                this.dateRange = `${this.filters.dates[0]} - ${this.filters.dates[1]}`;
                this.fetchUserLog(); // Fetch filtered data
            }
        },


        getDashboardPermissions() {
            const userId = localStorage.getItem('userId');
            const token = localStorage.getItem('token');

            axios.post(`/api/user-log-table-names`, {userId}, {
                headers: {
                    Authorization: `Bearer ${token}`, // Include the Authorization header with the Bearer token
                }
            })
            .then(response => {
                const dashboards = response.data;
                console.log('Dashboards:', dashboards);
                
                // Map the response to format for v-select
                const dashboardMapping = {
                    "dashboard": "Dashboard",
                    "collection_user_logs": "Collection Management",
                    "lead_user_log": "Leads Management",
                    "outlet_user_log": "Outlet Management",
                    "product_user_log": "Product Management",
                    "route_user_log": "Route Management",
                    "sales_user_logs": "Sales Management",
                    "tracking_trip_user_log": "Tracking Management",
                    "user_registration_log": "User Management",
                    "user_role_user_log": "User Log"
                };

                this.managementAreaDashboards = dashboards.map(item => ({
                    text: dashboardMapping[item] || item, // Use the mapped value or fallback to the original
                    value: item
                }));
                
                console.log('Dashboard permissions:', this.managementAreaDashboards);
            })
            .catch(error => {
                console.error('Error fetching dashboard permissions:', error);
            });
        },



        getAllUserRoles() {
            const userId = localStorage.getItem('userId');
            const token = localStorage.getItem('token'); 

            axios.post('/api/getUserRolesType', {
                userId: userId
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                let usrRole = response.data.data;
                this.userTypes = usrRole.map(item => {
                    return {
                        id: item.id,
                        name: item.role_type
                    };
                });
                console.log('User role types:', this.userTypes);
            })
            .catch(error => {
                console.error('Error fetching user roles:', error);
            });
        },

        getUsers() {
            const userId = localStorage.getItem('userId');
            const token = localStorage.getItem("token");

            const formData = new FormData();
            formData.append("userId", userId);

            axios.post("/api/createdUserList", formData, {
                headers: {
                'Authorization': `Bearer ${token}`
                }
            }).then((response) => {
                // Format users as 'id_name'
                this.filterUsers = response.data.data.map(user => {
                return `${user.id}_${user.name}`;
                });
                console.log('formatted users', this.filterUsers);
            }).catch((error) => {
                console.log(error);
            });
        },

        // fetchUserLog() {
        //     const userId = localStorage.getItem('userId');
        //     const token = localStorage.getItem("token");

        //     const selectedArea = this.filters.managementArea.length > 0 ? this.filters.managementArea[0] : null;

        //     if (!selectedArea) {
        //         this.filteredLogs = []; // Clear data if no area is selected
        //         return;
        //     }

        //     const formData = new FormData();
        //     formData.append("userId", userId);

        //     axios.post(`/api/user-logs/${selectedArea}`, formData, {
        //         headers: {
        //         'Authorization': `Bearer ${token}`
        //         }
        //     })
        //     .then(response => {
        //         this.filteredLogs = response.data; // Update the table data
        //         console.log('Result', response.data);
        //     })
        //     .catch(error => {
        //         console.error('Error fetching user logs:', error);
        //     });
        // },

        fetchUserLog() {
            const userId = localStorage.getItem('userId');
            const token = localStorage.getItem("token");

            const selectedArea = this.filters.managementArea.length > 0 ? this.filters.managementArea[0] : null;

            if (!selectedArea) {
                this.filteredLogs  = []; // Clear data if no area is selected
                return;
            }

            const formData = new FormData();
            formData.append("userId", userId);
            // formData.append("action", this.filters.action.join(',')); // Join actions as comma-separated string
            // formData.append("users", this.filters.users.join(',')); // Join users as comma-separated string
            // formData.append("userType", this.filters.userType.join(',')); // Join user types as comma-separated string
            // formData.append("search", this.filters.search);
            if (this.filters.action.length > 0) {
                formData.append("action", this.filters.action.join(','));
            }
            if (this.filters.users.length > 0) {
                formData.append("users", this.filters.users.join(','));
            }
            if (this.filters.userType.length > 0) {
                formData.append("userType", this.filters.userType.join(','));
            }
            if (this.filters.search.trim() !== '') {
                formData.append("search", this.filters.search.trim());
            }
            if (this.filters.dates.length === 2) {
                formData.append("startDate", this.filters.dates[0]);
                formData.append("endDate", this.filters.dates[1]);
            }

            console.log('Action Filter:', this.filters.action);
console.log('Users Filter:', this.filters.users);
console.log('User Type Filter:', this.filters.userType);
console.log('Search Filter:', this.filters.search);
console.log('Dates Filter:', this.filters.dates);

            // Log the form data to ensure correct values
            console.log('FormData:', Array.from(formData.entries()));

            if (this.filters.dates.length === 2) {
                formData.append("startDate", this.filters.dates[0]);
                formData.append("endDate", this.filters.dates[1]);
            }

            axios.post(`/api/user-logs/${selectedArea}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                // Save the logs data into a reactive property
                this.filteredLogs = response.data; // Save the response to be filtered
                console.log('Result', response.data);
                
            })
            .catch(error => {
                console.error('Error fetching user logs:', error);
            });
        },
        
        exportExcel() {
            // const tableData = this.fetchDataintoTable.map((item, index) => ({
            // '#': index + 1,
            // 'DESCRIPTION': item.description,
            // 'ACTION TIME & DATE': item.actionTimeDate,
            // 'ACTION': item.action,
            // 'USER ID': item.userId,
            // 'USER NAME': item.userName,
            // 'IP ADDRESS': item.ipAddress,
            // 'ROLE': item.role,
            // }));
            const tableData = this.filteredLogs.map((item, index) => ({
                '#': index + 1,
                'DESCRIPTION': item.description,
                'ACTION TIME & DATE': item.timestamp, // Adjust based on your data
                'ACTION': item.action,
                'USER ID': item.user_id,
                // 'USER NAME': item.user_details.name,
                'IP ADDRESS': item.ipAddress,
                // 'ROLE': item.user_details.user_type
            }));

            const worksheet = XLSX.utils.json_to_sheet(tableData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'UserLog');


            XLSX.writeFile(workbook, 'user_log.xlsx');
        },

        exportPDF() {
            const doc = new jsPDF();

            // Set the title for the document
            doc.text('User Log', 14, 10);

            // Prepare the data for the table
            // const tableData = this.fetchDataintoTable.map((item, index) => [
            // index + 1,
            // item.description,
            // item.actionTimeDate,
            // item.action,
            // item.userId,
            // item.userName,
            // item.ipAddress,
            // item.role,
            // ]);
            const tableData = this.filteredLogs.map((item, index) => [
                index + 1,
                item.description,
                item.timestamp, 
                item.action,
                item.user_id,
                // item.user_details.name,
                item.ipAddress,
                // item.user_details.user_type
            ]);

            // Define the columns for the table
            // const tableColumns = ['#', 'DESCRIPTION', 'ACTION TIME & DATE', 'ACTION', 'USER ID', 'USER NAME', 'IP ADDRESS', 'ROLE'];
            const tableColumns = ['#', 'DESCRIPTION', 'ACTION TIME & DATE', 'ACTION', 'USER ID', 'IP ADDRESS'];
            // AutoTable plugin to create a table
            doc.autoTable({
            head: [tableColumns],
            body: tableData,
            });

            // Save the PDF with a name
            doc.save('user_log.pdf');

        },
    }
};
</script>

<style scoped>
.blue-text {
    color: blue !important;
}
.card-size{
    width: 100% !important;
    padding: 0;
    margin-bottom: 0;
}
.page-title {
    font-weight: bold;
}
.light-gray-text{
    color: #a09e9e !important;
}
.tool-bar-style {
    display: flex;
    justify-content: end;
}
.divider-vertical{
    color: #3c3b3b !important;
}
.gray-text {
    color: #757575 !important;
}
.leads-details-card{
    height: 100%;
    overflow: auto;
    margin-bottom: 20px;
}
.card-h4-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    color: black;
}
.red-light-text {
    background-color: #f1cccc;
    color: #b00d0d;
}
.red-white-text {
    background-color: #b00d0d;
    color: white;
}
.green-light-text {
    background-color: #aee8b1;
    color: #057b0a;
}
.green-text {
    color: #057b0a;
    background-color: #aee8b1;
}
.data-table {
    width: 100% !important;
    border: black;
}
.description-column {
    max-width: 210px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* Allows text to wrap to the next line */
    word-wrap: break-word; /* Breaks long words to wrap them to the next line */
}
</style>