<template>
  <v-card class="mt-3 card-size" flat>
    <v-row class="pl-2 pr-6">
      <v-col class="mt-3" cols="6">
        <h3 class="page-title">ADD LEADS</h3>
        <h5 class="light-gray-text">Add leads according to your requirements.</h5>
        <v-card-title>
          
        </v-card-title>
      </v-col>
      <v-col class="px-4 mt-5" cols="6">
        <v-row class="tool-bar-style">
            <v-btn class="red-text" size="x-large" variant="text" @click="leadsBulkUploadDialog = true">
              <v-icon>mdi-file-document</v-icon>&nbsp;&nbsp;LEADS BULK UPLOAD
            </v-btn>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card class="px-2 pt-2 mx-1 mb-3 leads-details-card" elevation="3">
      <h5 class="card-two-title px-3 mt-2 mb-4">ADD LEADS DETAILS</h5>
      <v-divider></v-divider>

      <v-row class="pt-6">
        <v-col class="px-6" cols="6">
          <v-card class="pt-2 card-hight-fixed" flat>
            <v-col cols="11">
            <v-text-field
              v-model="lead.title"
              class="mb-2"
              label="Lead Title *"
              placeholder="Type title here"
              variant="outlined"
            ></v-text-field>

            <v-select
              v-model="lead.mainCategory"
              :items="categories"
              :loading="mainFilterLoading"
              class="mb-2"
              item-text="main_filter"
              item-value="id"
              label="Main Category *"
              placeholder="Select main category"
              variant="outlined"
              @update:modelValue="fetchSubCategories(lead.mainCategory)"
            ></v-select>

            <v-select
                v-model="lead.subCategory"
                :items="subCategories"
                :loading="subFilterLoading"
                :v-if="isDisabledProvinceList"
                class="mb-2"
                item-text="sub_filter"
                item-value="id"
                label="Sub Category *"
                placeholder="Select sub category"
                variant="outlined"
            ></v-select>

            <v-select
              v-model="lead.province"
              :items="provinces"
              :loading="mDistrictLoading"
              class="mb-2"
              item-text="label"
              item-value="value"
              label="Province"
              placeholder="Select province"
              variant="outlined"
              @update:modelValue="getAllDistrictAccProv"
            ></v-select>

            <v-select
              v-model="lead.district"
              :disabled="isDisabledDistrictList"
              :items="districts"
              :loading="mDistrictLoading"
              class="mb-2"
              item-text="label"
              item-value="value"
              label="District"
              placeholder="Select district"
              variant="outlined"
              @update:modelValue="getAllDsd"
            ></v-select>

            <v-textarea
                v-model="lead.description"
                class="mb-2"
                label="Lead Description"
                placeholder="Write a description here..."
                variant="outlined"

            ></v-textarea>
          </v-col>
          </v-card>
        </v-col>
        <v-col class="px-6" cols="6">
          <v-card class="pt-2 card-hight-fixed" flat>
            <v-col cols="11">
            <v-select
              v-model="lead.dsd"
              :disabled="isDisabledDSDLIST"
              :items="dsdList"
              :loading="mDSDLoading"
              class="mb-2"
              item-text="label"
              item-value="value"
              label="Divisional"
              placeholder="Select divisional"
              variant="outlined"
              @update:modelValue="getAllGnd"
            ></v-select>

            <v-select
              v-model="lead.gnd"
              :disabled="isDisabledGNDList"
              :items="gndList"
              :loading="mDSDLoading"
              class="mb-2"
              label="GND"
              variant="outlined"
              @update:modelValue="loadLeadsList(lead.mainCategory, lead.subCategory)"
              placeholder="Select GND"

            ></v-select>

            <v-select
              v-model="lead.assignAgent"
              :items="agents"
              class="mb-2"
              item-title="name"
              item-value="name"
              label="Assign Agent *"
              placeholder="Select agent"
              variant="outlined"
            ></v-select>

            <v-date-input
                v-model="lead.assignDate"
                color="red"
                label="Assign Date"
                max-width="580"
                persistent-placeholder
                placeholder="Select a Date"
                variant="outlined"
            ></v-date-input>

            <v-divider></v-divider>
            <h5 class="py-3">SELECTED LEADS LIST</h5>
            <h6 class="pb-3 light-gray-text">The following table shows all the leads related to your leads category & you can add only the leads you like.</h6>
            <v-card class="my-3">
              <v-toolbar color="white">

                <v-toolbar-title>
                  <v-switch v-model="selectAll" label="All" @change="toggleSelectAll">
                  </v-switch>                
                </v-toolbar-title>

                <v-divider vertical></v-divider>

                <v-spacer></v-spacer>

                <v-col cols="7">
                  <v-text-field

                      v-model="search"
                      append-inner-icon="mdi-magnify"
                      variant="outlined"
                      placeholder="Search Lead"
                      label="Search"
                      hide-details
                      clearable
                  ></v-text-field>
                </v-col>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card class="ma-3">
                  <v-data-table
                    :headers="headers"
                    :items="filteredItems"
                    :items-per-page="itemsPerPage"
                    :search="search"
                    class="data-table elevation-1"
                  >
                    <template v-slot:[`item.data-table-select`]="{ item }">
                      <v-switch v-model="item.selected" class="mt-0" hide-details></v-switch>
                    </template>
                    <template v-slot:[`item.subCategory`]="{ item }">
                      <span class="red-text">{{ item.subCategory }}</span>
                    </template>
                  </v-data-table>

                </v-card>
            </v-card>
          </v-col>
          </v-card>
        </v-col>
      </v-row>
      
      <v-divider class="divider-two-style"></v-divider>
      <v-row class="margin-bottomn-fix pa-3 mb-2">
        <v-spacer></v-spacer>
        <v-btn class="red-background-text mr-3" variant="outlined" size="large" @click="addLead">ADD LEAD</v-btn>
        <v-btn class="gray-background-text" variant="outlined" size="large" @click="backPageRoute()">CANCEL</v-btn>
      </v-row>       
    </v-card>
    <!-- View bulk upload Dialog -->
    <v-dialog v-model="leadsBulkUploadDialog" fullscreen>
      <v-card class="card-size">

        <v-toolbar dense color="white" class="pr-5 mb-1 mt-1">
          <v-toolbar-title>
              <img @click="homeReload()" :src="require('@/assets/app_bar_logo.png')"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" variant="elevated" dark @click="leadsBulkUploadDialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        
        <v-divider></v-divider>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-card class="pa-3 mx-6">
            <v-icon>mdi-view-dashboard
            </v-icon>
            <v-label class="ml-6">UPLOAD SPREDSHEET</v-label>
            </v-card>
            <v-card class="px-3 mx-6 mt-3">
              <v-row class="ma-0">
                <v-col cols="5">
                  <h5 class="px-3">Upload your lead bulk data set using excel or a csv file.</h5>
                  <v-col cols="12">

                    <!-- <v-file-input
                      label="Upload your Excel or CSV file"
                      accept=".csv, .xls, .xlsx"
                      @update:modelValue="handleFileUpload"
                      variant="outlined"
                      show-size
                      v-model="excelFiles"
                      :show-size="1000"
                    ></v-file-input> -->
                    <v-file-input 
                    accept=".csv, .xls, .xlsx"
                              show-size counter clearable
                              color="#086EFF"  variant="outlined"
                              @change="handleFileUpload"
                >
                    
                </v-file-input>

                  </v-col>
                </v-col>
                <v-col class="d-flex align-center justify-center" cols="1">
                  <v-divider :thickness="2" vertical></v-divider>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="3">

                    </v-col>
                    <v-col cols="9">
                      <v-card class="d-flex align-center justify-space-between pa-4 mt-3" color="grey-lighten-3" flat width="100%">
                        <div class="mr-4">
                          <h4 class="mb-0">DOWNLOAD SAMPLE TEMPLATE</h4>
                        </div>
                        <v-btn class="red-background-text btn-border" size="x-large" variant="text" @click="downloadTemplate">
                          DOWNLOAD
                        </v-btn>
                      </v-card>
                    </v-col>
                  </v-row>                  
                </v-col>
              </v-row>
              <v-divider></v-divider>

              <v-card v-if="bulkItems.length > 0" flat>
              <v-data-table
                :headers="bulkHeaders"
                :items="bulkItems"
                :items-per-page="itemsPerPage"
                class="elevation-1"
              >
                <template v-slot:[`item.subCategory`]="{ item }">
                  <span class="red--text">{{ item.subCategory }}</span>
                </template>
                <template v-slot:[`item.assignAgent`]="{ item }">
                  <span>{{ item.assignAgent }}<br/><span class="red--text">{{ item.regNum }}</span></span>
                </template>
              </v-data-table>
            </v-card>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="bulkItems.length === 0" class="red-background-text btn-border mx-3" size="large" variant="text" @click="uploadBulkLead">Upload</v-btn>
            </v-card-actions>

            </v-card>
          </v-col>
        </v-row>      
      </v-card>
    </v-dialog>
    <!-- Snackbar to show notifications -->
   <v-snackbar
      v-model="snackAlert.snackbar"
      :color="snackAlert.snackColor"
      :right="snackAlert.snackBarRight"
      :top="snackAlert.snackBarTop"
      timeout="3000"
    >
      {{ snackAlert.snackMessage }}
      
    </v-snackbar>
  </v-card>
</template>
    
<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import _ from 'lodash';

export default {
  name: 'AddLeads',
  data() {
    return {
      // Data properties for the form
      lead: {
        title: '',
        mainCategory: '',
        subCategory: '',
        province: '',
        district: '',
        description: '',
        dsd: '',
        gnd: '',
        assignAgent: '',
        assignDate: null,
      },
      excelFiles: null,

      selectedCategory: null,
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },

      selectAll: false,
      // categories: ['Category1', 'Category2'], // Example categories
      // subCategories: ['SubCategory1', 'SubCategory2'], // Example sub-categories
      categories: [], 
      subCategories: [],
      allCategories:[],

      isDisabledProvinceList:false,
      isDisabledDistrictList:false,
      isDisabledDSDLIST:false,
      isDisabledGNDList:false,
      
      selectedDistrict:[],
      selectedProvince:"",
      selectedDSD:[],
      selectedGND:[],

      agents: [], 

      // file: null,
      // outletBulkUploadDialog: false,
      bulkFile: null,
      bulkItems: [],
      bulkHeaders: [
        { text: 'Lead Title', value: 'Lead Title', title: 'Lead Title' },
        { text: 'Main Category', value: 'Main Category' , title: 'Main Category' },
        { text: 'Sub Category', value: 'Sub Category', title: 'Sub Category' },
        { text: 'District', value: 'District', title: 'District' },
        { text: 'Lead Description', value: 'Lead Description', title: 'Lead Description' },
        { text: 'DSD', value: 'DSD', title: 'DSD' },
        { text: 'GND', value: 'GND', title: 'GND' },
        { text: 'Assign Agent', value: 'Assign Agent', title: 'Assign Agent' },
        { text: 'Assign Date', value: 'Assign Date', title: 'Assign Date' },
        { text: 'Sub Categorys', value: 'Sub Categorys', title: 'Sub Categorys' },
        { text: 'Institute Name', value: 'Institute Names', title: 'Institute Name' },
        { text: 'Address', value: 'Addresses', title: 'Address' },
        { text: 'Latitudes', value: 'Latitudes', title: 'Latitudes' },
        { text: 'Longitudes', value: 'Longitudes', title: 'Longitudes' },
      ],

      // add leads
      headers: [
        { text: '', value: 'data-table-select', title: 'data-table-select' },
        { text: 'Sub Category', value: 'subCategory', sortable: true, title: 'Sub Category' },
        { text: 'Institute Name', value: 'instituteName', sortable: true, title: 'Institute Name' },
        { text: 'Address', value: 'address', sortable: true, title: 'Address' },
      ],
      // items: [],
      items: [],

      
      
    mainFilterLoading: false,
    subFilterLoading: false,

    mDistrictLoading: false,
    mDSDLoading: false,
    mGNDLoading: false,
    provinces: [],
    districts: [],
    dsdList: [],
    gndList: [],

      // Pagination
      itemsPerPage: 10,
      page: 1,
      totalPages: 0,

      //search
      search: '',

      // Dialog state
      leadsBulkUploadDialog: false,

      //select date
      assignDateMenu: false,
      // filteredItems: [],
    };
  },
  computed: {
    // Filtering items based on search input
    filteredItems() {
      return this.items.filter(item => {
        return (
          item.subCategory.toLowerCase().includes(this.search.toLowerCase()) ||
          item.instituteName.toLowerCase().includes(this.search.toLowerCase()) ||
          item.address.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    }
  },
  watch: {
    'lead.mainCategory': function(newValue) {
      // Example of using newValue
      console.log('Main Category changed to:', newValue);
      this.fetchLeads();
    },
    'lead.subCategory': function(newValue) {
      console.log('Sub Category changed to:', newValue);
      this.fetchLeads();
    },
    'lead.province': function(newValue) {
      console.log('Province changed to:', newValue);
      this.fetchLeads();
    },
    'lead.district': function(newValue) {
      console.log('District changed to:', newValue);
      this.fetchLeads();
    },
    'lead.dsd': function(newValue) {
      console.log('DSD changed to:', newValue);
      this.fetchLeads();
    },
    'lead.gnd': function(newValue) {
      console.log('GND changed to:', newValue);
      this.fetchLeads();
    }
  },


  mounted() {
    this.fetchMainCategories();
    this.getAllProvinces();
    this.fetchAllAgents();
    // this.loadLeadsList('All','province',null);
  },

  methods: {
    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    toggleSelectAll() {
      this.filteredItems.forEach(item => {
        item.selected = this.selectAll;
      });
    },

    // Call this method whenever mainCategory, subCategory, or location fields change
    async fetchLeads() {
      await this.loadLeadsList(this.lead.mainCategory, this.lead.subCategory);
    },

    async loadLeadsList(mainCategory, subCategory) {
      try {
        // Determine the location_type and location values based on selected values
        let locationType = '';
        let locationValue = [];

        if (this.lead.gnd && this.lead.gnd.length > 0) {
          locationType = 'gnd';
          locationValue = [this.lead.gnd];
        } else if (this.lead.dsd && this.lead.dsd.length > 0) {
          locationType = 'dsd';
          locationValue = [this.lead.dsd];
        } else if (this.lead.district && this.lead.district.length > 0) {
          locationType = 'district';
          locationValue = [this.lead.district];
        } else if (this.lead.province && this.lead.province !== "") {
          locationType = 'province';
          locationValue = [this.lead.province];
        } 
        // Construct the payload
        const payload = {
          type: 'Spatial',
          country: 'SriLanka',
          district: locationType === 'district' ? locationValue : [],
          province: locationType === 'province' ? locationValue : [],
          dsd: locationType === 'dsd' ? locationValue : [],
          gnd: locationType === 'gnd' ? locationValue : [],
          location_type: locationType,
          main_filter: mainCategory,
          sub_filter: subCategory
        };
        

        // Make the API call
        const response = await axios.post('https://geobizztech.celata.org/geoapi/gis/getGISData', payload);

        console.log('table data for leads:', payload);
        if (response.data.http_status === 'success') {
          console.log('table data for leads two:', payload);
          // Map the response data to your table structure
          // this.filteredItems = response.data.data.map(item => ({
          //   subCategory: item.Type,
          //   instituteName: item.Name,
          //   address: `${item.GND_N}, ${item.DSD_N}, ${item.DISTRICT_N}, ${item.PROVINCE_N}`
          // }));

          this.items = response.data.data.map(item => ({
            subCategory: item.Type,
            instituteName: item.Name,
            address: `${item.GND_N}, ${item.DSD_N}, ${item.DISTRICT_N}, ${item.PROVINCE_N}`,
            selected: false // Add a default value for selection
          }));
        } else {
          console.error("Failed to load leads:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    },


    fetchAllAgents() {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token'); 

        axios.post('/api/agentUser', {
            userId: userId
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'content-type': 'multipart/form-data',
            }
        })
        .then(response => {
            let sample = response.data.data;
            this.agents = sample.map(item => {
                return {
                    id: item.id,
                    name: item.name
                };
            });
            console.log('Agents:', this.agents);
        })
        .catch(error => {
            console.error('Error fetching Agents:', error);
        });
    },

    getAllProvinces() {
        this.districts = [];
        this.dsdList = [];
        this.gndList = [];
        this.mDistrictLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const header = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
            .then(response => {
              if (response.data.http_status === 'success') {
                this.provinces = response.data.data;
                this.mDistrictLoading = false;
              }
            })
            .catch(() => {
              this.mDistrictLoading = false;
            });
      },

      getAllDistrictAccProv(province) {
        console.log('Province selected:', province);
        this.districts = [];
        this.mDistrictLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const headers = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
            .then(response => {
              console.log('District API response:', response.data);
              if (response.data.http_status === 'success') {
                this.districts = response.data.data;
                console.log('District API response:', response.data);
                this.mDistrictLoading = false;
                this.loadLeadsList(this.lead.mainCategory, this.lead.subCategory);
                console.log('main c:', this.lead.mainCategory);
                console.log('sub c:', this.lead.subCategory);
              }
            })
            .catch(error => {
              this.mDistrictLoading = false;
              console.error('Error fetching districts:', error);

            });
      },

      getAllDsd(district) {
        this.dsdList = [];
        this.mDSDLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const header = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
            .then(response => {
              if (response.data.http_status === 'success') {
                this.dsdList = response.data.data;
                this.mDSDLoading = false;
                this.loadLeadsList(this.lead.mainCategory, this.lead.subCategory);
                console.log('main c:', this.lead.mainCategory);
                console.log('sub c:', this.lead.subCategory);
              }
            })
            .catch(() => {
              this.mDSDLoading = false;
            });
      },

      getAllGnd(dsd) {
        this.gndList = [];
        this.mGNDLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const header = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
            .then(response => {
              if (response.data.http_status === 'success') {
                this.gndList = response.data.data;
                this.mGNDLoading = false;
                this.loadLeadsList(this.lead.mainCategory, this.lead.subCategory);
                console.log('main c:', this.lead.mainCategory);
                console.log('sub c:', this.lead.subCategory);
              }
            })
            .catch(() => {
              this.mGNDLoading = false;
            });
      },

    async fetchMainCategories() {
      try {
        this.mainFilterLoading = true;
        const response = await axios.post('https://geobizztech.celata.org/geoapi/gis/getCategories', {
          org_code: 'SFA_Backend',
          category: 'Community data',
          type: 'Spatial',
          country: 'SriLanka',
          user: ''
        });

        // Log the entire response object
        console.log('API Response for Main Categories:', response);
        console.log('API Response for Main Categories:', response.data);
        console.log('Categories Dataset:', response.data.dataset);

        if (response.data && response.data.dataset) {
          this.allCategories = response.data.dataset;
          this.mainGroup = _.groupBy(this.allCategories, 'main_filter');

          this.categories = Object.keys(this.mainGroup);
          this.mainFilterLoading = false;

          this.geoJsonLayers = this.allCategories
            .filter(val => val.spatial_type !== 'Point')
            .map(val => ({
              data: null,
              name: val.sub_filter,
              main_type: val.main_filter,
              sub_directory: val.data_model
            }));

          // Log the mapped categories
          console.log('Mapped Categories:', this.categories);

        } else {
          throw new Error('Invalid response structure');
        }
      } catch (error) {
        console.error('Error fetching main categories:', error);
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = 'red';
        this.snackAlert.snackMessage = 'Failed to load main categories!';
      } finally {
        this.mainFilterLoading = false;
      }
    },

    fetchSubCategories(dataset){
      // if (filter === 'main'){
          this.isDisabledDistrictList = false;
          this.isDisabledDSDLIST = false;
          this.isDisabledGNDList = false;
          this.lead.province = null;
          this.lead.district = [];
          this.lead.dsd = [];
          this.lead.gnd = [];
          this.lead.subCategory="";

          // Debugging: Check the input parameters
          // console.log('Filter:', filter);
          console.log('Dataset:', dataset);

          // Ensure that `this.allCategories` is correctly populated
          console.log('All Categories:', this.allCategories);
          
          let sub_filter = _.map(this.allCategories,function (o) {
              if (o.main_filter === dataset )
                  return o.sub_filter
          });

          sub_filter = _.reject(sub_filter, _.isNil);
          this.subCategories = sub_filter.sort();

          // Debugging: Verify the final result
           console.log('Sub Categories:', this.subCategories);
      
    },

   
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.bulkFile = file; // Assign the selected file to bulkFile

      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assuming the data is in the first sheet
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Convert the sheet to JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headers = jsonData[0].map(header => header ? header.toString().trim() : '');
        const dataRows = jsonData.slice(1).map(row => {
          return headers.reduce((obj, header, index) => {
            const value = row[index];
            obj[header] = value !== undefined && value !== null ? value.toString().trim() : '';
            return obj;
          }, {});
        });

        this.bulkItems = dataRows;
      };

      reader.readAsArrayBuffer(file);
    },

    async uploadBulkLead() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      if (!this.bulkFile) {
        console.error('No file selected for upload');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.bulkFile);
      formData.append('userId', userId); // Append userId if needed by the backend

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/leads/bulk-upload`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            // 'Content-Type': 'multipart/form-data' // No need to set this, it will be set automatically
          },
        });
        console.log('Bulk upload response:', response.data);
        this.leadsBulkUploadDialog = false;
      } catch (error) {
        console.error('Error uploading bulk outlets:', error);
      }
    },

    async downloadTemplate() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const url = `${process.env.VUE_APP_API_BASE_URL}/api/leads/download-template?userId=${userId}`;


      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error('Failed to download template');
        }

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'template_leads_data.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
        this.showSnackbar("Template download successfully!", "success");
      } catch (error) {
        console.error('Error downloading template:', error);
      }
    },
   
    uploadData() {
      // Your API call logic
      axios.post('/your-api-endpoint', this.items)
        .then(response => {
          console.log('Data uploaded successfully:', response.data);
        })
        .catch(error => {
          console.error('Error uploading data:', error);
        });
    },
    backPageRoute(){
      this.$router.back();
    },
    homeReload(){
      window.location.href = '/dashboard';
    },
    // Added addLead method
    addLead() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      // const selectedLeads = this.filteredItems.filter(item => item.selected);
      // const selectedLeadsJSON = JSON.stringify(selectedLeads);

      // Gather selected leads and map to the required format
      const selectedLeads = this.items
        .filter(item => item.selected)
        .map((item, index) => ({
          id: index + 1, // Assigning a unique ID (you may adjust this as needed)
          instiName: item.instituteName,
          address: item.address
        }));

      // Convert the mapped leads to a JSON string

      const selectedLeadsJSON = JSON.stringify(selectedLeads);

      const leadData = {
        leadsTitle: this.lead.title,
        mainCatagory: this.lead.mainCategory,
        subCatagory: this.lead.subCategory,
        province: this.lead.province,
        district: this.lead.district,
        // dsd: this.lead.dsd,
        // gnd: this.lead.gnd,
        dsd: Array.isArray(this.lead.dsd) && this.lead.dsd.length > 0 ? this.lead.dsd.join(',') : '', // Ensure it is a string or empty
        gnd: Array.isArray(this.lead.gnd) && this.lead.gnd.length > 0 ? this.lead.gnd.join(',') : '', // Ensure it is a string or empty
        assignAgent: this.lead.assignAgent,
        assignDate: new Date(this.lead.assignDate).toISOString().split('T')[0],
        // leadsLists: //here json stringfy the lead list
        leadsLists: selectedLeadsJSON ,
        description: this.lead.description,
        userId,
      };

      axios.post('/api/addLeads', leadData, {
        // headers: {
        //   'Authorization': `Bearer ${token}`,
        //   'content-type': 'multipart/form-data',
        // },
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
        .then(response => {
          console.log('Lead added successfully:', response.data);
          this.showSnackbar("Lead added successfully!", "success");
          // Handle successful addition (e.g., clear form, show success message)
        })
        .catch(error => {
          console.error('Error adding lead:', error);
          this.showSnackbar("Error!", "error");
          // Handle error (e.g., show error message)
        });
    },
  }
};
</script>

<style scoped>
  .page-title {
    font-weight: bold;
  }

  .light-gray-text{
    color: #a09e9e !important;
  }

  .red-text {
    color: #b00d0d;
  }
  
  .gray-text {
    color: #757575 !important;
  }

  .btn-border{
    border-block-end-color: #9a1313;
  }

  .card-size{
    width: 100%;
    padding: 0;
    margin-bottom: 0;
  }

  .tool-bar-style {
    display: flex;
    justify-content: end;
  }

  .divider-vertical{
    color: #3c3b3b !important;
  }

  .card-two-title{
    color: black;
  }
  .leads-details-card{
    height: 100%;
  }
  .card-hight-fixed{
    height: 100%;
    overflow: auto;
    max-height: 63vh;
  }
  .data-table {
    width: 100% !important;
    border: black;
  }

  
  .red-background-text {
    background-color: #f0bcbc;
    color: #BB0000;
  } 
  .gray-background-text {
    background-color: rgb(202, 202, 202);
    color: black;
  } 
  .divider-two-style{
    margin-top: -5px;
  }
</style>
    