<template>
  <v-card class="mt-3 card-size" flat>
    <v-row class="pl-2 pr-6">
      <v-col cols="7" class="mt-3">
        <h3 class="page-title">PRODUCTS</h3>
        <h5 class="light-gray-text">Analyse orders placed in your store</h5>
        <v-card-title>
          
        </v-card-title>
      </v-col>
      <v-col cols="5" class="px-4 mt-5">
        <v-row class="tool-bar-style">
            <v-btn size="x-large" class="red-text btn-border" variant="text" @click="addProductsPageRoute()">
              <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;ADD PRODUCTS
            </v-btn>
            <v-divider vertical :thickness="3" class="divider-vertical"></v-divider>
            <v-btn variant="text" size="x-large" class="gray-text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card elevation="3" class="px-2 pt-2 mx-1 mb-4 product-details-card">
      <v-row class="card-two-title px-6 mt-2 mb-4">
        <v-spacer></v-spacer>
        <v-btn class="red-light-text" variant="outlined"  @click="exportPDF">
          <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
        </v-btn>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
          <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
        </v-btn>
      </v-row>
      <v-divider></v-divider>
      <!-- <v-row class="px-3 pt-6">
        <v-col cols="4">
          <v-text-field
              v-model="search"
              append-inner-icon="mdi-magnify"
              variant="outlined"
              placeholder="Search Products"
              label="Search"
              hide-details
              clearable
              @input="fetchProducts"
              
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
            label="Main Category"
            variant="outlined"
            placeholder="Select main category"
            :items="mainCategories"
            v-model="product.main_category"
            @update:modelValue="fetchSubCategories()"
            @change="fetchProducts"
            item-title="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
              label="Sub Category"
              variant="outlined"
              placeholder="Select sub category"
              :items="subCategories"
              v-model="product.sub_category"
              @change="fetchProducts"
              item-title="sub_category_name"
              item-value="sub_category_name"
          ></v-select>
         
        </v-col>
      </v-row> -->
      <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            class="data-table dataTable"
            :items-per-page="itemsPerPage"
            v-model:page="page"
            @page-count="totalPages = $event"
            @page="fetchProducts"
          >
            <template v-slot:[`item.data-table-select`]="{ item }">
              <v-switch v-model="item.selected" class="mt-0" hide-details></v-switch>
            </template>

            <template v-slot:[`item.product`]="{ item }">
              <div class="product-column">
                <v-row>
                  <v-col cols="3" v-for="(image, index) in product.product_image" :key="index">
                    <!-- <v-img :src="require(`@/assets/topSellingProducts/${item.image}`)" class="product-image"></v-img> -->
                    <!-- <v-img :src="item.image" class="product-image"></v-img> -->
                    <!-- <v-img 
                      v-if="item.product_image.length > 0" 
                      :src="`/${item.product_image[0].image_url}`" 
                      class="product-image"
                    /> -->
                          <v-img
                            :src="image.image_url"
                            class="thumbnail-image"
                            aspect-ratio="1"
                            @click="selectImage(image.image_url)"
                          ></v-img>

                  </v-col>
                  <v-col cols="9" class="d-flex align-center">
                    <span class="product-title">{{ item.name }}</span>
                  </v-col>
                </v-row>
              </div>
            </template>
            
            <template v-slot:[`item.main_category`]="{ item }">
              <span>{{ getCategoryName(item.main_category) }}</span>
            </template>


            <template v-slot:[`item.action`]="{ item }" >
              <v-row>
                  <v-btn
                      
                      class="mr-3 bg-blue-lighten-5 viewbtn"
                      variant="outlined"
                      color="blue"
                      @click="viewItem(item)"
                  >
                    <v-icon>mdi-check-circle</v-icon>&nbsp;  view
                  </v-btn>
                  <v-btn
                      class="mr-3 bg-green-lighten-5 "
                      rounded
                      variant="outlined"
                      size="small"
                      color="green"
                      icon
                      @click="editItem(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                      class="bg-red-lighten-5"
                      rounded
                      variant="outlined"
                      size="small"
                      color="red"
                      icon
                      @click="confirmDelete(item)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
              </v-row>
            </template>

            <template v-slot:top>
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="selectedMainCategory"
                    label="Main Category"
                    variant="outlined"
                    item-title="name"
                    item-value="id"
                    :items="mainCategories"
                    @update:modelValue="fetchSubCategories()"
                    clearable
                    placeholder="Select main category"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="selectedSubCategory"
                    :items="subCategories"
                    label="Sub Category"
                    item-title="sub_category_name"
                    item-value="sub_category_name"
                    variant="outlined"
                    clearable
                    placeholder="Select sub category"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    append-inner-icon="mdi-magnify"
                    placeholder="Search Products"
                    label="Search"
                    hide-details
                    clearable
                    v-model="search"
                    variant="outlined"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>

            
          </v-data-table>
          <!-- <v-row>
          <v-col cols="12" class="text-right">
            <v-pagination
              v-model="page"
              :length="totalPages"
              @input="fetchProducts"
            ></v-pagination>
          </v-col>
        </v-row> -->
        </v-col>
    </v-card>   
  </v-card>
<!-- Delete product confirmation dialog -->
    <v-dialog v-model="delete_product_dialog" max-width="500" max-height="500" persistent>
      <v-card class="pa-1">
        <v-row dense class="rowClr ma-1">
          <v-col cols="12" md="9">
            <v-card-title class="headline">DELETE PRODUCT</v-card-title>
          </v-col>
          <v-col cols="12" md="3" class="d-flex justify-end pt-3 pr-3">
            <v-btn icon size="x-small" @click="delete_product_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-divider class="mt-3"></v-divider>

        <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this product ?</v-card-text>
        <v-card-text class="textspace">
          This product will be deleted immediately. Once deleted, it can no longer be used in any purpose.
        </v-card-text>
        <v-divider class="mb-3"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="deleteItem" variant="outlined" class="bg-red-lighten-4">DELETE</v-btn>&nbsp; &nbsp; 
          <v-btn @click="delete_product_dialog = false" variant="outlined" class="bg-grey-lighten-2">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- View Products Dialog -->
    <v-dialog v-model="view_product_dialog" fullscreen>
      <v-card class="card-size">
        <v-toolbar dense color="white" class="pr-5 mb-5">
          <v-toolbar-title>
              <img @click="homeReload()" :src="require('@/assets/app_bar_logo.png')"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" variant="elevated" dark @click="view_product_dialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-card class="pa-3 mx-6">
            <v-icon>mdi-view-dashboard
            </v-icon>
            <v-label class="ml-6">PROJECTS DETAILS</v-label>
            </v-card>
            <v-card class="pa-3 mx-6 mt-3">
              <v-row class="ma-0">
                <v-col cols="10">
                  <v-row>
                    <v-col cols="2">
                      <!-- <v-img
                        :src="require(`@/assets/topSellingProducts/${selectedImage}`)"
                        class="product-image-view"
                        aspect-ratio="1"
                      ></v-img> -->
                      <!-- <v-img
                        :src="selectedImage ? require(`@/assets/topSellingProducts/${selectedImage}`) : require('@/assets/topSellingProducts/top-selling-oroducts-3.jpg')"
                        class="product-image-view"
                        aspect-ratio="1"
                      ></v-img> -->
                      <v-img
                        :src="selectedImage ? selectedImage : ''"
                        class="product-image-view"
                        aspect-ratio="1"
                      ></v-img>

                    </v-col>
                    <v-col cols="10" class="text-left">
                        <h4 class="black-text">{{ product.product_title }}</h4>
                        <h4 class="d-flex align-center">
                          <h2 class="red-text">LKR.{{ product.price }}</h2>
                          <h5>&nbsp;&nbsp; Rs. {{ oldPrice }}</h5>
                          <h5 class="red-text">&nbsp;&nbsp; {{ product.discount_rate }}</h5>
                        </h4>
                        <h4 class="d-flex align-center">
                          <h5>STOCK</h5>
                          <h5 class="red-text">&nbsp;&nbsp; {{ product.available_stock }}</h5>
                        </h4>
                      <v-row class="mt-1">
                        
                        <v-col v-for="(image, index) in product.product_image" :key="index" cols="1">
                          <v-img
                            :src="image.image_url"
                            class="thumbnail-image"
                            aspect-ratio="1"
                            @click="selectImage(image.image_url)"
                          ></v-img>
                        </v-col>

                      </v-row>
                      
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-row class="text-right pa-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mr-3 bg-green-lighten-5 "
                        rounded
                        variant="outlined"
                        size="small"
                        color="green"
                        icon
                        @click="editItem(product)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        class="bg-red-lighten-5"
                        rounded
                        variant="outlined"
                        size="small"
                        color="red"
                        icon
                        @click="confirmDelete(product)"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                </v-row>
                </v-col>
              </v-row>
              <v-divider class="mt-3 mb-6"></v-divider>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    class="px-3 red-text-field"
                    label="Product Name"
                    v-model="product.product_title"
                    readonly
                    variant="outlined">
                  </v-text-field>

                  <v-text-field
                    label="Main Category"
                    v-model="product.main_category"
                    readonly
                    variant="outlined"
                    class="px-3 red-text-field"
                  ></v-text-field>
                  <v-text-field
                    label="Sub Category"
                    v-model="product.sub_category"
                    readonly
                    variant="outlined"
                    class="px-3 red-text-field"
                  ></v-text-field>
                  <v-text-field
                    label="Unit Price"
                    v-model="product.price"
                    readonly
                    variant="outlined"
                    class="px-3 red-text-field"
                  ></v-text-field>
                  <v-textarea
                    label="Product Description"
                    v-model="product.product_desc"
                    readonly
                    variant="outlined"
                    class="px-3 red-text-field"
                  ></v-textarea>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                    label="Size"
                    v-model="product.size"
                    readonly
                    variant="outlined"
                    class="px-3 red-text-field"
                  ></v-text-field>
                  <v-text-field
                    label="Color"
                    v-model="product.color"
                    readonly
                    variant="outlined"
                    class="px-3 red-text-field"
                  ></v-text-field>
                  <v-text-field
                    label="Available Stock (Quantity)"
                    v-model="product.available_stock"
                    readonly
                    variant="outlined"
                    class="px-3 red-text-field"
                  ></v-text-field>
                  <v-text-field
                    label="Discount Rate"
                    v-model="product.discount_rate"
                    readonly
                    variant="outlined"
                    class="px-3 red-text-field"
                  ></v-text-field>
                  <v-card flat  v-if="previewImages.length > 0">
                    <h5 class="px-3">Product Images</h5>
                    <div class="drop-area px-3"> 
                      <div class="image-grid">
                        <div v-for="(image, index) in previewImages" :key="index" class="image-card-container">
                          <v-card class="image-card">
                            <v-img
                              :src="image.image_url"
                              :alt="'Image ' + (index + 1)"
                              class="image-preview"
                            ></v-img>
                          </v-card>
                        </div>
                      </div>
                    </div> 
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>      
      </v-card>
    </v-dialog>
    <!-- Edit products dialog -->
    <v-dialog v-model="edit_product_dialog" fullscreen>
      <v-card class="card-size">
        <v-toolbar dense color="white" class="pr-5 mb-5">
          <v-toolbar-title>
              <img @click="homeReload()" :src="require('@/assets/app_bar_logo.png')"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" variant="elevated" dark @click="edit_product_dialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;Close
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-card class="pa-3 mx-6">
            <v-icon>mdi-view-dashboard
            </v-icon>
            <v-label class="ml-6">UPDATE PRODUCT</v-label>
            </v-card>
            <v-card class="pa-3 mx-6 mt-3">
              <v-row class="ma-0">
                <v-col cols="10">
                  <v-row>
                    <v-col cols="2">
                      <!-- <v-img
                        :src="selectedImage ? require(`@/assets/topSellingProducts/${selectedImage}`) : ''"
                        class="product-image-view"
                        aspect-ratio="1"
                      ></v-img> -->
                      <v-img
                        :src="selectedImage ? selectedImage : ''"
                        class="product-image-view"
                        aspect-ratio="1"
                      ></v-img>
                    </v-col>
                    <v-col cols="10" class="text-left">
                        <h4 class="black-text">{{ product.product_title }}</h4>
                        <h4 class="d-flex align-center">
                          <h2 class="red-text">LKR.{{ product.price }}</h2>
                          <h5>&nbsp;&nbsp; Rs. {{ oldPrice }}</h5>
                          <h5 class="red-text">&nbsp;&nbsp; -{{ product.discount_rate }}%</h5>
                        </h4>
                        <h4 class="d-flex align-center">
                          <h5>STOCK</h5>
                          <h5 class="red-text">&nbsp;&nbsp; {{ product.available_stock }}</h5>
                        </h4>
                      <v-row class="mt-1">
                        <v-col v-for="(image, index) in product.product_image" :key="index" cols="1">
                          <v-img
                            :src="image.image_url"
                            class="thumbnail-image"
                            aspect-ratio="1"
                            @click="selectImage(image.image_url)"
                          ></v-img>
                        </v-col>
                      </v-row>
                      
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-row class="text-right pa-3">
                    <v-spacer></v-spacer>
                    <v-btn variant="outlined" size="large" class="red-background-text" @click="updateProduct">UPDATE PRODUCT</v-btn> 
                  </v-row>
                </v-col>
              </v-row>
              
              <v-divider class="mt-3 mb-6"></v-divider>
              <v-form ref="form">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    class="px-3 mb-2 red-text-field"
                    label="Product Name *"
                    v-model="product.product_title"
                    variant="outlined"
                    required
                    :rules="[v => !!v || 'Product name is required']"
                    >                    
                  </v-text-field>

                  <v-select
                    label="Main Category *"
                    v-model="product.main_category"
                    :items="mainCategories"
                    variant="outlined"
                    class="px-3 mb-2 red-text-field"
                    item-title="name"
                    item-value="id"
                    @update:modelValue="fetchSubCategories()"
                    required
                    :rules="[v => !!v || 'Main category is required']"
                  ></v-select>

                  <v-select
                    label="Sub Category *"
                    v-model="product.sub_category"
                    :items="subCategories"
                    variant="outlined"
                    class="px-3 mb-2 red-text-field"
                    item-title="sub_category_name"
                    item-value="sub_category_name"
                    :rules="[v => !!v || 'Sub category is required']"
                    required
                  ></v-select>
                  
                  <v-text-field
                    label="Unit Price *"
                    v-model="product.price"
                    variant="outlined"
                    class="px-3 mb-2 red-text-field"
                    :rules="[v => !isNaN(Number(v)) || 'Price must be a number']"
                    required
                  ></v-text-field>

                  <v-textarea
                    label="Product Description *"
                    v-model="product.product_desc"
                    variant="outlined"
                    class="px-3 mb-2 red-text-field"
                  ></v-textarea>

                </v-col>
                <v-col cols="6">
                    <v-text-field
                    label="Size"
                    v-model="product.size"
                    variant="outlined"
                    class="px-3 mb-2 red-text-field"
                    :rules="[v => sizeValidation(v) || 'Invalid size format']"
                  ></v-text-field>
                  <!-- <v-text-field
                    label="Color *"
                    v-model="product.color"
                    variant="outlined"
                    class="px-3 red-text-field"
                  ></v-text-field> -->
                  <v-text-field
                    class="mb-2 px-3"
                    label="Color *"
                    variant="outlined"
                    @click="dialog = true"
                    v-model="product.color"
                  ></v-text-field>

                  <v-dialog v-model="dialog" persistent max-width="400">
                    <v-card class="dialog-card-style px-3">
                      <v-card-title>Select Color</v-card-title>
                      <v-card-text>
                        <v-color-picker
                          v-model="product.color"
                          v-model:mode="mode"
                          class="mb-6"
                        ></v-color-picker>
                        <v-select
                          v-model="mode"
                          :items="modes"
                          variant="outlined"
                        ></v-select>
                      </v-card-text>
                      <v-btn variant="flat" block class="red-background-text-btn px-9 mb-6" @click="dialog = false"><v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;&nbsp;&nbsp;ADD</v-btn>
                    </v-card>
                  </v-dialog>
                  <v-text-field
                    label="Available Stock (Quantity) *"
                    v-model="product.available_stock"
                    variant="outlined"
                    class="px-3 mb-2 red-text-field"
                    required
                    :rules="[v => !isNaN(Number(v)) || 'Available stock required and must be a number']"
                  ></v-text-field>
                  <v-text-field
                    label="Discount Rate *"
                    v-model="product.discount_rate"
                    variant="outlined"
                    class="px-3 mb-2 red-text-field"
                    :rules="[v => !isNaN(Number(v)) || 'Discount rate must be a number', v => Number(v) >= 0 || 'Discount rate must be 0 or higher', v => Number(v) < 100 || 'Discount rate must be less than 100']"
                  ></v-text-field>
                  <v-card flat>
                    <h5 class="px-3">Product Images</h5>
                <div
                  class="drop-area"
                  @drop.prevent="onDrop"
                  @dragover.prevent="onDragOver"
                  @click="triggerFileInput"
                >
                  <input
                    ref="fileInput"
                    type="file"
                    multiple
                    accept="image/*"
                    class="hidden-file-input"
                    @change="onFileChange"
                  />
                  <div
                    v-for="(image, index) in previewImages"
                    :key="index"
                    class="image-card-container"
                  >
                    <v-card
                      class="image-card"
                    >
                      <v-img
                        :src="image"
                        :alt="'Image ' + (index + 1)"
                        class="image-preview"
                      ></v-img>
                      <v-btn
                        icon
                        size="x-small"
                        @click.stop="removeImage(index)"
                        class="remove-icon"
                      >
                        <v-icon size="small" color="red">mdi-delete</v-icon>
                      </v-btn>
                    </v-card>
                  </div> 
                  <div
                    v-if="previewImages.length === 0"
                    class="placeholder-container"
                  >
                    <v-icon size="36">mdi-cloud-upload</v-icon>
                    <span>Drag your photo here OR click to browse from device</span>
                  </div>
                </div>
              </v-card>
                 
                </v-col>
              </v-row>
            </v-form>
            </v-card>
          </v-col>
        </v-row>
        
      </v-card>
    </v-dialog>
      <!-- Snackbar to show notifications -->
   <v-snackbar
      v-model="snackAlert.snackbar"
      :color="snackAlert.snackColor"
      :top="snackAlert.snackBarTop"
      :right="snackAlert.snackBarRight"
      timeout="3000"
    >
      {{ snackAlert.snackMessage }}
      
    </v-snackbar>
    
  </template>
  
  <script>
  import axios from 'axios';
  import jsPDF from 'jspdf';
  import 'jspdf-autotable';
  import * as XLSX from "xlsx";

  export default {
    components: {
     
    },
    name: 'ProductsList',
    data() {
      return {

        form: {
          images: []
        },
        //search
        search: '',

        selectedMainCategory: null,
        selectedSubCategory: null,

        // Pagination
        itemsPerPage: 10,
        page: 1,
        totalPages: 0,

       
        headers: [
          { text: '', value: 'data-table-select', title: 'data-table-select' },
          { text: 'PRODUCT NAME', value: 'product_title', title: 'PRODUCT NAME', sortable: true },
          { text: 'MAIN CATEGORY', value: 'main_category', title: 'MAIN CATEGORY', sortable: true },
          { text: 'SUB CATEGORY', value: 'sub_category', title: 'SUB CATEGORY', sortable: true },
          { text: 'PRICE', value: 'price', title: 'PRICE', sortable: true },
          { text: 'STOCK AVAILABILITY', value: 'available_stock', title: 'STOCK AVAILABILITY', sortable: true },
          { text: 'ACTION', value: 'action', sortable: false, title: 'ACTION' },
        ],


         // Dialog state
        delete_product_dialog: false,
        view_product_dialog: false,
        edit_product_dialog: false,

        selectedProductId: null,
        product: {},

        numericRule: value => !isNaN(parseFloat(value)) || 'Price must be a number',

        item:[],
       

        mainCategories: [],
        subCategories: [],

        // List of products
        items: [],  

        // Additional fields
        product_image: [], // List of images for upload

        // Image handling
        previewImages: [],
        selectedImage: '',

        snackAlert: {
          snackbar: false, // Snackbar visibility
          snackColor: "", // Snackbar color
          snackMessage: "", // Snackbar message
          snackBarRight: false, // Snackbar position right
          snackBarTop: true,  // Snackbar position top
        },

        productcheck: null,

        dialog: false,
        mode: 'hsla',
        modes: ['hsla', 'rgba', 'hexa'],

      };
    },
    created() {
      // this.fetchCategories();
      this.fetchProducts();
      this.fetchMainCategories();
    },
    methods: {
      sizeValidation(value) {
        const sizePattern = /^\d+(\.\d+)?\s*(cm|centimeter|centimeters|mm|millimeter|millimeters|in|inches|m|meter|meters|ft|feet|other)$/i;
        return sizePattern.test(value.trim());
      },
      exportExcel() {
        const tableData = this.items.map((item, index) => ({
          '#': index + 1,
          'PRODUCT NAME': item.product_title,
          'MAIN CATEGORY': item.main_category,
          'SUB CATEGORY': item.sub_category,
          'PRICE': item.price,
          'STOCK AVAILABILITY': item.available_stock,
        }));

        const worksheet = XLSX.utils.json_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');


        XLSX.writeFile(workbook, 'products_analysis.xlsx');
      },

      exportPDF() {
        const doc = new jsPDF();

        // Set the title for the document
        doc.text('Product Analysis', 14, 10);

        // Prepare the data for the table
        const tableData = this.items.map((item, index) => [
          index + 1,
          item.product_title,
          item.main_category,
          item.sub_category,
          item.price,
          item.available_stock,
        ]);

        // Define the columns for the table
        const tableColumns = ['#', 'PRODUCT NAME', 'MAIN CATEGORY', 'SUB CATEGORY', 'PRICE', 'STOCK AVAILABILITY'];

        // AutoTable plugin to create a table
        doc.autoTable({
          head: [tableColumns],
          body: tableData,
        });

        // Save the PDF with a name
        doc.save('products_analysis.pdf');

      },

      showSnackbar(message, color) {
        this.snackAlert.snackbar = true;
        this.snackAlert.snackMessage = message;
        this.snackAlert.snackColor = color;
      },

      fetchMainCategories() {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token'); 

        axios.post('/api/mainProduct', {
            userId: userId
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            let sample = response.data.data;
            this.mainCategories = sample.map(item => {
                return {
                    id: item.id,
                    name: item.main_category
                };
            });
            console.log('Main categories:', this.mainCategories);
        })
        .catch(error => {
            console.error('Error fetching main categories:', error);
        });
    },


      fetchSubCategories() {
        // if (!this.product.main_category) return;
         
          const token = localStorage.getItem('token');
          const userId = localStorage.getItem('userId');
          const mainCatagoryId = this.product.main_category || this.selectedMainCategory;
          console.log('Main Category ID:', this.product.main_category);
          console.log('Main Category ID t:', this.selectedMainCategory);

          axios.post(`/api/subcatagoryProduct`,
          { userId: userId,
            mainCatagoryId: mainCatagoryId 
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

          .then(response => {
            console.log('API Response:', response); 
            this.subCategories = response.data.data;
            console.log('Subcategories:', this.subCategories);
            // Find and set the main category name
            const selectedCategory = this.mainCategories.find(cat => cat.id === mainCatagoryId);
            this.product.main_category_name = selectedCategory ? selectedCategory.name : '';
            this.selectedMainCategory = selectedCategory ? selectedCategory.name : '';
            console.log('Selected Main Category Name:', this.product.main_category_name);
          })
          .catch(error => {
              console.error('Error fetching sub categories:', error);
          });
      },

      getCategoryName(categoryId) {
        console.log('Looking up categoryId:', categoryId);
        if (typeof categoryId === 'string') {
          categoryId = parseInt(categoryId, 10); // Convert string ID to number if needed
        }
        const category = this.mainCategories.find(cat => cat.id === categoryId);
        console.log('Found category:', category);
        return category ? category.name : 'Unknown';
      },

      async updateProduct() {
        // // Validation code
        // const isValid = this.$refs.product.validate();
        // if (!isValid) {
        //   this.snackAlert.snackbar = true;
        //   this.snackAlert.snackColor = 'error';
        //   this.snackAlert.snackMessage = 'Please fill out all required fields correctly.';
        //   return;
        // }
        // Access the form reference and validate it
        const form = this.$refs.form;
        const isValid = form.validate();

        if (!isValid) {
          // If the form is not valid, show an error message and return
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'error';
          this.snackAlert.snackMessage = 'Please fill out all required fields correctly.';
          return;
        }
        // Example: validate size before updating product
        const sizeInput = this.product.size; // Adjust according to your product model
          if (!this.sizeValidation(sizeInput)) {
            this.snackAlert.snackbar = true;
            this.snackAlert.snackColor = 'error';
            this.snackAlert.snackMessage = 'Invalid size format.';
            return;
          }
  // if (!this.sizeValidation(product.size)) {
  //   this.snackAlert.snackbar = true;
  //   this.snackAlert.snackColor = 'error';
  //   this.snackAlert.snackMessage = 'Invalid size format';
  //     return;
  //   }
        const formData = new FormData();
        formData.append('productId', this.product.id);
        formData.append('productTitle', this.product.product_title);
        formData.append('productDescription', this.product.product_desc);
        formData.append('mainCatagory', this.product.main_category);
        formData.append('subCatagory', this.product.sub_category); 
        formData.append('size', this.product.size);
        formData.append('color', this.product.color);
        formData.append('price', this.product.price);
        formData.append('discountRate', this.product.discount_rate);
        formData.append('availableStaock', this.product.available_stock);
        formData.append('userId', localStorage.getItem('userId'));


        console.log('Images array:', this.product.images);

        // Convert images to Base64 and append them to FormData
        try {

          // const base64Images = await Promise.all(
          //   this.product.product_image.map(file => this.convertToBase64(file))
          // );

          // // Debug: log the Base64 images array
          // console.log('Base64 Images:', base64Images);

          // const imagesJson = JSON.stringify(base64Images.map(image => ({ image })));
          // formData.append('productImage', imagesJson);

          // const base64Images = await Promise.all(
          //     this.form.images.map(file => this.convertToBase64(file))
          // );

          // // Debug: log the Base64 images array
          // console.log('Base64 Images:', base64Images);

          // const imagesJson = JSON.stringify(base64Images.map(image => ({ image })));
          // formData.append('productImage', imagesJson);

          // Convert new images to base64
          const base64Images = await Promise.all(
              this.form.images.map(file => this.convertToBase64(file))
          );

          // Collect previously existing images
          const previousImages = this.product.product_image.map(img => ({
              image: img.image_url // Assuming the backend accepts URLs for existing images
          }));

          // Combine previous images and new ones
          const allImages = [
              ...previousImages,  // Old images
              ...base64Images.map(image => ({ image })) // New images (converted to base64)
          ];

          const imagesJson = JSON.stringify(allImages);
          formData.append('productImage', imagesJson);

          for (let [key, value] of formData.entries()) {
              console.log(`${key}:`, value);
          }

          // Send FormData to the API
          const token = localStorage.getItem('token');
          const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/addProduct`, formData, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          });

          if (response.data.success) {
            this.snackAlert.snackbar = true;
            this.snackAlert.snackColor = 'success';
            this.snackAlert.snackMessage = 'Product updated successfully';
            this.view_product_dialog = false;
            this.edit_product_dialog = false;
            this.fetchProducts();
            this.product.main_category_name='';
            this.selectedMainCategory='';
          } else {
            this.snackAlert.snackbar = true;
            this.snackAlert.snackColor = 'error';
            this.snackAlert.snackMessage = 'Failed to update product';
            this.fetchProducts();
          }
        } catch (error) {
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'error';
          this.snackAlert.snackMessage = 'Please fill out all required fields correctly';
          console.error('Error updating product:', error);
        }
      },

      // Helper method to convert a file to a Base64 string
      convertToBase64(file) {
        return new Promise((resolve, reject) => {
            if (file instanceof Blob) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            } else {
                reject(new Error('Invalid file type'));
            }
        });
      },
     
      homeReload(){
        window.location.href = '/dashboard';
      },

      fetchProducts() {

        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem("token");

        const formData = new FormData();
        formData.append("userId", userId);

        axios.post('/api/getProductList',formData,{
          headers: {
                'Authorization': `Bearer ${token}`
            }

        })
          .then(response => {
            // Sort the data by created_at in descending order (newest first)
            this.items = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            // this.items = response.data.data;
            console.log('Result', response.data.data);
            // this.subCategories = response.data.subCategories;
          })
          .catch(error => {
            console.error('Error fetching categories:', error);
          });
      },

      addProductsPageRoute(){
        this.$router.push({ path: '/add-products' });
      },

      

      viewItem(item) {
        this.selectedProductId = item.id;
        this.product = { ...item }; // Create a copy of the item
        this.selectedImage = item.product_image[0] || ''; // Set the initial selected image
        this.previewImages = item.product_image || [];
        this.view_product_dialog = true;
        console.log("View item:", item);
      },

      editItem(item) {
        this.edit_product_dialog = true;
        this.selectedProductId = item.id;
        this.product = { ...item }; // Create a copy of the item
        this.selectedImage = item.product_image[0] || ''; // Set the initial selected image
        // this.previewImages = item.product_image || [];
        // Map the existing product image URLs for preview
        this.previewImages = item.product_image.map(img => img.image_url) || []; 

        // Clear any new images to start fresh
        this.form.images = [];
        console.log('Editing item:', item);
        console.log("Edit item:", item);

        
      },

      confirmDelete(item) {
        this.delete_product_dialog = true;
        this.selectedProductId = item.id;
        console.log('Deleting item:', this.selectedProductId);
      },
      deleteItem() {
          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem('token');
          const ProductID = this.selectedProductId;
          console.log('delete id', ProductID);

          axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/deleteProduct`, {
            userId: userId,
            ProductID: ProductID,
          }, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          .then(() => {
            // Handle success - remove the deleted item from the table data
            this.fetchProducts(); // Refresh the user list
            this.delete_product_dialog = false; // Close the confirmation dialog
            this.view_product_dialog = false;
            console.log('product deleted successfully');
            this.showSnackbar("Product delete successfully!", "success");
          })
          .catch((error) => {
            console.log('Error deleting Product', error);
            this.delete_product_dialog = false;
            this.fetchProducts();
          });
        },
      backPageRoute(){
        this.$router.back();
      },
      selectImage(image) {
        this.selectedImage = image;
      },
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      onFileChange(event) {
        const files = event.target.files;
        this.handleFiles(files);
      },
      onDrop(event) {
        const files = event.dataTransfer.files;
        this.handleFiles(files);
      },
      onDragOver(event) {
        event.preventDefault();
      },

      handleFiles(files) {
        const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const fileArray = Array.from(files).filter(file => validImageTypes.includes(file.type));

        if (fileArray.length === 0) {
            alert('Only image files are allowed!');
            return;
        }

        // Ensure each item in the array is a File object
        fileArray.forEach(file => {
            if (!(file instanceof File)) {
                console.error('Not a valid File object:', file);
            }
        });

        // Add new files to form images
        this.form.images.push(...fileArray);

        // Preview the new image URLs alongside the existing ones
        this.previewImages.push(...fileArray.map(file => URL.createObjectURL(file)));
    },


      removeImage(index) {
        this.form.images.splice(index, 1); // Remove the image object (containing filename) from form data
        this.previewImages.splice(index, 1); // Remove the preview image URL from the UI
      },

     
    },

    mounted() {
      if (this.product.product_image && this.product.product_image.length > 0) {
        this.selectedImage = this.product.product_image[0];
      }
    },

    computed: {
      oldPrice() {
        const price = parseFloat(this.product.price);
        const discountRate = parseFloat(this.product.discount_rate);
        if (discountRate > 0) {
          return (price / (1 - discountRate / 100)).toFixed(2);
        }
        return price.toFixed(2); // If no discount, old price is the current price
      },
      // filteredItems() {
        
      //   return this.items.filter(product => {
      //     const matchesMainCategory = !this.selectedMainCategory || product.main_category === this.selectedMainCategory;
      //     const matchesSubCategory = !this.selectedSubCategory || product.sub_category === this.selectedSubCategory;
      //     // const matchesSearch = !this.search || product.product_title.toLowerCase().includes(this.search.toLowerCase());
      //     const matchesSearch = !this.search || 
      //       product.product_title.toLowerCase().includes(this.search.toLowerCase()) ||
      //       product.main_category_name?.toLowerCase().includes(this.search.toLowerCase()) || 
      //       product.sub_category_name?.toLowerCase().includes(this.search.toLowerCase()) ||
      //       product.price.toString().includes(this.search.toLowerCase()) || 
      //       product.available_stock.toString().includes(this.search.toLowerCase());

      //     return matchesMainCategory && matchesSubCategory && matchesSearch;
      //   });
      // },
      filteredItems() {
        return this.items.filter(product => {
          const matchesMainCategory = !this.selectedMainCategory || this.getCategoryName(product.main_category) === this.selectedMainCategory;

          const matchesSubCategory = !this.selectedSubCategory || product.sub_category_name === this.selectedSubCategory || product.sub_category === this.selectedSubCategory;
          const matchesSearch = !this.search || 
        product.product_title.toLowerCase().includes(this.search.toLowerCase()) ||
        // (product.main_category && product.main_category.toLowerCase().includes(this.search.toLowerCase())) ||
        // (mainCategoryName && mainCategoryName.toLowerCase().includes(this.search.toLowerCase())) ||
        (this.getCategoryName(product.main_category) && this.getCategoryName(product.main_category).toLowerCase().includes(this.search.toLowerCase())) ||
        (product.sub_category && product.sub_category.toLowerCase().includes(this.search.toLowerCase())) ||
        (product.price && product.price.toString().toLowerCase().includes(this.search.toLowerCase())) ||
        (product.available_stock && product.available_stock.toString().toLowerCase().includes(this.search.toLowerCase()));

          return matchesMainCategory && matchesSubCategory && matchesSearch;
        });
        
      },
    },

  };
  </script>
  
  <style scoped>
  .red-background-text {
    background-color: #f0bcbc;
    color: #BB0000;
  } 
  .gray-background-text {
    background-color: rgb(202, 202, 202);
    color: black;
  } 
  .product-details-card{
    height: 100%;
  }
    
  .divider-margin-dv{
    margin-top: -20px;
  }
  .card-size{
    width: 100% !important;
    padding: 0;
    margin-bottom: 0;
  }
  .page-title {
    font-weight: bold;
  }

  .light-gray-text{
    color: #a09e9e !important;
  }

  .red-text {
    color: #b00d0d;
  }

  .black-text{
    color:#000000 !important;
  }
  
  .gray-text {
    color: #757575 !important;
  }
  
  .data-table {
    width: 100% !important;
  }

  .btn-border{
    border-block-end-color: #9a1313;
  }
  .tool-bar-style {
    display: flex;
    justify-content: end;
  }

  .divider-vertical{
    color: #3c3b3b !important;
  }
  .card-two-title{
    color: black;
  }
  .v-card-title {
    font-weight: bold;
  }
  .red-text {
    color: #b00d0d;
  }
  .red-light-text {
    background-color: #f1cccc;
    color: #b00d0d;
  }
  .green-light-text {
    background-color: #aee8b1;
    color: #057b0a;
  }
  .green-text {
    color: #057b0a;
    background-color: #aee8b1;
  }

  .product-image {
    width: 70px;
    height: 70px;
    margin-left: 10px;
    padding: 4px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    border-width: 4px;
    box-shadow: #757575;
    background-color: white;
    margin: 10px;
  }

  .product-image-view {
    width: 150px;
    height: 150px;
    margin-left: 0px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    border-width: 1px;
    box-shadow: #dcdcdc;
    background-color: white;
  }

  .thumbnail-image {
    cursor: pointer;
    width: 50px;
    height: 50px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    border-width: 1px;
    box-shadow: #dcdcdc;
    background-color: white;
  }

  .drop-area {
    border: 2px dashed #9a1313;
    padding: 20px;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    gap: 10px;
  }

  .hidden-file-input {
    display: none;
  }

  .image-card-container {
    margin: 0 8px 8px 0;
  }

  .image-card {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .image-preview {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Space between image cards */
  }

  .placeholder-container {
    width: 100%;
    height: 120px;
    text-align: center;
    color: #9a1313;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .red-text-content .v-input__control .v-input__slot,
  .red-text-content .v-input__control .v-input__slot .v-text-field__details,
  .red-text-content .v-input__control .v-input__slot input,
  .red-text-content .v-input__control .v-input__slot textarea {
    color: red !important;
  }

  .rowClr{
    background-color: #d9d9d9;
  }

  .textspace{
    margin-top: -20px;
  }

  .headline {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .dialog-card-style{
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
  }

  .red-background-text-btn {
    background-color: #f0bcbc;
    color: #BB0000;
    margin-top: -27px;
    margin-bottom: 10px;
    margin-right: 30px;
    margin-left: 30px;
  } 
  .viewbtn{
      font-size: 12px;
    }
    :deep(.dataTable) th, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  text-align: center;
}

:deep(.dataTable) td, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
}

:deep(.dataTable) {
  border: white;
}

:deep(.dataTable )  tr,  th:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}
:deep(.dataTable )  tr,  td:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}
.remove-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: white;
  border-radius: 50%; /* This will make the background round */
  padding: 4px; /* Adjust as needed to control the icon size */
}

.remove-icon .v-icon {
  color: red;
}
</style>
  