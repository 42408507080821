<template>
  <v-card class="mt-3 card-size" flat>
    <v-form ref="form" @submit.prevent="registerUser">
      <v-row class="pl-2 px-6">
        <v-col cols="6" class="mt-3">
          <h3 class="page-title">USER REGISTRATION</h3>
          <h5 class="light-gray-text">Register users details according to your requirements.</h5>
          <v-card-title>
            
          </v-card-title>
        </v-col>
        <v-col cols="6" class="px-4 mt-5">
          <v-row class="tool-bar-style">
              <v-btn size="x-large" class="red-text" variant="text" @click="createUserRole()">
                <v-icon>mdi-account-star</v-icon>&nbsp;&nbsp;CREATE USER ROLE
              </v-btn>
              <v-divider vertical :thickness="3" class="divider-vertical"></v-divider>
              <v-btn variant="text" size="x-large" class="gray-text" @click="backPageRoute()">
                <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
              </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-card elevation="3" class="px-2 pt-2 mx-1 leads-details-card">
        <h5 class="card-two-title px-3 mt-2 mb-4">USERS REGISTRATION</h5>
        <v-divider></v-divider>

        <v-row class="pt-6">
          <v-col cols="6" class="px-6">
            
            <v-card flat class="pt-2 card-hight-fixed">
              <v-col cols="11">
              <v-text-field
                class="mb-2"
                label="Full Name *"
                variant="outlined"
                placeholder="Type full name here"
                v-model="user.fullName"
                required
              ></v-text-field>
              <v-text-field
                class="mb-2"
                label="User Name *"
                variant="outlined"
                placeholder="Type user name here"
                v-model="user.userName"
                required
              ></v-text-field>
              <v-text-field
                class="mb-2"
                label="Password *"
                :append-inner-icon="
                  e1
                    ? 'mdi-eye'
                    : 'mdi-eye-off'
                "
                @click:append-inner="e1 = !e1"
                :type="e1 ? 'text' : 'password'"
                variant="outlined"
                placeholder="Type password here"
                v-model="user.password"
                required
                prepend-inner-icon="mdi-lock-outline"
              ></v-text-field>
              <v-text-field
                class="mb-2"
                label="Confirm Password *"
                :append-inner-icon="
                  e2
                    ? 'mdi-eye'
                    : 'mdi-eye-off'
                "
                @click:append-inner="e2 = !e2"
                :type="e2 ? 'text' : 'password'"
                variant="outlined"
                placeholder="Confirm your password here"
                v-model="user.confirmPassword"
                required
                prepend-inner-icon="mdi-lock-outline"
              ></v-text-field>
              <v-select
                class="mb-2"
                label="User Type *"
                variant="outlined"
                :items="userTypes"
                v-model="user.userType"
                item-title="name"
                item-value="name"
                :rules="[v => !!v || 'User role is required']"
                required
                placeholder="Select User Type Here"
              ></v-select>
              <v-text-field
                class="mb-2"
                label="Registration Number *"
                variant="outlined"
                placeholder="user registration number"
                v-model="user.registrationNumber"
                readonly
                hint="This number is a randomly auto generated number through the system."
              ></v-text-field>
              <v-text-field
                class="mb-2"
                label="Email Address *"
                variant="outlined"
                placeholder="Type email here"
                v-model="user.emailAddress"
                required
              ></v-text-field>

              <div v-for="(phoneNo, index) in phoneNumbers" :key="index">
                  <v-row class="mb-2">
                      <v-col cols="8">
                      <v-text-field v-model="phoneNumbers[index]" label="Phone Number *" variant="outlined" placeholder="Create phone number"></v-text-field>
                      </v-col>
                      <v-col cols="auto" class="align-center justify-center">
                      <v-btn density="default" class="green-text" variant="outlined" icon="mdi-plus-thick" @click="addPhoneNo"></v-btn>
                      </v-col>
                      <v-col cols="auto" class="align-center justify-center">
                      <v-btn density="default" class="red-text-btn" variant="outlined" icon="mdi-minus-thick" @click="removePhoneNo(index)" v-if="phoneNumbers.length > 1"></v-btn>
                      </v-col>
                  </v-row>
              </div>
              
              <v-text-field
                class="mb-2"
                label="NIC *"
                variant="outlined"
                placeholder="Type NIC number here"
                v-model="user.nic"
                required
              ></v-text-field>

              <v-select
                class="mb-2"
                label="Gender *"
                variant="outlined"
                :items="gender"
                v-model="user.gender"
                required
                placeholder="Select Gender"
              ></v-select>
            </v-col>
            </v-card>
          </v-col>
          <v-col cols="6" class="px-6">
            <v-card flat class="pt-2 card-hight-fixed">
              <v-col cols="11">

              <v-select
                class="mb-2"
                label="Province"
                variant="outlined"
                placeholder="Select province"
                v-model="user.province"
                :items="provinces"
                @update:modelValue="getAllDistrictAccProv"
                item-text="label"
                item-value="value"
                :loading="mDistrictLoading"
              ></v-select>

              <v-select
                class="mb-2"
                label="District"
                variant="outlined"
                placeholder="Select district"
                v-model="user.district"
                :items="districts"
                @update:modelValue="getAllDsd"
                item-text="label"
                item-value="value"
                :loading="mDistrictLoading"
              ></v-select>

              <v-select
                class="mb-2"
                label="Divisional"
                variant="outlined"
                placeholder="Select divisional"
                v-model="user.dsd"
                :items="dsdList"
                item-text="label"
                item-value="value"
                @update:modelValue="getAllGnd"
                :loading="mDSDLoading"
              ></v-select>

              <v-select
                class="mb-2"
                label="GND *"
                variant="outlined"
                :items="gndList"
                v-model="user.gnd"
                required
                :loading="mDSDLoading"
                placeholder="Select GND"
              ></v-select>

              <v-textarea
                class="mb-2"
                label="Address *"
                variant="outlined"
                placeholder="Type user address here"
                v-model="user.address"
                required
                rows="2"
              ></v-textarea>

              <v-card flat>
                <div
                  class="drop-area mb-6"
                  @drop.prevent="onDrop"
                  @dragover.prevent="onDragOver"
                  @click="triggerFileInput"
                >
                  <input
                    ref="fileInput"
                    type="file"
                    multiple
                    accept="image/*"
                    class="hidden-file-input"
                    @change="onFileChange"
                  />
                  <div
                    v-if="previewImages.length > 0"
                    class="image-card-container"
                  >
                  <v-card class="image-card">
                    <v-img
                      :src="previewImages[0]"
                      alt="Image preview"
                      class="image-preview"
                    ></v-img>
                    <v-btn
                      icon
                      size="x-small"
                      @click.stop="removeImage(0)"
                      class="remove-icon"
                    >
                      <v-icon size="small" color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-card>
                  </div> 
                  <div
                    v-if="previewImages.length === 0"
                    class="placeholder-container"
                  >
                    <v-icon size="36">mdi-cloud-upload</v-icon>
                    <span>Drag your photo here OR click to browse from device</span>
                  </div>
                </div>
              </v-card>
            </v-col>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="divider-two-style"></v-divider>
        <v-row class="margin-bottomn-fix pa-3 mb-2">
          <v-spacer></v-spacer>
          <v-btn variant="outlined" class="red-background-text mr-3"  type="submit">REGISTER USER</v-btn>
          <v-btn variant="outlined" class="gray-background-text" @click="cancel()">CANCEL</v-btn> 
        </v-row> 
      </v-card>
    </v-form>
    <!-- Snackbar to show notifications -->
    <v-snackbar
      v-model="snackAlert.snackbar"
      :color="snackAlert.snackColor"
      :top="snackAlert.snackBarTop"
      :right="snackAlert.snackBarRight"
      timeout="3000"
    >
      {{ snackAlert.snackMessage }}
      
    </v-snackbar>
  </v-card>
</template>
    
<script>
import axios from 'axios';

export default {
  name: 'UserRegistration',
  data() {
    return {
      user: {
        fullName: '',
        userName: '',
        password: '',
        confirmPassword: '',
        userType: '',
        registrationNumber: '', 
        emailAddress: '',
        phoneNumber: [""],
        nic: '',
        gender: '',
        province: '',
        district: '',
        dsd: '',
        gnd: '',
        address: '',
        image: [],
        profilePhoto: null,
      },

      e1: false,
      e2: false,
      previewImages: [],

      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },

      phoneNumbers: [""],
      userTypes: [], 
      //provinces: ['Province 1', 'Province 2'], // Example provinces
      //districts: ['District 1', 'District 2'], // Example districts
      //dsds: ['DSD 1', 'DSD 2'], // Example DSDs
      gnds: ['GND 1', 'GND 2'], // Example GNDs
      gender: ['Male', 'Female'],

      
      mDistrictLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
    };
  },
  mounted() {
    this.getAllProvinces();
    this.getAllUserRoles();
    this.generateRegistrationNumber();
  },

  methods: {

    generateRegistrationNumber() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token'); 
      axios.post('/api/getLatestRegistrationNumber', {
          userId: userId
      }, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      })
        .then(response => {
          const latestNumber = response.data.latestNumber;
          const nextNumber = (parseInt(latestNumber.replace('REG-', '')) + 1).toString().padStart(4, '0');
          this.user.registrationNumber = `REG-${nextNumber}`;
        })
        .catch(error => {
          console.error('Error fetching the latest registration number:', error);
          // this.user.registrationNumber = 'REG-0000'; 
          this.user.registrationNumber = Math.random().toString(36).substring(2, 10).toUpperCase();
        });
    },

    getAllUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token'); 

      axios.post('/api/getUserRolesType', {
          userId: userId
      }, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      })
      .then(response => {
          let usrRole = response.data.data;
          this.userTypes = usrRole.map(item => {
              return {
                  id: item.id,
                  name: item.role_type
              };
          });
          console.log('User role types:', this.userTypes);
      })
      .catch(error => {
          console.error('Error fetching user roles:', error);
      });
    },
    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mDistrictLoading = false;
            }
          })
          .catch(() => {
            this.mDistrictLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },

    
    backPageRoute(){
      this.$router.back();
    },

    addPhoneNo() {
        this.phoneNumbers.push('');
    },

    removePhoneNo(index) {
        this.phoneNumbers.splice(index, 1);
    },

    // Method to convert a file to a base64 string
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

    async registerUser() {
      try {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem("token");

        console.log("Token:", token);
        console.log("Retrieved UserId from localStorage:", userId);

        // Log user data and form details
        console.log("User Data:", this.user);
        console.log("Phone Numbers:", this.phoneNumbers);

        // Form validation
        if (this.$refs.form && this.$refs.form.validate()) {
          // Validate password and confirmation password

          // console.log('user Regi',this.user.password === this.user.confirmPassword);
          // if (this.user.password !== this.user.confirmPassword) {
          //   this.showSnackbar("Passwords do not match.", "warning");
          //   return;
          // }

          const formData = new FormData();
          formData.append("userId", userId);
          console.log("userId", userId);
          formData.append("fullName", this.user.fullName);
          console.log("fullName", this.user.fullName);
          formData.append("userName", this.user.userName);
          console.log("userName", this.user.userName);
          formData.append("password", this.user.password);
          console.log("password", this.user.password);
          formData.append("confirmPassword", this.user.confirmPassword);
          console.log("confirmPassword", this.user.confirmPassword);
          formData.append("userType", this.user.userType);
          console.log("userType", this.user.userType);
          formData.append("registrationNumber", this.user.registrationNumber);
          console.log("registrationNumber", this.user.registrationNumber);
          formData.append("emailAddress", this.user.emailAddress);
          console.log("emailAddress", this.user.emailAddress);
          formData.append("nic", this.user.nic);
          console.log("nic", this.user.nic);
          formData.append("gender", this.user.gender);
          console.log("gender", this.user.gender);
          formData.append("province", this.user.province);
          console.log("province", this.user.province);
          formData.append("district", this.user.district);
          console.log("district", this.user.district);
          formData.append("dsd", this.user.dsd);
          console.log("dsd", this.user.dsd);
          formData.append("gnd", this.user.gnd);
          console.log("gnd", this.user.gnd);
          formData.append("address", this.user.address);
          console.log("address", this.user.address);

          
          formData.append("mobileNumber", this.phoneNumbers);


          console.log("mobileNumber", this.phoneNumbers);
          // console.log('phone numbers array',JSON.stringify(this.phoneNumbers));

          // // Append images to form data
          // if (this.user.image) {
          //   this.user.image.forEach((image, index) => {
          //     formData.append(`image_${index}`, image);
          //   });
          // }

          // Append the single image to form data
          // if (this.user.image && this.user.image.length > 0) {
          //   formData.append("image", this.user.image[0]);
          // }

          // console.log("image", this.user.image[0]);

          // Append the single image to form data as base64
          // Convert the image to a base64 string if an image is selected
        if (this.user.image && this.user.image.length > 0) {
          await this.convertToBase64(this.user.image[0]).then(base64String => {
            formData.append("image", base64String);
        console.log("image", base64String);
          });
        }



          // API call
          const response = await axios.post(
            "/api/createUser",
            formData,
            {
              headers: {
                'Authorization': `Bearer ${token}`,              
              },
            }
          );

          // Log the response from the server
          console.log("API Response:", response);
          console.log("API Response2:", response.data);
          console.log("API Response3:", response.data.data);

          if (response.status === 201) {
            this.showSnackbar("User registered successfully!", "success");
            this.cancel(); // Reset form after successful registration
          } else {
            this.showSnackbar("Unexpected response status.", "warning");
          }
        }
      } catch (error) {
        if (error.response) {
          console.error('Error response data:', error.response.data);
          console.error('Error response status:', error.response.status);
          console.error('Error response headers:', error.response.headers);
        } else if (error.request) {
          console.error('Error request data:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        this.showSnackbar("Failed to register user.", "error");
      }
    },


    cancel() {
      // Reset form fields
      this.user = {
        fullName: "",
        userName: "",
        password: "",
        confirmPassword: "",
        userType: "",
        emailAddress: "",
        nic: "",
        gender: "",
        province: "",
        district: "",
        dsd: "",
        gnd: "",
        address: "",
        image: [],
        phoneNo:"",
      };
      this.phoneNumbers = [];
      this.previewImages = [];
    },
    
    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.handleFiles(file);
      }
    },

    onDrop(event) {
      const file = event.dataTransfer.files[0];
      if (file) {
        this.handleFiles(file);
      }
    },

    onDragOver(event) {
      event.preventDefault();
    },

   

    handleFiles(file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

      if (!validImageTypes.includes(file.type)) {
        alert('Only image files are allowed!');
        return;
      }

      // Clear previous image
      this.user.image = [];
      this.previewImages = [];

      this.user.image.push(file);
      this.previewImages.push(URL.createObjectURL(file));
    },

    removeImage(index) {
      if (index >= 0 && index < this.user.image.length) {
        this.user.image.splice(index, 1);
        this.previewImages.splice(index, 1);
      }
    },

    createUserRole(){
      this.$router.push({ path: '/create-user-role' });
    },

  }
};
</script>
    
<style scoped>
  .page-title {
    font-weight: bold;
  }

  .light-gray-text{
    color: #a09e9e !important;
  }

  .red-text {
    color: #b00d0d;
  }
  
  .gray-text {
    color: #757575 !important;
  }

  .btn-border{
    border-block-end-color: #9a1313;
  }

  .card-size{
    width: 100%;
    padding: 0;
    margin-bottom: 0;
  }

  .tool-bar-style {
    display: flex;
    justify-content: end;
  }

  .divider-vertical{
    color: #3c3b3b !important;
  }

  .card-two-title{
    color: black;
  }
  .leads-details-card{
    height: 100%;
  }
  .card-hight-fixed{
    height: 100%;
    overflow: auto;
    max-height: 63vh;
  }
  .red-background-text {
    background-color: #f0bcbc;
    color: #BB0000;
  } 
  .gray-background-text {
    background-color: rgb(202, 202, 202);
    color: black;
  } 
  .divider-two-style{
    margin-top: -5px;
  }
  .remove-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: white;
    border-radius: 50%; /* This will make the background round */
    padding: 4px; /* Adjust as needed to control the icon size */
  }

  .remove-icon .v-icon {
    color: red;
  }

  .placeholder-container {
    width: 100%;
    height: 120px;
    text-align: center;
    color: #9a1313;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .image-preview {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .image-card {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
  }
  .image-card-container {
    margin: 0 8px 8px 0;
  }
  .hidden-file-input {
    display: none;
  }
  .drop-area {
    border: 2px dashed #9a1313;
    padding: 20px;
    margin-top: 0px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
  }

    </style>
    