<template>
  <v-container fluid style="padding: 5px">
  <v-row class="main-heading">
    <v-col cols="12" md="11">
      <v-row>
        <v-card-title>ALL AGENTS LIST</v-card-title>
      </v-row>
      <v-row>
        <v-card-subtitle>Analyse all agents data.</v-card-subtitle>
      </v-row>
    </v-col>
    <v-col cols="12" md="1">
      <v-btn class="back-button" @click="back()">BACK</v-btn>
    </v-col>
  </v-row>

  <v-row class="sub-heading">
    <v-card class="sub-heading-card d-flex align-center">
      <v-col cols="12" md="10">
        <v-card-subtitle class="his-sub-heading">ALL AGENTS</v-card-subtitle>
      </v-col>
      <v-col cols="12" md="1" class="col1">
        <v-btn class="red-light-text" variant="outlined" @click="exportPDF">
          <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
        </v-btn>
      </v-col>
      <v-col cols="12" md="1" class="col2">
        <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
          <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
        </v-btn>
      </v-col>
    </v-card>
  </v-row>

  <v-card class="main-card ma-0 ">
    <v-row class="text-filed-row pa-0 ma-0">
      <v-col cols="12" md="12">
        <v-text-field
            class="text-black"
            label="Search"
            placeholder="Search"
            persistent-placeholder
            variant="outlined"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="table-row">
      <v-col cols="12" class="table-col">
        <v-data-table
            :headers="headers"
            :items="agentListDetails"
            :search="search"
            class="dataTable"
        >
          <template v-slot:[`item.action`]="{ item }" >
            <v-row >
              <v-switch
                  color="red"
                  label="Enable"
                  value="red"
                  hide-details
                  class="BTNswitch"
                  v-model="item.trackingEnabled"
                  @change="toggleEnable(item)"
              ></v-switch>
            </v-row>
            <v-row>
              <v-card-text class="action-text">ENABLE | Disable Tracking Permission </v-card-text>
            </v-row>
          </template>


          <template v-slot:[`item.states`]="{ item }" >
            <v-row >
              <v-chip
                  class="ma-2"
                  color="green"
                  variant="outlined"
                  label
                  v-if="item.states === 'ontrip'"
              >
                ON TRIP
                <v-icon icon="mdi-check" end class="ml-4"></v-icon>
              </v-chip>

              <v-chip
                  class="ma-2"
                  color="orange"
                  variant="outlined"
                  label
                  v-if="item.states === 'idling'"
              >
                IDLING
                <v-icon icon="mdi-close" end class="ml-5"></v-icon>
              </v-chip>

              <v-chip
                  class="ma-2"
                  color="red"
                  variant="outlined"
                  label
                  v-if="item.states === 'parking'"
              >
                PARKING
                <v-icon icon="mdi-check" end class="ml-3"></v-icon>
              </v-chip>
            </v-row>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
export default {
  name: "AllAgents",
  data() {
    return {
      search: '',
      headers: [
        { text: 'AGENT NAME', value: 'name', title: 'AGENT NAME' },
        { text: 'CURRENT ADDRESS', value: 'address', title:'CURRENT ADDRESS'},
        { text: 'DISTRICT', value: 'district', title:'DISTRICT' },
        { text: 'DSD', value: 'dsd', title:'DSD' },
        { text: 'STATES', value: 'states', title: 'STATES' },
        { text: 'Actions', value: 'action', sortable: false , title:'ACTION' },
      ],
      
      // agentListDetails:[],
      
    }
  },
  computed: {
    ...mapGetters({
      agentListDetails: 'getagentListDetails'
      
     }),

    },
  methods: {
    back(){
      this.$router.push('/live-tracking');
    },

    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.text('Not Available report', 14, 10);

      // Prepare the data for the table
      const tableData = this.agentListDetails.map((item) => [
        // index + 1,
        item.name,
        item.email,
        item.address,
        item.district,
        item.dsd,
        
      ]);

      console.log('pdfdata', tableData);

      // Define the columns for the table
      const tableColumns = ['AGENT NAME', 'EMAIL', 'ADDRESS', 'DISTRICT', 'DSD'];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });

      // Save the PDF with a name
      doc.save('Not Available Reports.pdf');
    },

    exportExcel() {
      const tableData = this.agentListDetails.map((item) => ({
        // '#': index + 1,
        'AGENT NAME': item.name,
        'EMAIL': item.email,
        'ADDRESS': item.address,
        'DISTRICT': item.district,
        'DSD': item.dsd
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'All Agents Reports');


      XLSX.writeFile(workbook, 'All Agents Reports.xlsx');
    },
  },
};
</script>

<style scoped>

.main-heading {
  height: 10vh;
  padding: 10px;
  margin: 0;
  display: flex;
}

.red-light-text {
    background-color: #f1cccc;
    color: #b00d0d;
  }
 .red-text {
    color: #b00d0d;
  }

.green-light-text {
    background-color: #aee8b1;
    color: #057b0a;
  }
  .green-text {
    color: #057b0a;
    background-color: #aee8b1;
  }
  
.back-button {
  margin-right: 30px;
  padding: 5px;
}

.sub-heading {
  height: 10vh;
  padding: 10px;
  margin: 0;
}

.sub-heading-card {
  width: 100vw;
  height: 100%;
  padding: 10px;
}

.pdf-btn{
  width:60px;
  background-color: rgb(233, 196, 204);
  border: 1px solid red;
  margin-left: 30px;
}

.exl-btn{
  width:60px;
  background-color: rgb(82, 180, 109);
  border: 1px solid rgb(16, 69, 13);
}

.text-filed-row{
  margin-top: 0;
  height:6vh;
  padding: 20px;
}

.table-col{
  height: 80%;
}

.action-text{
  font-size: 9px;
}
.data-table{
  height: 90%;
  border-radius: 10px;
  border: 1px solid rgb(227, 229, 231);
  margin: 20px;
}
.view-btn{
  background-color: rgb(140, 182, 249);
  border: 1px solid rgb(76, 76, 228);
  border-radius: 5px;
  color: rgb(76, 76, 228);
  margin-right: 20px;
}
.del-btn{
  background-color: rgb(233, 196, 204);
  border: 1px solid red;
  border-radius: 5px;
  color: red;
  margin-right: 20px;
}



</style>



