<template>
  <v-card class="mt-3 card-size" flat>
    <v-row class="px-6">
      <v-col class="mt-3" cols="6">
        <h3 class="page-title">CREATE OUTLETS</h3>
        <h5 class="light-gray-text">Create or bulk upload outlet data set according to your requirements.</h5>
        <v-card-title>

        </v-card-title>
      </v-col>
      <v-col class="px-4 mt-5" cols="6">
        <v-row class="tool-bar-style">
          <v-btn class="red-text" size="large" variant="text" @click="outletBulkUploadDialog = true">
            <v-icon>mdi-file-document</v-icon>&nbsp;OUTLET DATA UPLOAD BULK UPLOAD
          </v-btn>
          <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
          <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card class="px-2 pt-2 mx-1 leads-details-card" elevation="3">
      <h5 class="card-h4-title px-3 mt-2 mb-4 ml-4">CREATE NEW OUTLET DATA</h5>
      <v-divider></v-divider>

      <v-row class="pt-6" style="padding-left: 15px; padding-right: 5px;">
        <v-col class="px-6" cols="6">
          <v-card class="pt-2 card-height-fixed" flat>
            <v-text-field
                v-model="outlet.outlet_name"
                class="mb-2"
                label="Outlet Name"
                placeholder="Type outlet name"
                variant="outlined"

            ></v-text-field>

            <v-text-field
                v-model="outlet.owner_name"
                class="mb-2"
                label="Owner Name"
                placeholder="Type owner name"
                variant="outlined"
            ></v-text-field>

            <v-text-field

                v-model="outlet.mobile_number"
                class="mb-2"
                label="Mobile Number"
                placeholder="Type mobile number"
                variant="outlined"
            ></v-text-field>

            <v-select
                v-model="outlet.outlet_type"
                :items="outletTypes"
                item-title="outlet_name"
                item-value="id"
                label="Select Outlet Type"
                variant="outlined"
            ></v-select>

            <v-select
                v-model="outlet.province"
                :items="provinces"
                :loading="mDistrictLoading"
                label="Province"
                placeholder="Select province"
                variant="outlined"
                @update:modelValue="getAllDistrictAccProv"
            ></v-select>

            <v-select
                v-model="outlet.district"
                :items="districts"
                :loading="mDistrictLoading"
                label="District"
                placeholder="Select district"
                variant="outlined"
                @update:modelValue="getAllDsd"
            ></v-select>

            <v-select
                v-model="outlet.dsd"
                :items="dsdList"
                :loading="mDSDLoading"
                label="DSD"
                placeholder="Select DSD"
                variant="outlined"
                @update:modelValue="getAllGnd"

            ></v-select>

            <v-select
                v-model="outlet.gnd"
                :items="gndList"
                label="GND"
                placeholder="Select GND"
                variant="outlined"
            ></v-select>

            <v-select
                v-model="outlet.outlet_status"
                :items="states"
                class="mb-2"
                label="Outlet States"
                placeholder="Select States"
                variant="outlined"
            ></v-select>

          </v-card>
        </v-col>
        <v-col class="px-6" cols="6">
          <v-card class="pt-2 card-height-fixed" flat>
            <v-text-field
                v-model="outlet.outlet_address"
                class="mb-2"
                label="Outlet Address"
                placeholder="Select Address"
                variant="outlined"
            ></v-text-field>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="outlet.latitude"
                    label="Latitude"
                    readonly
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="outlet.longitude"
                    label="Longitude"
                    readonly
                    variant="outlined"
                ></v-text-field>
              </v-col>

            </v-row>

            <v-divider></v-divider>
            <h5 class="px-3 py-3">USE MAP TO SELECT </h5>
            <h6 class="px-3 pb-3 light-gray-text">In here, you can set the outlet location by searching the location or
              dragging and placing it.</h6>
              <v-divider></v-divider>
              <v-card class="ma-3 pa-5" style="margin-right: 5px" variant="flat" width="100%">
                <Map ref="map" :center="mapCenter" :zoom="13" class="d-flex map" style="width: 100%; height: 50vh" @click="mapClicked">
                  <Marker
                      v-if="markerPosition"
                      :icon="customIcon"
                      :position="markerPosition"
                  />
                  <Circle
                      v-if="markerPosition"
                      :center="markerPosition"
                      :options="circleOptions"
                      :radius="radius"
                  />
                </Map>
              </v-card>
            </v-card>
        </v-col>
      </v-row>

      <v-divider class="divider-two-style"></v-divider>
      <v-row class="margin-bottom-fix pa-3 mb-2">
        <v-spacer></v-spacer>
        <v-btn class="red-background-text mr-3" variant="outlined" @click="createRoute">CREATE NEW OUTLET</v-btn>
        <v-btn class="gray-background-text" variant="outlined" @click="backPageRoute()">CANCEL</v-btn>
      </v-row>
    </v-card>

    <!-- View outlet Dialog -->

    <v-dialog v-model="outletBulkUploadDialog" fullscreen>
      <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
            <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="outletBulkUploadDialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-card class="pa-3 mx-6">
              <v-icon>mdi-view-dashboard
              </v-icon>
              <v-label class="ml-6">UPLOAD SPREADSHEET</v-label>
            </v-card>
            <v-card class="px-3 mx-6 mt-3">
              <v-row class="ma-0">
                <v-col cols="5">
                  <h5 class="px-3">Upload your outlet bulk data set using excel or a csv file.</h5>
                  <v-col cols="12">
                    <v-file-input
                        accept=".csv, .xls, .xlsx"
                        label="Upload your Excel or CSV file"
                        variant="outlined"
                        @change="handleFileUpload"
                    ></v-file-input>
                  </v-col>
                </v-col>
                <v-col class="d-flex align-center justify-center" cols="1">
                  <v-divider :thickness="2" vertical></v-divider>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="3">

                    </v-col>
                    <v-col cols="9">
                      <v-card class="d-flex align-center justify-space-between pa-4 mt-3" color="grey-lighten-3" flat
                              width="100%">
                        <div class="mr-4">
                          <h4 class="mb-0">DOWNLOAD SAMPLE TEMPLATE</h4>
                        </div>
                        <v-btn class="red-background-text btn-border" size="x-large" variant="text"
                               @click="downloadTemplate">
                          DOWNLOAD
                        </v-btn>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider></v-divider>

              <v-card v-if="bulkItems.length > 0" class="ma-3" flat>
                <v-data-table
                    :headers="bulkHeaders"
                    :items="bulkItems"
                    :items-per-page="itemsPerPage"
                    class="elevation-1"
                >
                  <template v-slot:[`item.STATES`]="{ item }">
                    <div>
                      <div>
                        <v-chip v-if="item.outlet_status === 'Allowed'" color="green" style="height: 20px; width:130px; font-size: 12px"
                                variant="outlined">
                          ALLOWED
                          <v-icon class="justify-end ml-14">mdi-check</v-icon>
                        </v-chip>

                        <v-chip v-if="item.outlet_status === 'NotAllowed'" color="red" style="height: 20px; width:130px; font-size: 12px"
                                variant="outlined">
                          NOT ALLOWED
                          <v-icon class="justify-end ml-6">mdi-close</v-icon>
                        </v-chip>
                      </div>
                    </div>
                  </template>

                </v-data-table>
              </v-card>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="bulkItems.length === 0" class="red-background-text btn-border mx-3" size="x-large" variant="text"
                       @click="uploadBulkOutlets">Upload
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        color="success"
        location="top-right"
        outlined
        timeout="3000"
    >
      NEW OUTLET WAS CREATED SUCCESSFULLY.
      <template v-slot:action="{ attrs }">
        <v-btn
            icon
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
        v-model="snackbarFile"
        color="success"
        location="top-right"
        outlined
        timeout="3000"
    >
      FILE UPLOAD SUCCESSFULLY.
      <template v-slot:action="{ attrs }">
        <v-btn
            icon
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay v-model="loading" >
      <v-progress-circular class="centered-progress" indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import axios from 'axios';
// import * as XLSX from 'xlsx';
import {Map, Marker, Circle} from '@fawmi/vue-google-maps';
// import {ref, watch} from "vue";
// import {onMounted, ref} from 'vue';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';


export default {
  components: {
    Map,
    Marker,
    Circle,
  },
  data() {
    return {
      snackbarFile:false,
      loading: false,
      snackbar: false,
      outlet: {
        outlet_name: '',
        owner_name: '',
        mobile_number: '',
        outlet_type_id: null,
        outlet_address: '',
        latitude: '',
        longitude: '',
        province: '',
        district: '',
        dsd: '',
        gnd: '',
        outlet_status: '',
        outlet_type: '',
      },
      states:['ALLOWED', 'NOT ALLOWED', 'NEED APPROVAL'],
      outletTypes: [],
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      mDistrictLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      outletBulkUploadDialog: false,
      bulkFile: null,
      bulkItems: [],
      bulkHeaders: [
        { text: 'Outlet Name', value: 'Outlet Name', title: 'Outlet Name' },
        { text: 'Owner Name', value: 'Owner Name' , title: 'Owner Name' },
        { text: 'Mobile Number', value: 'Mobile Number', title: 'Mobile Number' },
        { text: 'Outlet Type ID', value: 'Outlet Type Id', title: 'Outlet Type ID' },
        { text: 'Outlet Address', value: 'Outlet Address', title: 'Outlet Address' },
        { text: 'Latitude', value: 'Latitude', title: 'Latitude' },
        { text: 'Longitude', value: 'Longitude', title: 'Longitude' },
        { text: 'Province', value: 'Province', title: 'Province' },
        { text: 'District', value: 'District', title: 'District' },
        { text: 'DSD', value: 'DSD', title: 'DSD' },
        { text: 'GND', value: 'GND', title: 'GND' },
        { text: 'Outlet Status', value: 'Outlet Status', title: 'Outlet Status' },
      ],
      mapCenter: { lat: 6.863062576280659, lng: 79.98859753968648 },
      markerPosition: null,
      circleOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      },
      radius: 800,
      customIcon: {
        url: require('@/assets/ICONS/maker.png'), // Path to your custom marker icon
        scaledSize: { width: 40, height: 40 } // Adjust the size if needed
      }
    };
  },
  mounted() {
    this.getAllProvinces();
    this.fetchOutletType();
  },
  methods: {
    fetchOutletType(){
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet`, { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.outletTypes = response.data;
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });

    },
    async createRoute() {
      this.loading = true;

      const userId = localStorage.getItem('userId');
      const outletData = {
        outlet_name: this.outlet.outlet_name,
        outlet_address: this.outlet.outlet_address,
        latitude: this.outlet.latitude,
        longitude: this.outlet.longitude,
        mobile_number: this.outlet.mobile_number,
        outlet_type_id: this.outlet.outlet_type,
        province: this.outlet.province,
        district: this.outlet.district,
        dsd: this.outlet.dsd,
        gnd: this.outlet.gnd,
        outlet_status: this.outlet.outlet_status,
        owner_name: this.outlet.owner_name,
        userId:  userId
      };
      try {
        const token = localStorage.getItem('token');

        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/save`, outletData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        console.log(response);

        this.snackbar = true;

      } catch (error) {
        // Show error message
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response?.data?.message || 'Something went wrong!',
        });
      }finally {
        this.loading = false;
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    },



    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mDistrictLoading = false;
            }
          })
          .catch(() => {
            this.mDistrictLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },


    handleFileUpload(event) {
      const file = event.target.files[0];
      this.bulkFile = file; // Assign the selected file to bulkFile

      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assuming the data is in the first sheet
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Convert the sheet to JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headers = jsonData[0].map(header => header ? header.toString().trim() : '');
        const dataRows = jsonData.slice(1).map(row => {
          return headers.reduce((obj, header, index) => {
            const value = row[index];
            obj[header] = value !== undefined && value !== null ? value.toString().trim() : '';
            return obj;
          }, {});
        });

        this.bulkItems = dataRows;
      };

      reader.readAsArrayBuffer(file);
    },

    async uploadBulkOutlets() {
      this.loading = true;
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      if (!this.bulkFile) {
        console.error('No file selected for upload');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.bulkFile);
      formData.append('userId', userId); // Append userId if needed by the backend

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlets/bulk-upload`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            // 'Content-Type': 'multipart/form-data' // No need to set this, it will be set automatically
          },
        });
        if (response.status === 200) {
          console.log('Bulk upload response:', response.data);
          this.outletBulkUploadDialog = false;
          this.snackbarFile = true;
        }

      } catch (error) {
        console.error('Error uploading bulk outlets:', error);
      }finally {
        this.loading = false;
      }
    },

    backPageRoute() {
      this.$router.back();
    },
    mapClicked(event) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.outlet.latitude = this.markerPosition.lat;
      this.outlet.longitude = this.markerPosition.lng;
    },

    async downloadTemplate() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const url = `${process.env.VUE_APP_API_BASE_URL}/api/outlets/download-template?userId=${userId}`;


      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error('Failed to download template');
        }

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'template_outlet_data.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error('Error downloading template:', error);
      }
    },
    homeReload() {
      window.location.href = '/dashboard';
    },
  },

  watch: {
    'outlet.province'(newValue) {
      this.getAllDistrictAccProv(newValue);
    },
    'outlet.district'(newValue) {
      this.getAllDsd(newValue);
    },
    'outlet.dsd'(newValue) {
      this.getAllGnd(newValue);
    }
  }

};
</script>

<style scoped>
.page-title {
  font-weight: bold;
}

.light-gray-text {
  color: #a09e9e !important;
}

.red-text {
  color: #b00d0d;
}

.gray-text {
  color: #757575 !important;
}

.btn-border {
  border-block-end-color: #9a1313;
}

.card-size {
  width: 100%;
}

.tool-bar-style {
  display: flex;
  justify-content: end;
}

.divider-vertical {
  color: #3c3b3b !important;
}

.card-two-title {
  color: black;
}

.leads-details-card {
  height: 100%;
}

.card-height-fixed {
  height: 100%;
  overflow: auto;
  max-height: 63vh;
}

.data-table {
  width: 100% !important;
  border: black;
}

.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  font-size: 1rem;
}

.red-background-text {
  background-color: #f0bcbc;
  color: #BB0000;
}

.gray-background-text {
  background-color: rgb(202, 202, 202);
  color: black;
}

.divider-two-style {
  margin-top: -5px;
}

.map::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  background: url('@/assets/ICONS/maker.png') no-repeat center center;
  background-size: cover;
  transform: translate(-50%, -50%);
}

.centered-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}
</style>
    