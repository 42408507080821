<!-- Dashboard Component three - Line Chart -->

<template>
    <v-row class="mx-3 mt-1">
        <v-col cols="4">
            <v-select
                label="Year"
                variant="outlined"
                placeholder="Select Year"
            ></v-select>
        </v-col>
        <v-col cols="2">
            <v-card class="pa-2 flat">
            <h6 class="text-success">9.7 M</h6>
            <h6 class="gray-text font-weight-bold"><v-icon size="10" class="box-icon-color-1">mdi-checkbox-blank-outline</v-icon> Collections &nbsp; [Target]</h6>
            </v-card>
        </v-col>
        <v-col cols="2">
            <v-card class="pa-2 flat">
            <h6 class="yellow-text">20.0 M</h6>
            <h6 class="gray-text font-weight-bold"><v-icon size="10" class="box-icon-color-2">mdi-checkbox-blank-outline</v-icon> Sales &nbsp; [Target]</h6>
            </v-card>
        </v-col>
        <v-col cols="2">
            <v-card class="pa-2 flat">
            <h6 class="text-primary">9.4 M</h6>
            <h6 class="gray-text font-weight-bold"><v-icon size="10" class="box-icon-color-3">mdi-checkbox-blank-outline</v-icon> Collections &nbsp; [Actual]</h6>
            </v-card>
        </v-col>
        <v-col cols="2">
            <v-card class="pa-2 flat">
            <h6 class="red-text">18.5 M</h6>
            <h6 class="gray-text font-weight-bold"><v-icon size="10" class="box-icon-color-4">mdi-checkbox-blank-outline</v-icon> Sales &nbsp; [Actual]</h6>
            </v-card>
        </v-col>
        </v-row>
    <v-card flat>
        <v-card-title class="width-of-card">
            <div class="hello" ref="chartdiv">
            </div>
        </v-card-title>
    </v-card>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
  name: 'LineChartDashboardComponentThree',
  mounted() {
    let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

    chart.paddingRight = 20;

 // Sample data
 let data = [
      { date: new Date(2023, 0, 1), sales_actual: 1.5, collections_actual: 1, sales_target: 2, collections_target: 1.2 },
      { date: new Date(2023, 1, 1), sales_actual: 1.8, collections_actual: 1.3, sales_target: 2.2, collections_target: 1.4 },
      { date: new Date(2023, 2, 1), sales_actual: 2.2, collections_actual: 1.8, sales_target: 2.5, collections_target: 1.7 },
      { date: new Date(2023, 3, 1), sales_actual: 2.5, collections_actual: 2.2, sales_target: 3, collections_target: 2.5 },
      { date: new Date(2023, 4, 1), sales_actual: 1.9, collections_actual: 1.5, sales_target: 2.3, collections_target: 1.8 },
      { date: new Date(2023, 5, 1), sales_actual: 1.6, collections_actual: 1.2, sales_target: 2, collections_target: 1.5 },
      { date: new Date(2023, 6, 1), sales_actual: 1.8, collections_actual: 1.4, sales_target: 2.1, collections_target: 1.6 },
      { date: new Date(2023, 7, 1), sales_actual: 2, collections_actual: 1.7, sales_target: 2.4, collections_target: 1.9 },
      { date: new Date(2023, 8, 1), sales_actual: 2.3, collections_actual: 2, sales_target: 2.7, collections_target: 2.2 },
      { date: new Date(2023, 9, 1), sales_actual: 2.5, collections_actual: 2.3, sales_target: 3, collections_target: 2.5 },
      { date: new Date(2023, 10, 1), sales_actual: 2.8, collections_actual: 2.5, sales_target: 3.2, collections_target: 2.7 },
      { date: new Date(2023, 11, 1), sales_actual: 3, collections_actual: 2.8, sales_target: 3.5, collections_target: 3 }
    ];

    chart.data = data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.dateFormats.setKey("month", "MMM");
    dateAxis.periodChangeDateFormats.setKey("month", "MMM");    
    dateAxis.renderer.cellStartLocation = 0.2; // Adjust for bar width
    dateAxis.renderer.cellEndLocation = 0.8; // Adjust for bar width

    // Ensure all months are shown
    dateAxis.baseInterval = { timeUnit: "month", count: 1 };
    dateAxis.gridIntervals.setAll([
      { timeUnit: "month", count: 1 }
    ]);

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 5;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.minGridDistance = 20;
    valueAxis.renderer.opposite = true; // Move y-axis to the right side
    
    // Set y-axis step to 1M
    valueAxis.renderer.labels.template.adapter.add("text", function(text) {
      return text + "M";
    });
    valueAxis.renderer.labels.template.fontSize = 14;

    // Column series for actual sales
    let salesActualSeries = chart.series.push(new am4charts.ColumnSeries());
    salesActualSeries.dataFields.dateX = "date";
    salesActualSeries.dataFields.valueY = "sales_actual";
    salesActualSeries.tooltipText = "Actual Sales: {valueY.value}M";
    salesActualSeries.name = "Actual Sales";
    salesActualSeries.columns.template.fill = am4core.color("#b00d0d"); // Red color
    salesActualSeries.columns.template.stroke = am4core.color("#b00d0d");    
    salesActualSeries.columns.template.width = am4core.percent(30);

    // Column series for actual collections
    let collectionsActualSeries = chart.series.push(new am4charts.ColumnSeries());
    collectionsActualSeries.dataFields.dateX = "date";
    collectionsActualSeries.dataFields.valueY = "collections_actual";
    collectionsActualSeries.tooltipText = "Actual Collections: {valueY.value}M";
    collectionsActualSeries.name = "Actual Collections";
    collectionsActualSeries.columns.template.fill = am4core.color("#0D47A1"); // Blue color
    collectionsActualSeries.columns.template.stroke = am4core.color("#0D47A1");
    collectionsActualSeries.columns.template.width = am4core.percent(30);

    // Line series for target sales
    let salesTargetSeries = chart.series.push(new am4charts.LineSeries());
    salesTargetSeries.dataFields.dateX = "date";
    salesTargetSeries.dataFields.valueY = "sales_target";
    salesTargetSeries.tooltipText = "Target Sales: {valueY.value}M";
    salesTargetSeries.name = "Target Sales";
    salesTargetSeries.strokeWidth = 2;
    salesTargetSeries.stroke = am4core.color("#efb907"); // Yellow color
    salesTargetSeries.fill = am4core.color("#efb907");

    // Line series for target collections
    let collectionsTargetSeries = chart.series.push(new am4charts.LineSeries());
    collectionsTargetSeries.dataFields.dateX = "date";
    collectionsTargetSeries.dataFields.valueY = "collections_target";
    collectionsTargetSeries.tooltipText = "Target Collections: {valueY.value}M";
    collectionsTargetSeries.name = "Target Collections";
    collectionsTargetSeries.strokeWidth = 2;
    collectionsTargetSeries.stroke = am4core.color("rgb(27, 185, 25)"); // Green color
    collectionsTargetSeries.fill = am4core.color("rgb(27, 185, 25)");

    chart.cursor = new am4charts.XYCursor();
    // chart.legend = new am4charts.Legend();

    this.chart = chart;
    
     // Hide the amCharts link/icon
     chart.logo.disabled = true; // This line hides the amCharts link/icon

  },

  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
}
</script>


<style scoped>
.hello {
  width: 100%;
  height: 400px;
}
.width-of-card{
  width: 100%;
}
.red-text {
  color: #b00d0d;
}

.gray-text {
  color: #757575 !important;
}

.box-icon-color-1{
  background-color: rgb(27, 185, 25);
  color: rgb(27, 185, 25);
}

.yellow-text{
  color: #efb907 !important;
}

.box-icon-color-2{
  background-color: #efb907;
  color: #efb907;
}

.box-icon-color-3{
  background-color: blue;
  color: blue;
}

.box-icon-color-4{
  background-color: #b00d0d;
  color: #b00d0d;
}
</style>