import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';

import router from './routes'
import 'vuetify/styles'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import store from './store/store'
import Echo from 'laravel-echo';

import axios from 'axios';

import Pusher from 'pusher-js';


// Load fonts
loadFonts();



// window.Pusher = require('pusher-js');
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '1eb73c865619e7bb2dcb',
    cluster: 'ap2',
    encrypted: true
});

// Set the base URL for Axios
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

// Create Vue app

const app = createApp(App);

// Use plugins and router
app.use(router);
app.use(vuetify);
app.use(store);
// app.use(VueGoogleMaps, {
//     load: {
//         key: 'AIzaSyBSO8DAG_F81EGii5PTp_M_dLsR1yvszC8',
//         libraries: 'drawing,language=si,geometry,places,visualization',
//     },
// })
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCQMADkuuUCAUyisqVPYbzPXthJWrSzUe0',
        // libraries: 'drawing,language=si,geometry,places,visualization',
    },
    // autobindAllEvents: true,
})

// Mount the app
app.mount('#app');


