<!-- Top 6 cards of main dashboard - main dashboard first component -->

<template> 

    <v-row class="mt-3">
        
      <!-- Total Sales -->
      <v-col cols="12" md="4">
        <v-card class="pa-3 fixed-height">
            <h4 class="card-h4-title">TOTAL SALES
                <div class="icon-box">
                    <v-icon size="32" color="#910404">mdi-finance</v-icon>
                </div>
            </h4>
            <h6 class="text-red bottom">Current Month</h6>
          <v-divider></v-divider>
          <h3 class="headline mt-3">{{ dashboard.totalSales }}</h3> 
          <h4 class="d-flex align-center my-1">
            <h6 class="text-success font-weight-bold"><v-icon size="20" color="green">mdi-arrow-up-bold</v-icon> 0.08% </h6>
            <h6 class="gray-text">&nbsp; Increase compared to last month</h6>
          </h4>
        </v-card>
      </v-col>

       <!-- Total Collections -->
       <v-col cols="12" md="4">
        <v-card class="pa-3 fixed-height">
            <h4 class="card-h4-title">TOTAL COLLECTIONS
                <div class="icon-box">
                    <v-icon size="32" color="#910404">mdi-cash-check</v-icon>
                </div>
            </h4>
            <h6 class="text-red bottom">Current Month</h6>
          <v-divider></v-divider>
          <h3 class="headline mt-3">{{ dashboard.totalCollection }}</h3>
          <h4 class="d-flex align-center my-1">
            <h6 class="text-success font-weight-bold"><v-icon size="20" color="green">mdi-arrow-up-bold</v-icon> 1.03% </h6>
            <h6 class="gray-text">&nbsp; Increase compared to last month</h6>
          </h4>
        </v-card>
      </v-col>

      <!-- Profit/Loss -->
      <v-col cols="12" md="4">
        <v-card class="pa-3 fixed-height">
            <h4 class="card-h4-title">PROFIT/LOSS
                <div class="icon-box">
                    <v-icon size="32" color="#910404">mdi-chart-bar</v-icon>
                </div>
            </h4>
            <h6 class="text-red bottom">Current Month</h6>
          <v-divider></v-divider> 
          <h3 class="headline text-success"><v-icon size="20" color="green">mdi-arrow-up-bold</v-icon>{{ dashboard.totalProfit }}</h3>
          <h6 class="gray-text">Profit</h6>
          <h4 class="d-flex align-center my-1">
            <h6 class="text-success font-weight-bold"><v-icon size="20" color="green">mdi-arrow-up-bold</v-icon> 1.07% </h6>
            <h6 class="gray-text">&nbsp; Increase compared to last month</h6>
          </h4>
        </v-card>
      </v-col>

    </v-row>

    <v-row class="mt-3">
      
      <!-- Total Stock -->
      <v-col cols="12" md="4">
        <v-card class="pa-3 fixed-height">
            <h4 class="card-h4-title">TOTAL STOCK
                <div class="icon-box">
                    <v-icon size="32" color="#910404">mdi-chart-arc</v-icon>
                </div>
            </h4>
            <h6 class="text-red bottom">Current Month</h6>
          <v-divider></v-divider>
          
          <h3 class="d-flex align-center my-1 mt-3">
            <h3 class="headline">{{ dashboard.totalStock  }}</h3>
            <h6 class="text-red">&nbsp; PRODUCTS UNIT</h6>
          </h3>
          <h4 class="d-flex align-center my-1">
            <h6 class="text-success font-weight-bold"><v-icon size="20" color="green">mdi-arrow-up-bold</v-icon> 2.18% </h6>
            <h6 class="gray-text">&nbsp; Increase compared to last month</h6>
          </h4>
        </v-card>
      </v-col>

      <!-- Ongoing Leads -->
      <v-col cols="12" md="4">
        <v-card class="pa-3 fixed-height">
            <h4 class="card-h4-title">ONGOING LEADS
                <div class="icon-box">
                    <v-icon size="32" color="#910404">mdi-timetable</v-icon>
                </div>
            </h4>
            <h6 class="text-red bottom">Current Month</h6>
          <v-divider></v-divider>
          <h3 class="headline mt-3">{{ dashboard.totalLeasds }}</h3>
          <h4 class="d-flex align-center my-1">
            <h6 class="text-success font-weight-bold"><v-icon size="20" color="green">mdi-arrow-up-bold</v-icon> 1.23% </h6>
            <h6 class="gray-text">&nbsp; Increase compared to last month</h6>
          </h4>
        </v-card>
      </v-col>

      <!-- Registered Outlets -->
      <v-col cols="12" md="4">
        <v-card class="pa-3 fixed-height">
            <h4 class="card-h4-title">REGISTERED OUTLETS
                <div class="icon-box">
                    <v-icon size="32" color="#910404">mdi-store</v-icon>
                </div>
            </h4>
            <h6 class="text-red bottom">All</h6>
          <v-divider></v-divider>
          <h3 class="headline mt-3">{{ dashboard.totalOutlet }}</h3>
          <h4 class="d-flex align-center my-1">
            <h6 class="text-success font-weight-bold"><v-icon size="20" color="green">mdi-arrow-up-bold</v-icon> 02 </h6>
            <h6 class="gray-text">&nbsp; Outlets registered in the current month</h6>
          </h4>
        </v-card>
      </v-col>

    </v-row>

    
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ReportCard',
    data() {
        return {
          // dashboardData:"",
        };
    },
    computed: {
    ...mapGetters({
      dashboard: 'getDashboardDetails'
     })
    },
    created(){
      
    },
   
    methods: {
   
    
    }

}
</script>

<style>
.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  font-size: 1rem;
}

.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e3beca;
  border-radius: 3px;
  height: 50px;
  width: 50px;
}

.headline {
  font-size: 1.5rem;
  font-weight: bold;
}

.text-red {
  color: #BB0000;
}
.text-success {
  color: green;
}
.pa-3 {
  padding: 1.5rem;
}

.gray-text{
    color: #757575 !important;
}
.bottom{
    margin-top: -15px;
    margin-bottom: 15px;
}

.fixed-height {
  height: 180px;
}
</style>